import moment from "moment";
const dateFormat = "MMMM DD YYYY hh:mm:ss A";
const convertFormat = "DD-MM-YYYY hh:mm:ss A";

export const tools = {
  // date
  now() {
    return moment().format(dateFormat);
  },

  formatDate(date, format = 6) {
    let fDate = moment(date).format(dateFormat);
    if (format == 3) {
      fDate = moment(date).format("MMMM DD YYYY");
    } else if (format == 4) {
      fDate = moment(date).format("DD-MMMM-YYYY");
    }
    return fDate;
  },

  utcToLocalFormat(date, format = "D MMM - h:mm a") {
    return moment.utc(date).local().format(format);
  },

  convertToLocal(date) {
    return moment(date, convertFormat).format(dateFormat);
  },

  utcToLocal(date, format = dateFormat) {
    return moment.utc(date, convertFormat).local().format(format);
  },

  dateStatus(date1, date2, type) {
    let status = false;
    if (type == "past") {
      status = moment(date1).isBefore(date2);
    } else if (type == "sAfter") {
      status = moment(date1).isSameOrAfter(date2);
    } else if (type == "after") {
      status = moment(date1).isAfter(date2);
    } else if (type == "same") {
      status = moment(date1).isSame(date2, "date");
    }
    return status;
  },

  // input
  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  // dom
  scrollEnd(id = "") {
    if (id == "") {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      let ele = document.getElementById(id);
      ele.scrollTo(0, ele.scrollHeight);
    }
  },

  // encode decode
  crypt(type="E",str) {
    if(type=="E"){
      return window.btoa(str);
    } else {
      return window.atob(str);
    }
  },
};
