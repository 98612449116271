import React from "react";
import { Container, Row, Col, ListGroup, Media, Button } from "react-bootstrap";
import { Sidebar } from "../../../_layouts/sidebar";
import { ProfileHeader } from "../../../_layouts/profileHeader";
import { apiServices } from "../../../api";
import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import { StatusBar } from "../../../_includes";
import moment from "moment";
import PurchaseHistoryDetail from "./PurchaseHistoryDetail";
import { Helmet } from "react-helmet";
class PurchaseHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: (this.props.match.params.order_id || ""),
      ordersHistory: [],
      orderDetails: [],
      showDetail: false,
      // status
      status: "process",
    };
  }
  
  componentDidMount() {
    const {orderId} = this.state;
    if(orderId>0){
      this.getOrderDetails(orderId);
    } else {
      this.getAllPurchaseHistory();
    }
  }

  getAllPurchaseHistory = async () => {
    let data = {
      url: "GET_MOVIE_ORDERS",
      query: "?page=0&size=100&sort=orderDate,desc",
      method:"GET"
    };
    apiServices.call(
      data,
      (response) => {
        if(response.data.totalElements>0){
          this.setState({
            status:"success",
            ordersHistory: response.data,
          });
        } else {
          this.setState({
            status: "noData"
          });
        }
      },
      (error) => {
        common.snack("E",error);
      },
      (final) => {
      }
    );
  };

  getOrderDetails = (orderId) => {
    try {
      this.setState({
        status: "process",
      });
      let data = {
        url: "GET_ORDER_DETAILS",
        query: orderId,
        method:"GET"
      };

      apiServices.call(
        data,
        (response) => {
          this.setState({
            orderDetails: response.data,
            showDetail: true,
          });
        },
        (error) => {
          common.snack("E",error);
        },
        (final) => {
          this.setState({
            status: "success"
          });
        }
      );
    } catch (e) {
      common.snack("E",e.toString());
    }
  };

  showDetail = (orderId) => {
    this.getOrderDetails(orderId);
  };

  goBack = () => {
    const {orderId} = this.state;
    if(orderId){
      common.redirect("/purchasehistory",0);
    } else {
      this.setState({
        showDetail: false,
      });
    }
  };
  
  render() {
    const { orderId, ordersHistory, showDetail, orderDetails, status } = this.state;
    return (
      <div>
        <Header />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="profileContent_detail">
                  <div className="myProfile_header order-detail-wrap-header">
                    <Row>
                      <Col md={9}>
                        <h3>Purchase History</h3>
                      </Col>
                      <Col md={3}>
                        {showDetail && (
                          <Button
                            className="btn-booknow"
                            onClick={() => this.goBack()}
                          >
                            Go Back
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <StatusBar page="purchaseHistory" loader="small" status={status} />
                  {status == 'success' && !showDetail && ordersHistory!="" && (
                      <ListGroup className="historyTicket_detail">
                        {ordersHistory.content.map((item, idx) => (
                          <ListGroup.Item
                            onClick={() => this.showDetail(item.movieOrderId)}
                            key={idx}
                            className="rt-cp"
                          >
                            <Row>
                              <Col md={9}>
                                <Media>
                                  <img
                                    src={
                                      item.orderMovie &&
                                      item.orderMovie.moviePoster
                                        ? common.loadCloudImg(item.orderMovie.moviePoster)
                                        : null
                                    }
                                    alt="image"
                                    className="purchase_img"
                                  />
                                  <Media.Body>
                                    <h5>
                                      {item.orderMovie &&
                                      item.orderMovie.movieName
                                        ? item.orderMovie.movieName +
                                          item.orderNo
                                        : "-"}
                                    </h5>
                                    <ul>
                                      <li>
                                        <p>
                                          {item.orderMovie &&
                                          item.orderMovie.primaryLanguage
                                            ? item.orderMovie.primaryLanguage
                                            : "-"}{" "}
                                        </p>
                                      </li>
                                      <li>
                                        <p>
                                          {item.bookingStartTime
                                            ? moment
                                                .utc(
                                                  item.bookingStartTime,
                                                  "DD-MM-YYYY HH:mm:ss"
                                                )
                                                .local()
                                                .format(
                                                  "DD MMM, YYYY | hh:mm A"
                                                )
                                            : "-"}
                                        </p>
                                      </li>
                                      <li>
                                        <p
                                          className={
                                            item.paymentStatus === "PAID"
                                              ? "payment-status success"
                                              : "payment-status danger"
                                          }
                                        >
                                          {item.paymentStatus}
                                        </p>
                                      </li>
                                      {item.paymentStatus === "PAID" && (
                                      <li>                                        
                                          <a
                                            href={
                                              "/movies-details/" +
                                              (item.orderMovie &&
                                              item.orderMovie.movieId
                                                ? item.orderMovie.movieId
                                                : 0)
                                            }
                                            className="nav-movie"
                                          >
                                            {" "}
                                            <img
                                              src={common.loadImg(
                                                "play_icon.png"
                                              )}
                                              alt="image"
                                            />
                                          </a>                                        
                                      </li>
                                      )}
                                    </ul>
                                  </Media.Body>
                                </Media>
                              </Col>
                              <Col md={3} className="text-md-right">
                                <p>
                                  {item.orderDate
                                    ? moment
                                        .utc(
                                          item.orderDate,
                                          "DD-MM-YYYY HH:mm:ss"
                                        )
                                        .local()
                                        .format("DD MMM")
                                    : "-"}
                                </p>
                                <h3>
                                  ₹
                                  {item.totalRoundedValue}
                                </h3>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  {status == 'success' && showDetail && (
                    <PurchaseHistoryDetail orderDetails={orderDetails} />
                  )}                  
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { PurchaseHistory };
