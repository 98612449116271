import moment from "moment";
import SnackbarUtils from "../_includes/snackbar";
import { apiServices } from "../api";
import { envConfig } from "../api/config";
import { history } from "../_helpers";
var jwt = require("jsonwebtoken");

let cloudUrl = "https://d13heh6lmag6t5.cloudfront.net";

export const common = {
  // localStorage
  lsUser(field = "") {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (field) {
      return userData.data[field];
    }
  },

  // basic
  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  },

  // intermediate
  loadImg(img) {
    let path = require("../assets/img/" + img);
    return path.default;
  },

  // loadMovieImg(img) {
  //   return require("../assets/img/movies/" + img);
  // },

  // advanced
  refreshToken(authorization = true, callback) {
    if (authorization) {
      callback(1);
    } else {
      callback(1);
    }
  },

  bodyBg(type) {
    if (type == "add") {
      document.body.classList.add("signup_bg");
    } else {
      document.body.classList.remove("signup_bg");
    }
  },

  redirect(page, time) {
    setTimeout(
      () => {
          // window.location.href = page;
          history.push(page);
      },
      !time ? 0 : time * 1000
    );
    return false;
  },

  snack(type, msg = "") {
    switch (type) {
      case "S":
        SnackbarUtils.success(msg);
        break;
      case "E":
        SnackbarUtils.error(msg);
        break;
      case "W":
        SnackbarUtils.warning(msg);
        break;
      case "I":
        SnackbarUtils.info(msg);
        break;
      case "T":
        SnackbarUtils.toast(msg);
        break;
      case "C":
        SnackbarUtils.closeAll();
        break;
    }
  },

  getRole() {
    let decodeData = jwt.decode(localStorage.getItem("userToken"));
    return decodeData.roles;
  },

  checkRole(role, redirect = false) {
    let decodeData = jwt.decode(localStorage.getItem("userToken"));
    if (decodeData.roles.includes(role)) {
      return true;
    } else {
      if (redirect) {
        this.redirect("/");
      } else {
        return false;
      }
    }
  },

  async myIp() {
    let result = await fetch("https://api.ipify.org/?format=jsonp&callback=", {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => {
        return JSON.parse(result.replace(/[();]/g, ""));
      })
      .catch((error) => {
        return "";
      });
    return result;
  },

  async myLocation() {
    let ipResult = await this.myIp();
    let result = "";
    if (ipResult != "") {
      result = fetch(
        "https://geo.ipify.org/api/v1?apiKey=at_I6RfeujCs62pauUKeUxdQ6xSaDbl3&ipAddress=" +
          ipResult.ip,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return "";
        });
    }
    return result;
  },

  async geoLocation() {
    await navigator.geolocation.getCurrentPosition(function (position) {
      if (position) {
        return {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      return null;
    });
  },

  checkAuth(url) {
    let token = localStorage.getItem("userToken");
    if (token != null) {
      this.redirect(url, 0);
      return true;
    }
    return false;
  },

  isAuth() {
    let token = localStorage.getItem("userToken");
    if (token == null) {
      return false;
    }
    return true;
  },

  loadCloudImg(img) {
    let tempStore = ["image", "moviePoster"];
    if (img != "" && img != null && !img.includes("https://")) {
      return cloudUrl + img;
    } else {
      return img;
    }
  },

  loadCloudFile(file) {
    if (file != "" && file != null) {
      return cloudUrl + file;
    } else {
      return "";
    }
  },

  getUserDetails() {
    let data = {
      url: "GET_MYPROFILE",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        localStorage.setItem("user", JSON.stringify(response));
      },
      (error) => {
        common.snack("E", "Something went wrong!!!");
      },
      (final) => { }
    );
  }
};