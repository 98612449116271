import React, { Component } from "react";
import { Router, Switch } from "react-router-dom";
import { history } from "../_helpers";
import { HomeLayout, SignupLayout } from "./routerLayout";

import { Signup, Otp, UserInfo } from "../pages/Signup";
// import { Home } from "../pages/Home/Home";
// import { Upcoming } from "../pages/Upcoming/Upcoming";
// import { Movies } from "../pages/Movies/Movies";

// Profile
import {
  Profile,
  Notifications,
  PurchaseHistory,
  ProfileSettings,
  EditProfile,
  ResetPassword,
} from "../pages/Profile";
import { MyLocation, SettingsLanguage } from "../pages/Profile/pages/setting";

import {
  Home,
  Upcoming,
  Movies,
  MovieDetail,
  PubMovieDetails,
  TicketBooking,
  Payments,
  About,
  Privacy,
  Terms,
  Contact,
  SubmitFilm,
  ShortFilms,
  WebSeries,
  ShortFilmDetail,
} from "../pages";

import {
  ProducerChannels,
  ProducerMovies,
  ProducerOrders,
} from "../pages/Producer";

import { NotFound } from "../pages/NotFound/NotFound";
class RouterComponent extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <SignupLayout exact path="/signup" component={Signup} auth={false} />
          <SignupLayout
            exact
            path="/otp/:userdetail"
            component={Otp}
            auth={false}
          />
          <SignupLayout
            exact
            path="/userinfo"
            component={UserInfo}
            auth={true}
          />
          <HomeLayout exact path="/" component={Home} auth={null} />

          <HomeLayout exact path="/upcoming" component={Upcoming} auth={null} />
          <HomeLayout exact path="/movies" component={Movies} auth={null} />
          <HomeLayout
            exact
            path="/movies/search/language/:language"
            component={Movies}
            auth={null}
          />
          <HomeLayout
            exact
            path="/movies/search/language/:language/genre/:genre"
            component={Movies}
            auth={null}
          />
          <HomeLayout
            exact
            path="/movies/search/genre/:genre"
            component={Movies}
            auth={null}
          />
          <HomeLayout
            exact
            path="/movies-details/:movie_id"
            component={MovieDetail}
            auth={true}
          />
          <HomeLayout
            exact
            path="/shortfilm-details/:movie_id"
            component={MovieDetail}
            auth={true}
          />
          {/* <HomeLayout
            exact
            path="/shortfilm-details/:movie_id"
            component={MovieDetail}
            auth={true}
          /> */}
          <HomeLayout
            exact
            path="/movies/ticket-booking/:movie_id"
            component={TicketBooking}
            auth={true}
          />
          <SignupLayout
            exact
            path="/v/:movie_name/:movie_id"
            component={PubMovieDetails}
            auth={null}
          />
          <HomeLayout
            exact
            path="/movies/payment"
            component={Payments}
            auth={true}
          />
          <HomeLayout
            exact
            path="/movies/shortfilms"
            component={ShortFilms}
            auth={null}
          />
          <HomeLayout
            exact
            path="/movies/webseries"
            component={WebSeries}
            auth={null}
          />
          <HomeLayout exact path="/about-us" component={About} auth={null} />
          <HomeLayout
            exact
            path="/privacy-policy"
            component={Privacy}
            auth={null}
          />
          <HomeLayout
            exact
            path="/terms-and-conditions"
            component={Terms}
            auth={null}
          />
          <HomeLayout
            exact
            path="/contact-us"
            component={Contact}
            auth={null}
          />
          <HomeLayout
            exact
            path="/submit-film"
            component={SubmitFilm}
            auth={true}
          />

          {/* Producer */}
          <HomeLayout
            exact
            path="/producer/channels"
            component={ProducerChannels}
            auth={true}
          />
          <HomeLayout
            exact
            path="/producer/movies/:channel_id"
            component={ProducerMovies}
            auth={true}
          />
          <HomeLayout
            exact
            path="/producer/orders/:movie_id/:channel_id"
            component={ProducerOrders}
            auth={true}
          />

          {/* Profile */}
          <HomeLayout exact path="/profile" component={Profile} auth={true} />
          <HomeLayout
            exact
            path="/resetpassword"
            component={ResetPassword}
            auth={true}
          />
          <HomeLayout
            exact
            path="/profilesettings"
            component={ProfileSettings}
            auth={true}
          />
          <HomeLayout
            exact
            path="/notification"
            component={Notifications}
            auth={true}
          />
          <HomeLayout
            exact
            path="/purchasehistory/:order_id?"
            component={PurchaseHistory}
            auth={true}
          />
          <HomeLayout
            exact
            path="/editprofile"
            component={EditProfile}
            auth={true}
          />
          <HomeLayout
            exact
            path="/mylocation"
            component={MyLocation}
            auth={true}
          />
          <HomeLayout
            exact
            path="/language"
            component={SettingsLanguage}
            auth={true}
          />
          <HomeLayout component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export { RouterComponent };
