import { envConfig } from "./config";
// Omit this
export const REST_TEST = envConfig.REST_TEST;
// Auth
export const SIGNUP = envConfig.AUTH_API + "users/registerbyphone";
export const FORGOT_PASSWORD_BY_PHONE = envConfig.AUTH_API + "users/forgotpasswordbyphone";
export const OTP_VERIFICATION = envConfig.AUTH_API + "users/validatephoneotp";
export const SIGNIN = envConfig.AUTH_API + "users/login";
export const UPDATE_PROFILE = envConfig.AUTH_API + "userinfo/";
export const SIGNUP_EMAIL = envConfig.AUTH_API + "users/registerbyemail";

export const FORGOTPASSWORD = envConfig.AUTH_API + "users/forgotpassword";
export const UPDATE_FIRST_LOGIN = envConfig.AUTH_API + "updatefirstlogin";
export const GENERATE_TOKEN = envConfig.AUTH_API + "users/generatetoken";
export const RESETPASSWORD = envConfig.AUTH_API + "resetpassword";
export const SOCIAL_SIGN_IN = envConfig.AUTH_API + "users/socialLogin";
// OTT
export const GET_MOVIE_INFO = envConfig.OTT_API + "movies/";
export const GET_STREAMING_NOW_MOVIES = envConfig.OTT_API + "streamingnow";
export const GET_UPCOMING_MOVIES = envConfig.OTT_API + "upcomingmovies";
export const GET_SEARCH_MOVIES = envConfig.OTT_API + "searchmovies";
export const GET_ALL_MOVIES = envConfig.OTT_API + "getallmovies";
export const GET_NEXT_WEEK_MOVIES = envConfig.OTT_API + "nextweekreleases";
export const CREATE_MOVIE_ORDER = envConfig.OTT_API + "movie";
export const UPDATE_PAYMENT_STATUS = envConfig.OTT_API + "orderpayment";
export const GET_MOVIE_ORDERS = envConfig.OTT_API + "getmovieorders";
export const GET_MOVIE_LINK = envConfig.OTT_API + "playmovieweb";
export const GET_ORDER_DETAILS = envConfig.OTT_API + "orders/";
export const VALIDATE_MOVIE_COUPON = envConfig.OTT_API + "validatemoviecoupon";
export const GET_MOVIE_ORDER = envConfig.OTT_API + "movieorders";
export const GET_MY_CHANNELS = envConfig.OTT_API + "mychannels";
export const GET_MY_CHANNEL_MOVIES = envConfig.OTT_API + "mychannelmovies";
export const GET_MY_MOVIE_ORDERS = envConfig.OTT_API + "mymovieorders";
export const GET_SHORTFILMS = envConfig.OTT_API + "getallshortfilms";
export const GET_UPCOMING_SHORTFILMS = envConfig.OTT_API + "upcomingshortfilms";

export const REVIEWS_GET_ALL_MOVIE_REVIEWS = envConfig.OTT_API + "getallmoviereviews";
export const REVIEWS_MOVIE_REVIEWS_BY_MOVIE = envConfig.OTT_API + "moviereviewsbymovie";
export const REVIEWS_MOVIE_REVIEWS_BY_USER = envConfig.OTT_API + "moviereviewsbyuser";
export const REVIEWS_MY_MOVIE_REVIEW = envConfig.OTT_API + "mymoviereview";
export const REVIEWS_MOVIE = envConfig.OTT_API + "movie";
export const REVIEWS_MOVIE_REVIEWS = envConfig.OTT_API + "moviereviews";
export const CREATE_MOVIES = envConfig.OTT_API + "movies";
export const ADD_MOVIE_GENRE = envConfig.OTT_API + "movie";

// Public
export const PUBLIC_MOVIES = envConfig.OTT_API + "public/getallmovies";
export const PUBLIC_UPCOMING_MOVIES = envConfig.OTT_API + "public/upcomingmovies";
export const PUBLIC_STREAMING_NOW = envConfig.OTT_API + "public/streamingnow";
export const PUBLIC_LANGUAGE = envConfig.OTT_API + "public/getalllanguages";
export const PUBLIC_GENRE = envConfig.OTT_API + "public/getallgenres";
export const PUBLIC_FITER_MOVIES = envConfig.OTT_API + "public/searchmovies?search=active:Y,";
export const PUBLIC_GET_MOVIE_INFO = envConfig.OTT_API + "public/movies";
export const PUBLIC_SEARCH_MOVIES = envConfig.OTT_API + "public/searchmovies";
export const PUBLIC_SHORTFILMS = envConfig.OTT_API + "public/getallshortfilms";
export const PUBLIC_UPCOMING_SHORTFILMS = envConfig.OTT_API + "public/upcomingshortfilms";

export const GET_MYPROFILE = envConfig.AUTH_API + "myprofile";

export const GET_ABOUT_CONTENT = envConfig.OTT_API + "public/pages/about-us";
export const GET_TERMS_CONTENT = envConfig.OTT_API + "public/pages/terms-and-conditions";
export const GET_PRIVACY_CONTENT = envConfig.OTT_API + "public/pages/privacy-policy";
export const GET_CONTACT_CONTENT = envConfig.OTT_API + "public/pages/contact-us";

export const GET_COLLECTIONS = envConfig.OTT_API + "public/mcollections/getall";
export const GET_PUBLIC_REVIEWS = envConfig.OTT_API + "public/moviereviewsbymovie";
export const GET_SUBSCRIPTION = envConfig.OTT_API + "public/subs/getall";

// movie play
export const MOVIE_PLAYS = envConfig.OTT_API + "movie/";
export const MOVIE_PLAYS_UPDATE = envConfig.OTT_API + "movieplays/";