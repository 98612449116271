import React, { Component } from "react";
/** Import React Slick Slider files */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/PubMovieDetails.css";
import { Reviews } from "../../../_includes";

import StarRatings from "react-star-ratings";

import { Container, Modal, Button, Row, Col } from "react-bootstrap";

import { Header } from "../../../_layouts/header";
import { common, message } from "../../../_helpers";
import { apiServices } from "../../../api";

// Video

import { Replay } from "vimond-replay";
import "vimond-replay/index.css";
import Player from "../../TheoPlayer/TheoPlayer";
import ReactPlayer from 'react-player'
// AUdio Player
import ReactAudioPlayer from "react-audio-player";

// package
import moment from "moment";
import { Helmet } from "react-helmet";
import ReadMoreAndLess from "react-read-more-less";
import MetaTags from 'react-meta-tags';
import { StatusBar } from "../../../_includes";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
class PubMovieDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movieId: this.props.match.params.movie_id,
      movieDetails: [],
      isLoading: false,
      showVideoPlayer: false,
      videoUrl: null,
      isMovie: false,
      moviePlayId: null,
      isShow: false,
      watchNowModal: false,
      status: "process",
      source: {
        sources: [],
        movieId: null,
        isMovie: false,
        playId: "",
      },
      isViewMore: false,
      isYoutubeUrl: false,
      youtubeUrl: "",
      moviePlayId: ""
    };
  }

  componentDidMount() {
    this.getMovieDetails(this.state.movieId);
  }

  getMovieDetails = (id) => {
    if (id) {
      let data = {
        url: "PUBLIC_GET_MOVIE_INFO",
        query: "?movie=" + id,
        method: "GET",
        authorization: "none",
      };

      apiServices.call(
        data,
        (response) => {
          let auth = common.checkAuth(
            "/movies-details/" + response.data.movieId
          );
          if (!auth) {
            this.setState({
              movieDetails: response.data,
              status: "success",
            });
          }
        },
        (error) => {
          common.snack("E", message.md_no_movies);
          common.redirect("/");
        },
        (final) => {}
      );
    }
  };

  playVideo = (url, isMovie, playId) => {
    const { source, movieDetails } = this.state;
    // if (movieDetails.movieType !== "Trailer") {
    //   if (url.includes("https://www.youtube.com/")) {
    //     this.setState({
    //       isYoutubeUrl: true,
    //       youtubeUrl: url
    //     })
    //   } else {
        window.scrollTo(0, 0);
        if (typeof url == "object") {
          let movieData = [];
          let movies = url.map(function (item, idx) {
            let obj = { src: item.movieUrl, type: item.contentType };
            movieData.push(obj);
          });
          source["sources"] = movieData;
        } else {
          let videoData = [];
          let videoObj = { src: url, type: "application/x-mpegurl" };
          videoData.push(videoObj);
          source["sources"] = videoData;
        }
        source.isMovie = isMovie;
        source.movieId = movieDetails.movieId;
        source.playId = playId + "_" + movieDetails.movieId;

        this.setState({
          source,
          showVideoPlayer: true,
          isMovie: isMovie ? true : false,
        });
    //   }
    // } else {
    //   this.setState({
    //     isYoutubeUrl: true,
    //     youtubeUrl: url
    //   })
    // }
  };

  closeYoutubeVideo = () => {
    this.setState({
      youtubeUrl: null,
      isYoutubeUrl: false,
    });
  };

  closeVideo = () => {
    this.setState({
      videoUrl: null,
      showVideoPlayer: false,
    });
  };

  // Songs Modal
  handleShow = () => {
    this.setState({ isShow: true });
  };

  handleClose = () => {
    this.setState({ isShow: false });
  };

  // rating
  rateMovie() {
    this.gotoTicketBooking();
    // e.preventDefault();
    // const { movieId } = this.state;
    // this.Reviews.writeReview(movieId);
  }

  reviewsList() {
    this.gotoTicketBooking();
    // const { movieId, movieDetails } = this.state;
    // this.Reviews.listReview(movieId, movieDetails.movieName);
  }

  gotoTicketBooking = (e, id) => {
    // e.preventDefault();
    common.redirect("/signup");
  };

  toggleCastCrew = () => {
    const { isViewMore } = this.state;
    this.setState({
      isViewMore: !isViewMore,
    });
  };

  moviePlay = (val, e) => {
    //e.preventDefault();
  }

  playTrailor = (movie) => {
    // this.setState({
    //   youtubeUrl: movie.trailorUrl ? movie.trailorUrl : movie.teaserUrl,
    //   isYoutubeUrl: true,
    // });
    let url = movie.trailorUrl ? movie.trailorUrl : movie.teaserUrl;
    let playId = movie.trailorUrl ? "trailer" : "teaser";
    console.log(url);
    const { source, movieDetails, users } = this.state;
    // if (movieDetails.movieType !== "Trailer") {
    //   if (url.includes("https://www.youtube.com/")) {
    //     this.setState({
    //       isYoutubeUrl: true,
    //       youtubeUrl: url
    //     })
    //   } else {
        window.scrollTo(0, 0);
        // source.sources[0].src = url;
        if (typeof url === "object") {
          let movieData = [];
          url.map((item, idx) => {
            let obj = { src: item.movieUrl, type: item.contentType };
            movieData.push(obj);
          });
          source["sources"] = movieData;
        } else {
          let videoData = [];
          let videoObj = { src: url, type: "application/x-mpegurl" };
          videoData.push(videoObj);
          source["sources"] = videoData;
        }
        source.isMovie = false;
        source.movieId = movieDetails.movieId;
        source.playId = playId + "_" + movieDetails.movieId;
        source.userId = users.data.userId;
        this.setState({
          // videoUrl: url,
          source,
          showVideoPlayer: true,
          isMovie: false,
        });
      // }
}

  render() {
    const {
      movieDetails,
      status,
      videoUrl,
      showVideoPlayer,
      source,
      isBookedLoading,
      playId,
      isViewMore,
      isYoutubeUrl,
      youtubeUrl,
      users
    } = this.state;

    return (
      <div>
        <StatusBar page="movie" loader="small" status={status} />
        <div
          style={{
            background:
              "url(" +
              common.loadCloudImg(movieDetails.webMovieDetail) +
              ") no-repeat center center /cover",
            maxHeight: "484px",
          }}
          className="mb-5"
        >
          <Header />

          <MetaTags>
            <meta charSet="utf-8" />
            <meta property="og:title" content={movieDetails.movieName} />
            <meta property="og:description" content={movieDetails.movieDescription} />
            <meta property="og:url" content={window.location.href} />
            <title>{movieDetails.movieName}</title>
            <meta name="description" content={movieDetails.movieDescription} />
          </MetaTags>

          
            <div className="streamnow-movies-wrap mb-5 cb-movie-details-wrap">
              <div className="container">
                <div className="slider-wrap">
                  <div className="bannerRadius movie-info-banner">
                    <div className="d-flex movie-info-wrap mb-4">
                    <div className="align-self-end">
                    <img
                              src={
                                movieDetails.moviePoster
                                  ? common.loadCloudImg(movieDetails.moviePoster)
                                  : ""
                              }
                              alt={movieDetails.movieName}
                              className="img-fluid"
                            />
                            </div>
                    {/* {movieDetails.movieType !== "Trailer" &&
                      <div className="align-self-end">
                        <a
                          href="#"
                          className="btn btn-booknow mr-3"
                          onClick={(e) => this.gotoTicketBooking(e)}
                        >
                          Book Now
                        </a>
                      </div>
                    }
                    {movieDetails.movieType == "Trailer" && (
                        <div className="align-self-end">
                          <a
                            onClick={() => {
                              this.playTrailor(movieDetails);
                            }}
                            className="btn btn-booknow mr-3"
                          >
                            Watch Now
                          </a>
                        </div>
                      )} */}
                      <div className="generdetailsContainer">
                      <p className="md-movie-name">{movieDetails.movieName}</p>
                        <p className="generdetails">
                          {movieDetails.primaryLanguage} |{" "}
                          {moment
                            .utc(
                              moment
                                .duration(movieDetails.runningTime, "minutes")
                                .asMilliseconds()
                            )
                            .format("HH[h] mm[m]")}{" "}
                          |{" "}
                          {movieDetails.releaseMonth
                            ? movieDetails.releaseMonth
                            : "-"}{" "}
                          |{" "}
                          {movieDetails.ageRating
                            ? movieDetails.ageRating + "+"
                            : "-"}
                        </p>
                        <div className="row">
                        {movieDetails.movieGenres &&
                          movieDetails.movieGenres.length > 0 && movieDetails.movieGenres.map((data, idx) => (
                            <h6 className="gener">
                              <span key={idx}>
                              {data.genre && data.genre.genreName
                                ? data.genre.genreName
                                : "-"}
                            </span>
                            </h6>
                          ))}
                      </div>
                      {movieDetails.movieType !== "Trailer" &&
                      // <div className="justify-content-left">
                        <a
                          href="#"
                          className="btn btn-booknow mr-3"
                          onClick={(e) => this.gotoTicketBooking(e)}
                        >
                          Book Now
                        </a>
                      // </div>
                    }
                    {movieDetails.movieType == "Trailer" && (
                        // <div className="justify-content-left">
                          <a
                            onClick={() => {
                              this.playTrailor(movieDetails);
                            }}
                            className="btn btn-booknow mr-3"
                          >
                            Watch Now
                          </a>
                        // </div>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        {isYoutubeUrl == true &&
          <div className="popup-wrap">
            <div ref={this.playerRef} className="video-player-wrap">
              <div className={"PlayerDiv"}>
                <ReactPlayer
                url={youtubeUrl} 
                width='960px'
                height='480px'
                playing={true}
                controls={true}
                />
              </div>

              <div className="popup-control d-flex justify-content-center mt-3">
                <div
                  className="video-link close-button"
                  onClick={() => this.closeYoutubeVideo()}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        }

        {showVideoPlayer == true && (
          <div className="popup-wrap">
            <div ref={this.playerRef} className="video-player-wrap">
              <div className={"PlayerDiv"}>
                {/* <Player source={source} /> */}
                <VideoPlayer
                  user={users ? users.data : null}
                  isMovie={source && source.isMovie}
                  playId={source && source.playId}
                  src={isYoutubeUrl ? youtubeUrl : source.sources && source.sources.length > 0 && source.sources[0].src}
                  onPlay={(status, event) => this.moviePlay(status, event)}
                />
              </div>

              <div className="popup-control d-flex justify-content-center mt-3">
                <div
                  className="video-link close-button"
                  onClick={() => this.closeVideo()}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="upcoming-movies-wrap mb-5">
          <div className="container">
            <div className="moviesContainer row">
              <div className="movieleft col-md-9 col-sm-12">
                {movieDetails && movieDetails.movieDescription && (
                  <div>
                    <h5 className="sub-title">SYNOPSIS</h5>
                    <div className="read-more-container mb-5">
                      <ReadMoreAndLess
                        ref={this.ReadMore}
                        className="read-more-content"
                        charLimit={290}
                        readMoreText="Read more"
                        readLessText="Read less"
                      >
                        {movieDetails.movieDescription}
                      </ReadMoreAndLess>
                    </div>
                  </div>
                )}
                {movieDetails &&
                  movieDetails.movieActors &&
                  movieDetails.movieActors.length > 0 && (
                    <div>
                      <h5 className="sub-title">CAST & CREW</h5>
                      <Row>
                        {movieDetails.movieActors.map((actors, key) => {
                          return (
                            <Col md={2}>
                              <div className="crewdetails text-center">
                                {actors.actor.actorImage ? (
                                  <img
                                    src={common.loadCloudImg(
                                      actors.actor.actorImage
                                    )}
                                    alt="Movie Image"
                                    className="profile_pic"
                                  />
                                ) : (
                                  <img
                                    src={common.loadImg("user_icon.png")}
                                    alt="profile"
                                    className="profile_pic"
                                  />
                                )}
                                <h5>{actors.actor.actorName}</h5>
                                <p>{actors.actorRole}</p>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  )}
              </div>

              <div className="movieright col-md-3 col-sm-12">
              {movieDetails.avgRating && movieDetails.avgRating > 0 && (
                  <div onClick={() => this.reviewsList()}>
                    <h5 className="sub-title">Rating</h5>
                    <div className="rating-wrap d-flex justify-content-between mb-4">
                      <StarRatings
                        rating={movieDetails.avgRating}
                        starRatedColor="#ffffff"
                        starEmptyColor="#506483"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                      />
                      <span>{movieDetails.avgRating} / 5</span>
                    </div>
                  </div>
                )}
                {/* <button
                  className="btn btn-add-rating text-center"
                  onClick={() => this.rateMovie()}
                >
                  <img
                    src={common.loadImg("star.png")}
                    alt=""
                    className="mr-3"
                  />
                  RATE THIS MOVIE
                </button> */}
                <h5 className="sub-title">Teaser / Trailer</h5>
                <div className="mb-5">
                  {movieDetails.teaserUrl && (
                    <div className="mb-3">
                      <div
                        className="video-link"
                        onClick={() =>
                          this.playVideo(
                            movieDetails.teaserUrl,
                            false,
                            "teaser"
                          )
                        }
                      >
                        <div className="teaser-trailer-wrap mb-3">
                          <img
                            src={
                              movieDetails.webStreamingNow
                                ? common.loadCloudImg(
                                    movieDetails.webStreamingNow
                                  )
                                : ""
                            }
                            alt={movieDetails.movieName}
                            className="img-fluid teaserImg"
                          />
                          <div className="overlayTr">
                            <img src={common.loadImg("teaser_play1.png")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {movieDetails.trailorUrl && (
                    <div className="mb-3">
                      <div
                        className="video-link"
                        onClick={() =>
                          this.playVideo(
                            movieDetails.trailorUrl,
                            false,
                            "trailer"
                          )
                        }
                      >
                        <div className="teaser-trailer-wrap">
                          <img
                            src={
                              movieDetails.webStreamingNow
                                ? common.loadCloudImg(
                                    movieDetails.webStreamingNow
                                  )
                                : ""
                            }
                            alt={movieDetails.movieName}
                            className="img-fluid teaserImg"
                          />
                          <div className="overlayTr">
                            <img src={common.loadImg("teaser_play1.png")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* {movieDetails.movieGenres &&
                  movieDetails.movieGenres.length > 0 && (
                    <div>
                      <h5 className="sub-title">Genre</h5>
                      <div className="d-flex genre-list mb-5">
                        {movieDetails.movieGenres.map((data, idx) => (
                          <div className="genre-name" key={idx}>
                            {data.genre && data.genre.genreName
                              ? data.genre.genreName
                              : "-"}
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}

              </div>
            </div>
          </div>
        </div>

        {/* <Modal
          show={this.state.watchNowModal}
          className="movie-songs-modal copyright-info-modal"
        >
          <Modal.Body>
            <Row className="copyright-info-content">
              <Col sm={12}>
                <p>
                  The Unauthorized reproduction or distribution of this
                  copyrighted work is illegal. Criminal copyright infringement
                  without monetary gain also is punishable by up to 3 years in
                  jail and a fine of Rs 3,00,000
                </p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="acc-close-btn"
              onClick={() => {
                this.getMovieLink(movieDetails.movieId);
              }}
            >
              Accept
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ watchNowModal: false });
              }}
              className="close-btn"
            >
              Decline
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    );
  }
}

export { PubMovieDetails };
