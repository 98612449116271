import React from "react";
import { Header } from "../../../../../_layouts/header";
import { ProfileHeader } from "../../../../../_layouts/profileHeader";
import { Sidebar } from "../../../../../_layouts/sidebar";
import { Container, Row, Col, Button } from "react-bootstrap";
import { common } from "../../../../../_helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyDGe5vjL8wBmilLzoJ0jNIwe9SAuH2xS_0");
Geocode.enableDebug();

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class MyLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      setAddress: "",
    };
  }

  handleSelect = () => {};

  handleInputChange = (event) => {
    const { setAddress } = event.target.value;
    this.setState({ setAddress: setAddress });
  };

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    return (
      <div>
        <Header />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="profileContent_detail">
                  <div className="myProfile_header">
                    <h3>Settings</h3>
                  </div>

                  <div>
                    <h5 style={{ color: "#fff" }}>My Location</h5>
                  </div>

                  <div className="mapDiv">
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.state.setAddress}
                      onSelect={this.handleSelect()}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Type Addressssss",
                            })}
                            name="setAddress"
                            value={this.state.setAddress}
                            onChange={this.handleInputChange}
                          />

                          <div>
                            {loading ? <div>...loading</div> : null}
                            {suggestions.map((suggestions) => {
                              const style = {
                                backgroundColor: suggestions.active
                                  ? "#ebba20"
                                  : "#fff",
                                color: suggestions.active ? "#fff" : "#000",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestions, {
                                    style,
                                  })}
                                >
                                  {suggestions.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {/* {({ }) => (<div>Hello</div>)} */}
                    </PlacesAutocomplete>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDuUkopRJ8P-U1fehBehZIJ6cTDufpWy04",
                      }}
                      defaultCenter={this.props.center}
                      defaultZoom={this.props.zoom}
                    >
                      <AnyReactComponent
                        lat={11.0168}
                        lng={76.9558}
                        text="Location"
                      />
                    </GoogleMapReact>
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <Button className="set-location-btn">
                      Set My Location
                    </Button>
                  </div>
                  <div>
                    <Button className="go-back-btn">Go Back</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { MyLocation };
