import React, { Component } from "react";
import "../css/Film.css";
import { Button, Form, Col } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { common } from "../../../_helpers";
import { apiServices } from "../../../api";

class Step1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          errors: {
            originalTitle: {
              error: "",
              isReq: "Original Title is required",
            },
            englishTitle: {
              error: "",
              isReq: "English Title is required"
            },
            countryProduction: {
              error: '',
              isReq: 'Country of Production is required',
            },
            runningTime: {
              error: '',
              isReq: 'Running Time is required'
            },
            yearProduced: {
              error: '',
              isReq: 'Year Produced is required'
            },
            yearReleased: {
              error: '',
              isReq: 'Year Released is required'
            },
            originalLanguage: {
              error: '',
              isReq: 'Original Language is required'
            },
            provideLink: {
              error: '',
              isReq: 'Provide Link is required'
            },
            briefSynopsis: {
              error: '',
              isReq: 'Brief Synopsis is required'
            },
          },
            fields: {
              originalTitle: {
                criteria: {required: true},
                value: '',
                label: 'Original Title',
                field: 'Original Title'
              },
              englishTitle: {
                criteria: {required: true},
                value: '',
                label: 'English Title',
                field: 'English Title'
              },
              countryProduction: {
                criteria: {required: true},
                value: '',
                label: 'Country of Production',
                field: 'Country(IES) of Production'
              },
              runningTime: {
                criteria: {required: true},
                value: '',
                label: 'Running Time',
                field: 'Running Time (In Minutes)'
              },
              yearProduced: {
                criteria: {required: true},
                value: '',
                label: 'Year Produced',
                field: 'Year Produced'
              },
              yearReleased: {
                criteria: {required: true},
                value: '',
                label: 'Year Released',
                field: 'Year Released (If Applicable)'
              },
              originalLanguage: {
                criteria: {required: true},
                value: '',
                label: 'Original Language',
                field: 'Original Language'
              },
              // genre: {
              //   criteria: {required: false},
              //   value: '',
              //   label: 'GENRE',
              //   field: 'GENRE(CHOOSE ONE THAT APPLY)'
              // },
              provideLink: {
                criteria: {required: true},
                value: '',
                label: 'Provide Link',
                field: 'Submit Film(Provide Link)*'
              },
              briefSynopsis: {
                criteria: {required: true},
                value: '',
                label: 'Brief Synopsis',
                field: 'Brief Synopsis of Film*'
              },
            },
            isLoading: false,
            formSubmit: false,
            agreed: false,
            keyboardHeight: 0,
            location: {},
            currentPosition: 0,
            numColumns: 2,
            genreError: "Please select atlease one genre",
            selectedGenre: [],
            genreList: []
    }
}

async componentDidMount() {
  const { fields } = this.state;
  this.getGenre();
  let stepOne = localStorage.getItem("stepOneDetails");  
    if (stepOne) {
      let stepOneDetails = JSON.parse(stepOne);
      fields.originalTitle.value = stepOneDetails.movieName;
      fields.englishTitle.value = stepOneDetails.movieNameEn;
      fields.countryProduction.value = stepOneDetails.countryOfProduction;
      fields.runningTime.value = stepOneDetails.runningTime;
      fields.yearProduced.value = stepOneDetails.yearProduced;
      fields.yearReleased.value = stepOneDetails.yearReleased;
      fields.originalLanguage.value = stepOneDetails.primaryLanguage;
      fields.provideLink.value = stepOneDetails.website;
      fields.briefSynopsis.value = stepOneDetails.movieDescription;
      this.setState({
        fields,
        selectedGenre: stepOneDetails.genre
      })
    }
}

getGenre = () => {
  let data = {
    url: "PUBLIC_GENRE",
    method: "GET",
  };
  apiServices.call(
    data,
    (response) => {
      let genre = [];
      response.data.content.length > 0 &&
        response.data.content.map((item) => {
          let data = {
            name: item.genreName,
            id: item.genreId,
            ordering: item.ordering
          };
          genre.push(data);
        });
      this.setState({
        genreList: genre,
      });
    },
    (error) => {},
    (final) => {}
  );
};

onChange = async (event) => {
  const { name, value } = event.target;
  const { fields } = this.state;
  fields[name].value = value;
  this.setState(
    {
      fields: fields,
    },
    () => {
      this.validateField(name, value);
    }
  );
};

 // validation
 validateField = (name, value) => {
  const { errors, fields } = this.state;
  if (errors.hasOwnProperty(name)) {
    if (!value) {
      errors[name].error = errors[name].isReq;
    } else {
      errors[name].error = "";
    }
  }

  this.setState({
    errors: errors,
  });
};

validateForm() {
  const { fields, errors } = this.state;
  let isValid = true;
  for (var key of Object.keys(errors)) {
    if (fields[key].value == "" || fields[key].value == null) {
      errors[key].error = errors[key].isReq;
      isValid = false;
    }
  }
  return isValid
}

  goToNext = () => {
    const { genreList, genreError, fields, selectedGenre } = this.state;
    this.setState({ formSubmit: true, genreError });
    let isValid = this.validateForm();
    if (isValid) {
               let stepOneDetails = {
                 movieName: fields.originalTitle.value,
                 movieNameEn: fields.englishTitle.value,
                 countryOfProduction: fields.countryProduction.value,
                 runningTime: fields.runningTime.value,
                 yearProduced: fields.yearProduced.value,
                 yearReleased: fields.yearReleased.value,
                 primaryLanguage: fields.originalLanguage.value,
                 genre: selectedGenre,
                 website: fields.provideLink.value,
                 movieDescription: fields.briefSynopsis.value
               }
               localStorage.setItem("stepOneDetails", JSON.stringify(stepOneDetails))
               this.props.goNext();
    }
  };

onGenreSelect = (selectedList, selectedItem) => {
  this.setState({
    selectedGenre: selectedList,
  },
  _=> {
    if(this.state.selectedGenre.length !== 0){
      this.setState({
        genreError: ""
      })
    }
  });
};

onGenreRemove = (selectedList, removedItem) => {
  this.setState(
    {
      selectedGenre: selectedList,
    },
    _=> {
      if(this.state.selectedGenre.length == 0){
        this.setState({
          genreError: "Please select atlease one genre"
        })
      }
    });
};


    render() {
        const { errors, fields, genreList, formSubmit, genreError } = this.state;
        return (
            <div className="step1">
                <h4 className="film-heading">FILM INFORMATION</h4>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.originalTitle.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"originalTitle"}
                            placeholder={fields.originalTitle.label}
                            value={fields.originalTitle.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.originalTitle.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.originalTitle.error}
                               </span>
                            )}
                    </Form.Group>
                    </Form.Row>

                    <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.englishTitle.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"englishTitle"}
                            placeholder={fields.englishTitle.label}
                            value={fields.englishTitle.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.englishTitle.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.englishTitle.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>
               
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.countryProduction.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"countryProduction"}
                            placeholder={fields.countryProduction.label}
                            value={fields.countryProduction.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.countryProduction.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.countryProduction.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.runningTime.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"runningTime"}
                            placeholder={fields.runningTime.label}
                            value={fields.runningTime.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.runningTime.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.runningTime.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.yearProduced.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"yearProduced"}
                            placeholder={fields.yearProduced.label}
                            value={fields.yearProduced.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.yearProduced.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.yearProduced.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.yearReleased.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"yearReleased"}
                            placeholder={fields.yearReleased.label}
                            value={fields.yearReleased.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.yearReleased.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.yearReleased.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.originalLanguage.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"originalLanguage"}
                            placeholder={fields.originalLanguage.label}
                            value={fields.originalLanguage.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.originalLanguage.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.originalLanguage.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{"GENRE(CHOOSE ONE THAT APPLY)"}
                            </Form.Label>
                            <Multiselect
                                options={genreList}
                                selectedValues={this.state.selectedGenre}
                                onSelect={this.onGenreSelect}
                                onRemove={this.onGenreRemove}
                                displayValue="name"
                                placeholder="Select Genre"
                                style={{
                                  chips: { background: "#fed30a", color: "black" },
                                  searchBox: {
                                    // border: "none",
                                    "border": "1px solid #ced4da",
                                    "border-radius": "0px",
                                  },
                                  option: {
                                    color: "black",
                                  },
                                }}
                              />
                        {genreError !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {genreError}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.provideLink.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"provideLink"}
                            placeholder={fields.provideLink.label}
                            value={fields.provideLink.value}
                            onChange={(e) => this.onChange(e)}
                        />
                          {errors.provideLink.error !== "" && formSubmit && (
                                 <span className="rt-submit-err">
                                  {errors.provideLink.error}
                                 </span>
                              )}
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.briefSynopsis.field}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"briefSynopsis"}
                            placeholder={fields.briefSynopsis.label}
                            value={fields.briefSynopsis.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.briefSynopsis.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                                {errors.briefSynopsis.error}
                               </span>
                            )}
                    </Form.Group>
                </Form.Row>

                    <Button
                        className="continue-btn"
                        onClick={() => this.goToNext()}
                    >
                        Continue
                    </Button>
            </div>
        )
    }
}

export { Step1 }