import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import Plyr from "plyr";
import "./VideoPlayer.css";
export default function VideoPlayer({ src, isMovie, playId, user, onPlay }) {
  const [waterTop, setWaterTop] = useState(20);
  const [waterLeft, setWaterLeft] = useState(20);
  const videoRef = useRef(null);
  const el = useRef(null);
  let interval;

  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;
    video.controls = true;
    const defaultOptions = {};
    video.lastSeekTime = 500;
    //const player = new Plyr(video, {captions: {active: true, update: true, language: 'en'}});
    if (!src.includes("https://")) {
      // Initialize here
      const player = new Plyr(video, defaultOptions);
      player.source = {
        type: "video",
        sources: [
          {
            src: src,
            provider: "youtube",
          },
        ],
      };
    } else if (Hls.isSupported()) {
      // This will run in all other modern browsers
      const hls = new Hls();
      hls.loadSource(src);
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        // Transform available levels into an array of integers (height values).
        const availableQualities = hls.levels.map((l) => l.height);
        availableQualities.unshift(0); //prepend 0 to quality array
        defaultOptions.captions = {
          active: true,
          update: true,
          language: "en",
        };
        // Add new qualities to option
        defaultOptions.quality = {
          default: 0, //Default - AUTO
          options: availableQualities,
          // this ensures Plyr to use Hls to update quality level
          forced: true,
          onChange: (e) => {
            hls.levels.forEach((level, levelIndex) => {
              if (level.height === e) {
                console.log("Found quality match with " + e);
                hls.currentLevel = levelIndex;
              }
            });
            console.log({ hls });
          },
        };
        defaultOptions.i18n = {
          qualityLabel: {
            0: "Auto",
          },
        };

        hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
          var span = el.current.querySelector(
            ".plyr__menu__container [data-plyr='quality'][value='0'] span"
          );
          if (span) {
            if (hls.autoLevelEnabled) {
              span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
            } else {
              span.innerHTML = `AUTO`;
            }
          }
        });
        // Initialize here
        const player = new Plyr(video, defaultOptions);
        // Handle changing captions
        player.on("languagechange", () => {
          // Caption support is still flaky.
          setTimeout(() => (hls.subtitleTrack = player.currentTrack), 50);
        });
        hls.attachMedia(video);
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      // This will run in safari, where HLS is supported natively
      video.src = src;
      const player = new Plyr(video, defaultOptions);
    } else {
      console.error(
        "This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API"
      );
    }
    if (isMovie == true) {
      interval = setInterval(() => setWaterMark(), 5000);
    }

    let previousPlayheadPosition = getCookie("playheadPosition" + playId);
    if (previousPlayheadPosition) {
      video.currentTime = previousPlayheadPosition;
    }
    video.addEventListener("timeupdate", () => {
      setCookie("playheadPosition" + playId, video.currentTime);
    });
    video.addEventListener("play", (e) => {
      onPlay("play", e);
    });
    video.addEventListener("pause", (e) => {
      onPlay("pause", e);
    });
  }, [src, videoRef, el]);

  const setWaterMark = () => {
    setTimeout(() => {
      let container = el;
      // let contWidth = container.current.offsetWidth;
      let contHeight =
        container.current &&
        container.current.offsetHeight &&
        container.current.offsetHeight;
      let watermarkTop = Math.floor(Math.random() * contHeight) + 1;
      let watermarkLeft = Math.floor(Math.random() * contHeight) + 1;
      setWaterTop(watermarkTop);
      setWaterLeft(watermarkLeft);
    }, 50);
  };

  // update a cookie
  const setCookie = (cname, cvalue) => {
    let d = new Date();
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); // store 7 days
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  // fetch a cookie
  const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  return (
    <div ref={el}>
      <video ref={videoRef} autoPlay={true} />
      {isMovie == true && user && (
        <p className="watermak" style={{ top: waterTop, left: waterLeft }}>
          {user.userId}
        </p>
      )}
      <style jsx>{`
        video {
          width: 100%;
          height: 600px;
        }

        :root {
          --plyr-color-main: #f4d03f;
        }
      `}</style>
    </div>
  );
}
