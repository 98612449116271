import React from "react";
import { Container, Row, Col, Media, Button } from "react-bootstrap";
import { common, history } from "../_helpers";
class ProfileHeader extends React.Component {
   
    constructor(props) {
        super(props);
        const userData = JSON.parse(localStorage.getItem("user"));
        this.state = {
            username: userData && userData.data && userData.data.firstName ? userData.data.firstName : "",
            email: userData && userData.data && userData.data.email ? userData.data.email : userData && userData.data && userData.data.emailId ? userData.data.emailId : ""
        }
      }

    // Logout user
    logoutUser = () => {
        localStorage.clear()
        common.redirect("/");
    }

    render() {
        return (
                <div className="profile_bg">
                    <div className="custom_width">
                        <Container>
                            <div className="profile_position">
                            <div className="profile_header">
                            <Row>
                                <Col lg={10} md={9}>
                                    <Media>
                                        <img src={common.loadImg("user_icon.png")} alt="profile" 
                                        className="profile_image mr-3" />
                                        <Media.Body>
                                            <h5>{this.state.username}</h5>
                                            <p> {this.state.email} </p>
                                        </Media.Body>
                                    </Media>
                                </Col>
                                <Col lg={2} md={3}>
                                    <Button variant="primary-btn" onClick={this.logoutUser}>Sign out</Button>
                                </Col>
                            </Row>
                            </div> {/* profile header end here */}
                            </div> {/* profile position end here */ }
                        </Container>
                    </div> {/* custom width end here */}
                </div> 
        );
    }
}
export { ProfileHeader };
