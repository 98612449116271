import React, { Component } from "react";
import { Header } from "../../_layouts/header";

class NotFound extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="page-not-found-wrap text-center">
          <h1>404</h1>
          <h3>Page Not Found</h3>
          <a href="/" className="btn btn-bordered">Go to Home Page</a>
        </div>
      </div>
    );
  }
}

export { NotFound };
