import React, { Component } from "react";
import { Route } from "react-router-dom";
import { common } from "../_helpers";

// Import Layouts
import { Home, Signup } from "../_layouts";

export const SignupLayout = ({ auth: auth, component: Component, ...rest }) => {
  return (
    !checkAuth(auth) && (
      <Route
        {...rest}
        render={(matchProps) => (
          <Signup>
            <Component {...matchProps} />
          </Signup>
        )}
      />
    )
  );
};

export const HomeLayout = ({ auth: auth, component: Component, ...rest }) => {
  return (
    !checkAuth(auth) && (
      <Route
        {...rest}
        render={(matchProps) => (
          <Home>
            <Component {...matchProps} />
          </Home>
        )}
      />
    )
  );
};



function checkAuth(auth) {
  let token = localStorage.getItem("userToken");
  let redirect = false;
  if (auth === true && token == null) {
    common.redirect("/", 0);
    redirect = true;
  } 
  else if (auth === false && token != null) {
    common.redirect("/", 0);
    redirect = true;
  }

  return redirect;
}
