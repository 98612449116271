import React from "react";
import { common } from "../_helpers";

class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    
  }
  render() {
    return (
     <div className="coming-soon-wrap text-center mt-5 mb-5">
       <img src={common.loadImg("comingsoon.png")} alt="Coming Soon" height="200" className="mb-5" />
       <p>We are currently working on this page<br/> and We will be back soon!</p>
     </div>
    );
  }
}
export { ComingSoon };
