import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import { apiServices } from "../../../api";
import { StatusBar } from "../../../_includes";
import "../css/Producer.css";
import { Helmet } from "react-helmet";
import { envConfig } from "../../../api/config";

class ProducerMovies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelId: this.props.match.params.channel_id,
      channelMoviesList: [],
      status: "process",
      paging: {
        page: 1,
        size: 30,
        total: 1,
      },
    };
  }

  componentDidMount() {
    common.checkRole("Producer", true);
    this.getchannelMoviesList("init");
  }

  getchannelMoviesList(type) {
    const { channelId, channelMoviesList, paging } = this.state;
    let data = {
      url: "GET_MY_CHANNEL_MOVIES",
      method: "GET",
      query:
        "/" + channelId + "?page=" + (paging.page - 1) + "&size=" + paging.size,
      // "&sort=createdOn,desc",
    };

    apiServices.call(
      data,
      (response) => {
        if(response.data.totalElements>0){
          response.data.content.map((item, key) => {
            channelMoviesList.push(item);
          });
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          channelMoviesList,
          paging,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {
        if (type == "load") {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        status: "process",
        paging,
      },
      () => {
        this.getchannelMoviesList("load");
      }
    );
  }

  refreshData() {
    this.setState(
      {
        channelMoviesList: [],
        status: "process",
        paging: {
          page: 1,
          size: 30,
          total: 1,
        },
      },
      () => {
        this.getchannelMoviesList("init");
      }
    );
  }

  gotoOrder(e, id) {
    e.preventDefault();
    const { channelId } = this.state;
    common.redirect("/producer/orders/" + id + "/" + channelId);
  }

  goBack() {
    common.redirect("/producer/channels", 0);
  }

  render() {
    const { channelMoviesList, paging, status } = this.state;
    return (
      <div>
        <Header />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <div className="producer-content-wrap">
                <div className="myProfile_header order-detail-wrap-header">
                  <Row>
                    <Col md={10}>
                      <h3>My Movies</h3>
                    </Col>
                    <Col md={2} className="rt-right">
                      <Button
                        className="btn-booknow btn-goback"
                        onClick={() => this.goBack()}
                      >
                        Go Back
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="producer-content">
                  <StatusBar page="movieOrder" loader="small" status={status} />
                  {status == "success" &&
                    (channelMoviesList == "" ? (
                      <StatusBar
                        page="movieOrder"
                        type="channelMoviesList"
                        status={"noData"}
                      />
                    ) : (
                      <div>
                        <ul className="row channel-list">
                          {channelMoviesList.map((item, key) => (
                            <li className="col-md-4 mb-4 text-center" key={key}>
                              <a
                                href="#"
                                onClick={(e) => this.gotoOrder(e, item.movieId)}
                              >
                                {item.image ? (
                                  <img
                                    src={
                                      item.image
                                      ? item.image.includes("https://")
                                        ? item.image
                                        : envConfig.IMAGE_URL1 +
                                        item.image
                                      : ""}
                                    alt="Channel Image"
                                    className="pc-img mb-3"
                                  />
                                ) : (
                                  <img
                                    src={common.loadImg("no_movie.png")}
                                    alt="Channel Image"
                                    className="pc-img mb-3"
                                  />
                                )}
                                <h2 className="pc-title">{item.movieName}</h2>
                              </a>
                            </li>
                          ))}
                        </ul>
                        {paging.page != paging.total && (
                          <a
                            href="#"
                            onClick={(e) => this.pagination(e)}
                            className="loadMore"
                          >
                            <img src={common.loadImg("loadmore.png")} /> Load
                            More
                          </a>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { ProducerMovies };
