import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Media,
  Button,
  Modal,
} from "react-bootstrap";
import "../css/Movies.css";

import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import { apiServices } from "../../../api";
import { Multiselect } from "multiselect-react-dropdown";
// package
import moment from "moment";
import { Helmet } from "react-helmet";
import { envConfig } from "../../../api/config";

/** Import React Slick Slider files */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StatusBar } from "../../../_includes";

class Movies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicMovieAllList: [],
      status: "process",
      paging: {
        page: 1,
        size: 12, // 20
        total: 1,
      },
      languageList: [],
      genreList: [],
      selectedLanguages: [],
      selectedGenres: [],
      streamingList: [],
      isRating: false,
      isDate: false,
      publicUpcomingMovieList: [],
      collections: [],
    };
  }

  componentDidMount() {
    if (this.props.location.pathname == "/movies") {
      this.getMovies();
    }
    this.getLanguage();
    this.getGenre();
    this.upcomingMovies();
    this.getStreamingMovies();
    this.getCollections();
  }

  getFilterMovies = () => {
    const { languageList, selectedLanguages, selectedGenres, genreList } =
      this.state;
    let selectLanguageVal = [];
    let selectGenreVal = [];
    let language = this.props.match.params.language
      ? this.props.match.params.language.split(",")
      : [];
    let genre = this.props.match.params.genre
      ? this.props.match.params.genre.split(",")
      : [];

    selectLanguageVal =
      languageList &&
      languageList.length > 0 &&
      languageList.filter(function (item) {
        return (
          language &&
          language.length > 0 &&
          language.find((element) => {
            return item.name.toLowerCase() == element.toLowerCase();
          })
        );
      });

    selectGenreVal =
      genreList &&
      genreList.length > 0 &&
      genreList.filter(function (item) {
        return (
          genre &&
          genre.length > 0 &&
          genre.find((element) => {
            return item.name.toLowerCase() == element.toLowerCase();
          })
        );
      });

    this.setState(
      {
        selectedLanguages: selectLanguageVal,
        selectedGenres: selectGenreVal,
      },
      (_) => {
        this.filterMovies();
      }
    );
  };

  /* Get All Streaming Movies from DB. */
  getStreamingMovies = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_STREAMING_NOW_MOVIES",
        query: "?page=0&size=12&sort=streaming_start_date,desc",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_STREAMING_NOW",
        query: "?page=0&size=12&sort=streaming_start_date,desc",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        this.setState({
          streamingList: response.data,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  upcomingMovies = () => {
    const { paging, publicUpcomingMovieList } = this.state;
    let data = {
      url: "PUBLIC_UPCOMING_MOVIES",
      query:
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=streamingStartDate,asc",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        let movieList = [];
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            publicUpcomingMovieList.push(item);
          });
          movieList = this.groupMovies(publicUpcomingMovieList, "releaseMonth");
          // paging
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          movieList,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  getMovies = () => {
    const { paging, publicMovieAllList } = this.state;
    let data = {
      url: "PUBLIC_MOVIES",
      query:
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=streamingStartDate,desc",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        let movieList = [];
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            publicMovieAllList.push(item);
          });
          movieList = this.groupMovies(publicMovieAllList, "releaseMonth");
          // paging
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          publicMovieAllList: response.data.content,
          movieList,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  groupMovies = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        status: "process",
        paging,
      },
      () => {
        this.getMovies();
      }
    );
  }

  getLanguage = () => {
    let data = {
      url: "PUBLIC_LANGUAGE",
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        let language = [];
        response.data.content.length > 0 &&
          response.data.content.map((item) => {
            let data = {
              name: item.languageName,
              id: item.languageId,
            };
            language.push(data);
          });
        this.setState(
          {
            languageList: language,
          },
          (_) => {
            if (this.props.location.pathname !== "/movies") {
              if (this.props.match.params.language) {
                this.getFilterMovies();
              }
            }
          }
        );
      },
      (error) => {},
      (final) => {}
    );
  };

  getGenre = () => {
    let data = {
      url: "PUBLIC_GENRE",
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        let genre = [];
        response.data.content.length > 0 &&
          response.data.content.map((item) => {
            let data = {
              name: item.genreName,
              id: item.genreId,
            };
            genre.push(data);
          });
        this.setState(
          {
            genreList: genre,
          },
          (_) => {
            if (this.props.location.pathname !== "/movies") {
              if (this.props.match.params.genre) {
                this.getFilterMovies();
              }
            }
          }
        );
      },
      (error) => {},
      (final) => {}
    );
  };

  onLanguageSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedLanguages: selectedList,
    });
  };

  onLanguageRemove = (selectedList, removedItem) => {
    this.setState(
      {
        selectedLanguages: selectedList,
      },
      (_) => {
        // if(this.state.selectedLanguages.length == 0){
        //   this.getMovies();
        // }
      }
    );
  };

  onGenreSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedGenres: selectedList,
    });
  };

  onGenreRemove = (selectedList, removedItem) => {
    this.setState(
      {
        selectedGenres: selectedList,
      },
      (_) => {
        // if(this.state.selectedLanguages.length == 0){
        //   this.getMovies();
        // }
      }
    );
  };

  filterMovies = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      selectedLanguages,
      selectedGenres,
      publicMovieAllList,
      paging,
      isRating,
      isDate,
    } = this.state;
    let language = "";
    let urlLanguage = "";
    let genre = "";
    let urlGenre = "";
    selectedLanguages &&
      selectedLanguages.length > 0 &&
      selectedLanguages.map((item, key) => {
        language += item.name + ";";
        urlLanguage += key == 0 ? item.name : "," + item.name;
      });
    selectedGenres &&
      selectedGenres.length > 0 &&
      selectedGenres.map((item, key) => {
        genre += item.id + ";";
        urlGenre += key == 0 ? item.name : "," + item.name;
      });
    // this.setState({
    //     isLoading: true,
    // });
    let url = "";
    if (isDate) {
      url += ",releaseDate-";
    }

    if (isRating) {
      url += ",avgRating-";
    }

    if (language !== "") {
      url += ",primaryLanguageLISTSTR=" + language;
    }

    if (genre !== "") {
      url += ",movieGenresDSgenreIdLIST=" + genre;
    }

    if (!isDate && !isRating) {
      url += ",movieId>0,releaseDate-";
    }
    var data = {
      url: "PUBLIC_FITER_MOVIES",
      query: url + "&page=" + (paging.page - 1) + "&size=" + paging.size,
      body: null,
      authorization: "none",
      method: "GET",
    };

    apiServices.call(
      data,
      async (response) => {
        if (response.error) {
          console.log("========error", response);
        } else {
          let movieList = [];
          // this.setState({
          //   publicMovieAllList: []
          // })
          // if (response.data.totalElements > 0) {
          //   await response.data.content.map((item) => {
          //     publicMovieAllList.push(item);
          //   });
          // }
          //   movieList = await this.groupMovies(publicMovieAllList, "releaseMonth");
          //   // paging
          //   paging.total = await Math.ceil(response.data.totalElements / paging.size);

          this.setState({
            publicMovieAllList: response.data.content,
            movieList,
            status: "success",
          });
          if (e) {
            if (urlLanguage && urlGenre == "") {
              common.redirect("/movies/search/language/" + urlLanguage);
            }
            if (urlGenre && urlLanguage == "") {
              common.redirect("/movies/search/genre/" + urlGenre);
            }
            if (urlGenre && urlLanguage) {
              common.redirect(
                "/movies/search/language/" + urlLanguage + "/genre/" + urlGenre
              );
            }
            if (urlGenre == "" && urlLanguage == "") {
              common.redirect("/movies");
            }
          }
        }
      },
      (error) => {
        // common.snack("E", error);
      },
      (final) => {}
    );
  };

  setValues = (fields, value) => {
    const { isDate, isRating } = this.state;
    this.setState({
      [fields]: value,
    });
  };

  resetFilter = (e) => {
    e.preventDefault();
    this.setState(
      {
        isDate: false,
        isRating: false,
        selectedGenres: [],
        selectedLanguages: [],
      },
      (_) => {
        common.redirect("/movies");
        this.getMovies();
      }
    );
  };

  gotoMovies(e, id, key, name) {
    e.preventDefault();
    if (common.isAuth()) {
      common.redirect("/movies-details/" + id);
    } else {
      var movieName = name.replace(/[^A-Z0-9]+/gi, "-");
      common.redirect("/v/" + movieName.toLowerCase() + "/" + key);
    }
  }

  goToUpcoming = (e) => {
    e.preventDefault();
    common.redirect("/upcoming");
  };

  /* Get All Collections from DB. */
  getCollections = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_COLLECTIONS",
        query: "?sort=ordering,asc",
        method: "GET",
      };
    } else {
      data = {
        url: "GET_COLLECTIONS",
        query: "?sort=ordering,asc",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          collections: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  render() {
    const {
      publicMovieAllList,
      languageList,
      genreList,
      isDate,
      isRating,
      publicUpcomingMovieList,
      paging,
      streamingList,
      selectedLanguages,
      selectedGenres,
      collections,
      status,
    } = this.state;
    let posterImg = "https://d13heh6lmag6t5.cloudfront.net/web/streamingnow/";
    let sliderSettings = {
      dots: true,
      arrows: false,
      centerPadding: "40px",
      centerMode: true,
      slidesToShow: 3,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      pauseOnHover: true,
      infinite:
        streamingList &&
        streamingList.content &&
        streamingList.content.length > 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="cb-inner-page-wraper">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Movies List</title>
          <meta
            name="description"
            content="Watch new movies releasing on Cinebazzar OTT | Digital Theater on your favorite devices from anywhere with internet"
          />
        </Helmet>
        <div className="slider mb-5">
          <div className="movieslider_margin">
            <div className="streaming-now-wrap">
              <Slider {...sliderSettings}>
                {streamingList &&
                  streamingList.content &&
                  streamingList.content.length > 0 &&
                  streamingList.content.map((movie, idx) => (
                    <div className="slider_style" key={idx}>
                      <a
                        href="#"
                        onClick={(e) =>
                          this.gotoMovies(
                            e,
                            movie.movieId,
                            movie.movieKey,
                            movie.movieName
                          )
                        }
                      >
                        <img
                          src={common.loadCloudImg(movie.webStreamingNow)}
                          alt={movie.movieName}
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="container">
          {/* <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Movies</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Movies</li>
            </ul>
          </div> */}
          <div className="cb-inner-wrap">
            <div className="movie-filter-wrap mb-5">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="d-flex align-items-center">
                    <h3 className="filter-title mr-3">Sort by</h3>
                    <button
                      className={
                        "btn-sorting mr-3" + (isRating == true ? " active" : "")
                      }
                      onClick={() => this.setValues("isRating", !isRating)}
                    >
                      Rating
                    </button>
                    <button
                      className={
                        "btn-sorting" + (isDate == true ? " active" : "")
                      }
                      onClick={() => this.setValues("isDate", !isDate)}
                    >
                      Dates
                    </button>
                  </div>
                </div>
                <div className="col-md-7">
                  <form>
                    <div className="form-row row align-items-center justify-content-between">
                      <h3 className="filter-title col-md-2">Filter by</h3>
                      <div className="col-md-3">
                        <label className="sr-only" for="language">
                          Language
                        </label>
                        <Multiselect
                          selectedValues={selectedLanguages}
                          options={languageList}
                          onSelect={this.onLanguageSelect}
                          onRemove={this.onLanguageRemove}
                          displayValue="name"
                          placeholder="Select Language"
                          style={{
                            chips: { background: "#fed30a", color: "black" },
                            searchBox: {
                              border: "1px #ffffff solid",
                              "border-radius": "5px",
                              color: "white",
                              background: "rgba(255, 255, 255, 0.5)",
                            },
                            option: {
                              color: "black",
                            },
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label class="sr-only" for="genre">
                          Genre
                        </label>
                        <Multiselect
                          selectedValues={selectedGenres}
                          options={genreList}
                          onSelect={this.onGenreSelect}
                          onRemove={this.onGenreRemove}
                          displayValue="name"
                          placeholder="Select Genre"
                          style={{
                            chips: { background: "#fed30a", color: "black" },
                            searchBox: {
                              border: "1px #ffffff solid",
                              "border-radius": "5px",
                              color: "white",
                              background: "rgba(255, 255, 255, 0.5)",
                            },
                            option: {
                              color: "black",
                            },
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn-apply mr-1"
                          onClick={(e) => this.filterMovies(e)}
                        >
                          Apply
                        </button>
                        <button
                          type="submit"
                          className="btn-apply"
                          onClick={(e) => this.resetFilter(e)}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="section-title d-flex justify-content-between align-items-center">
              <h2>TRENDING MOVIES</h2>
            </div>
            <StatusBar page="movie" loader="small" status={status} />
            {publicMovieAllList &&
              status == "success" &&
              publicMovieAllList.length == 0 && (
                <StatusBar page="movie" loader="small" status={"noData"} />
              )}
            <ul className="all-movies-list row mb-5">
              {publicMovieAllList &&
                publicMovieAllList.length > 0 &&
                publicMovieAllList.map((movie, key) => {
                  return (
                    <li
                      className="col-sm-4 col-md-3 col-lg-2 movie-image"
                      key={key}
                      onClick={(e) =>
                        this.gotoMovies(
                          e,
                          movie.movieId,
                          movie.movieKey,
                          movie.movieName
                        )
                      }
                    >
                      <a
                        href="#"
                        // onClick={(e) =>
                        //   this.gotoMovies(e, movie.movieId, movie.movieKey)
                        // }
                        key={key}
                      >
                        <img
                          src={common.loadCloudImg(movie.moviePoster)}
                          alt="Movie"
                        />
                        <div className="movie-rating movie-hide">
                          {movie.avgRating && movie.avgRating <= 5
                            ? movie.avgRating.toFixed(1)
                            : "-"}
                          /5
                        </div>

                        <div className="movie-detail movie-hide">
                          <h3 className="movie-name">{movie.movieName}</h3>
                          <p className="movie-info info">
                            {moment
                              .utc(movie.releaseDate, "DD-MM-YYYY HH:mm:ss")
                              .local()
                              .format("DD MMM YYYY")}{" "}
                            | {movie.primaryLanguage} |{" "}
                            {movie.movieGenres.length > 0 &&
                              movie.movieGenres[0].genre &&
                              movie.movieGenres[0].genre.genreName}
                          </p>
                        </div>
                      </a>
                    </li>
                  );
                })}
            </ul>

            {paging.total > 1 && paging.total != paging.page && (
              <div className="load-more mb-5 text-center">
                <a className="read-more text-uppercase">
                  <span onClick={(e) => this.pagination(e)}>Load More</span>
                </a>
              </div>
            )}

            {/* <div className="section-title d-flex justify-content-between align-items-center">
              <h2>Upcoming</h2>
              <a
                href="#"
                className="btn btn-primary"
                onClick={(e) => this.goToUpcoming(e)}
              >
                More
              </a>
            </div>
          } */}

            {publicUpcomingMovieList && publicUpcomingMovieList.length > 0 && (
              <div className="mb-5">
                <div className="section-title d-flex justify-content-between align-items-center">
                  <h2>UPCOMING</h2>
                  <a
                    href="#"
                    className="btn btn-primary"
                    onClick={(e) => this.goToUpcoming(e)}
                  >
                    More
                  </a>
                </div>

                <ul className="upcoming-list row">
                  {publicUpcomingMovieList.length > 0 &&
                    publicUpcomingMovieList.map((movies, key) => {
                      return (
                        <li
                          className="col-sm-4 col-md-3 col-lg-2 my-2"
                          key={key}
                          onClick={(e) =>
                            this.gotoMovies(
                              e,
                              movies.movieId,
                              movies.movieKey,
                              movies.movieName
                            )
                          }
                        >
                          <div className="slider_style">
                            <a href="#">
                              <img
                                src={common.loadCloudImg(movies.image)}
                                alt="Movie Poster"
                                className="img-fluid"
                              />
                            </a>
                            <div className="slider_book collect">
                              <h5>
                                <span>{movies.movieName}</span>
                              </h5>
                              <h6>
                                <span>{movies.primaryLanguage}</span>
                                <span>{movies.yearReleased}</span>
                                <span>
                                  {moment
                                    .utc(
                                      moment
                                        .duration(movies.runningTime, "minutes")
                                        .asMilliseconds()
                                    )
                                    .format("HH[h] mm[m]")}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}

            <div className="short-film-wrap mb-5">
              {collections && collections.length > 0 && (
                <div>
                  {collections.map((collection, key) => {
                    return (
                      <div className="container">
                        {collection.movieCollections &&
                          collection.movieCollections.length > 0 && (
                            <div className="section-title d-flex justify-content-between align-items-center">
                              <h2>{collection.name}</h2>
                              {/* <a href="/movies" className="btn btn-primary">
                              More
                            </a> */}
                            </div>
                          )}
                        <ul
                          className="short-film-list row custom-row"
                          key={key}
                        >
                          {collection.movieCollections &&
                            collection.movieCollections.length > 0 &&
                            collection.movieCollections.map((movie, index) => {
                              return (
                                <li className="movie-col" key={index}>
                                  <div className="slider_style">
                                    <a
                                      href={
                                        "/v/" +
                                        movie.movie.movieName
                                          .replace(/[^A-Z0-9]+/gi, "-")
                                          .toLowerCase() +
                                        "/" +
                                        movie.movie.movieKey
                                      }
                                    >
                                      <img
                                        src={common.loadCloudImg(
                                          movie.movie.thumbnail
                                        )}
                                        alt="Movie Poster"
                                        className="img-fluid"
                                      />
                                    </a>
                                    <div className="slider_book collect">
                                      <h5>
                                        <span>{movie.movie.movieName}</span>
                                      </h5>
                                      {/* <h6><span>{movie.movie.primaryLanguage}</span>
                                      <span>{movie.movie.yearReleased}</span>
                                      <span>{moment
                                        .utc(
                                          moment
                                            .duration(movie.movie.runningTime, "minutes")
                                            .asMilliseconds()
                                        )
                                        .format("HH[h] mm[m]")}
                                      </span></h6> */}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Movies };
