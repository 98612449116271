import React, { Component } from "react";
import "../css/Movies.css";
import { Header, DownloadApp } from "../../../_layouts/header";
import { common, tools, message, payment } from "../../../_helpers";
import { apiServices } from "../../../api";
// import { StatusBar, SocialShare } from "../../../_includes";
import { PayPalButton } from "react-paypal-button-v2";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
// package
import moment from "moment";
import { envConfig } from "../../../api/config";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data
      ticketInfo:
        localStorage.getItem("ticketInfo") != null
          ? JSON.parse(localStorage.getItem("ticketInfo"))
          : null,
      movieInfo: [],
      summaryInfo: [],
      // order
      orderId: null,
      orderDetails: [],
      isOrderLoading: false,
      isCompleteOrder: false,
      // discount
      coupon: {
        couponCode: "",
        couponType: "",
        couponValue: "",
        isLoading: false,
        apply: false,
        percentageValue: null,
      },
      status: "process",
      paymentLevel: "process",
    };
  }

  async componentDidMount() {
    const { ticketInfo } = this.state;
    if (ticketInfo == null) {
      common.redirect("/movies");
    } else {
      this.getMovieInfo();
    }
  }

  handleOnChange = (event) => {
    const { coupon } = this.state;
    coupon.couponCode = event.target.value;
    this.setState({ coupon });
  };

  getMovieInfo() {
    const { ticketInfo } = this.state;
    let data = {
      url: "GET_MOVIE_INFO",
      query: ticketInfo.movieId,
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        this.setState({
          movieInfo: response.data,
          summaryInfo: this.getSummary(response.data),
          status: "success",
        });
      },
      (error) => {
        common.snack("E", message.mp_no_movies);
        common.redirect("/movies", 2);
      },
      (final) => {}
    );
  }

  getSummary(movieInfo) {
    let ticketRate = parseFloat(movieInfo.ticketRate).toFixed(2);
    let subTotal = ticketRate;
    let taxValue = ticketRate ? (ticketRate * (18 / 100)).toFixed(2) : "0.00";
    let grandTotal = subTotal
      ? (parseFloat(subTotal) + parseFloat(taxValue)).toFixed(2)
      : "0.00";
    return {
      ticketRate,
      discount: 0,
      subTotal,
      taxValue,
      grandTotal,
    };
  }

  razarPayment() {
    const { summaryInfo, orderId } = this.state;
    const options = {
      key: payment.razorPay.production.key, //rzp_test_l9iFQytntMQUQx
      name: "Cinebazzar OTT",
      description: "Cinebazzar OTT",
      amount: summaryInfo.grandTotal * 100,
      receipt: orderId.toString(),
      notes: {
        merchant_order_id: orderId,
      },
      theme: {
        color: "#f4c21f",
      },
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;
          this.updatePaymentStatus(paymentId, "Razor Pay");
        } catch (err) {
          common.snack("E", "Payment has been failed!");
        }
      },
      modal: {
        ondismiss: async () => {
          this.updatePaymentStatus(0, "Razor Pay", true);
          common.snack("E", "Payment has been cancelled");
        },
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  async createOrder(paymentMode) {
    this.setState({ isOrderLoading: true });
    const { ticketInfo, movieInfo, orderId, summaryInfo, coupon } = this.state;
    let userData = JSON.parse(localStorage.getItem("user"));
    let myLocation = await common.myLocation();
    let param = {
      userId: userData.data.userId,
      bookingStartTime: moment(
        ticketInfo.date + " " + ticketInfo.time,
        "DD-MMMM-YYYY hh:mm A"
      )
        .utc()
        .format("DD-MM-YYYY HH:mm:ss"),
      bookingEndTime: moment(
        ticketInfo.date + " " + ticketInfo.time,
        "DD-MMMM-YYYY hh:mm A"
      )
        .utc()
        .add(1, "days")
        .utc()
        .format("DD-MM-YYYY HH:mm:ss"),
      unitPrice: movieInfo.ticketRate,
      orderQuantity: 1,
      uom: "Nos",
      discountPercentage: coupon.percentageValue,
      discountType: coupon.couponType,
      couponCode: coupon.apply ? coupon.couponCode : null,
      discountValue: summaryInfo.discount,
      totalOrderValue: movieInfo.ticketRate,
      taxPercentage: null,
      totalTaxValue: null,
      totalRoundedValue: movieInfo.ticketRate,
      orderDate: moment().utc().format("DD-MM-YYYY HH:mm:ss"),
      orderRemarks: null,
      paymentMode: paymentMode,
      orderCountry: myLocation.location.country,
      latitude: myLocation.location.lat,
      longitude: myLocation.location.lng,
      orderSource: "WEB",
    };

    let data = {};
    if (orderId) {
      data = {
        url: "GET_ORDER_DETAILS",
        query: orderId,
        body: param,
        method: "PUT",
      };
    } else {
      data = {
        url: "CREATE_MOVIE_ORDER",
        body: param,
        query: "/" + movieInfo.movieId + "/orders",
        method: "POST",
      };
    }

    apiServices.call(
      data,
      (response) => {
        this.setState(
          {
            orderDetails: response.data,
            orderId: response.data.movieOrderId,
          },
          () => {
            if (paymentMode == "Razor Pay") {
              this.razarPayment();
            } else if (paymentMode == "Coupon" || paymentMode == "FREE") {
              this.updatePaymentStatus(response.data.movieOrderId, paymentMode);
            }
          }
        );
      },
      (error) => {
        common.snack("E", message.somthing_wrong);
      },
      (final) => {
        this.setState({ isOrderLoading: false });
      }
    );
  }

  /* Update payment status in Database */
  updatePaymentStatus(paymentId, paymentMode, isCancelled) {
    const { orderId, orderDetails, movieInfo, summaryInfo } = this.state;

    if (orderId != null) {
      try {
        let param = {
          orderNo: orderDetails.orderNo,
          paymentTransactionNo: paymentId,
          paymentDate: moment().utc().format("DD-MM-YYYY hh:mm:ss A"),
          paidAmount: summaryInfo.grandTotal,
          paymentStatus: isCancelled ? "CANCELLED" : "PAID",
          paymentMode: paymentMode,
        };

        var data = {
          url: "UPDATE_PAYMENT_STATUS",
          body: param,
          query: "/" + orderId,
          method: "PUT",
        };

        apiServices.call(
          data,
          (response) => {
            if (
              (response.status === 200 || response.status === 202) &&
              response.data.orderNo
            ) {
              if (!isCancelled) {
                localStorage.removeItem("ticketInfo");
                common.snack("S", "Payment has been successful");
                // Social Share
                this.setState(
                  {
                    paymentLevel: "done",
                  },
                  () => {
                    // this.refs.socialShare.showModal("payment", movieInfo);
                  }
                );
                common.redirect(
                  "/movies-details/" + this.state.ticketInfo.movieId
                );
              }
            } else {
              common.snack(
                "E",
                "Something went wrong please contact administrator"
              );
            }
          },
          (error) => {
            common.snack(
              "E",
              "Something went wrong please contact administrator"
            );
          },
          (final) => {}
        );
      } catch (e) {
        common.snack("E", "Something went wrong please contact administrator");
      }
    }
  }

  // Discount
  movieCoupon() {
    const { ticketInfo, movieInfo, coupon } = this.state;
    if (coupon.apply) {
      coupon.apply = false;
      coupon.couponCode = "";
      coupon.couponType = "";
      coupon.couponValue = "";
      coupon.percentageValue = null;
      this.setState(
        {
          coupon,
          summaryInfo: this.getSummary(movieInfo),
        },
        (_) => {
          this.createOrder();
        }
      );
    } else {
      if (coupon.couponCode == "") {
        common.snack("E", "Please enter the discount code you have");
      } else {
        coupon.isLoading = true;
        this.setState({ coupon });

        var data = {
          url: "VALIDATE_MOVIE_COUPON",
          body: {
            movieId: ticketInfo.movieId,
            couponCode: coupon.couponCode,
          },
          method: "POST",
        };

        apiServices.call(
          data,
          (response) => {
            let result = response.data;
            if (response.status == 200 || response.status == 202) {
              let couponData = result.coupon;
              let ticketRate = parseFloat(movieInfo.ticketRate).toFixed(2);
              let discount = 0;
              let percentageValue = null;

              if (couponData.couponType == "PERCENTAGE") {
                discount = (
                  (ticketRate / 100) *
                  couponData.couponValue
                ).toFixed(2);
                percentageValue = couponData.couponValue;
              } else {
                // discount = (ticketRate - couponData.couponValue).toFixed(2);
                discount = couponData.couponValue.toFixed(2);
              }

              let taxValue = ((ticketRate - discount) * (18 / 100)).toFixed(2);
              let subTotal = (ticketRate - discount).toFixed(2);
              let grandTotal = (
                parseFloat(subTotal) + parseFloat(taxValue)
              ).toFixed(2);
              let summaryInfo = {
                ticketRate,
                discount,
                subTotal,
                taxValue,
                grandTotal,
              };
              coupon.apply = true;
              coupon.couponType = couponData.couponType;
              coupon.couponValue = couponData.couponValue;
              coupon.isLoading = false;
              coupon.percentageValue = percentageValue;
              this.setState(
                {
                  coupon,
                  summaryInfo,
                },
                (_) => {
                  if (percentageValue === 100) {
                    this.createOrder("Coupon");
                  } else {
                    common.snack("S", "Discount code has been applied");
                    const { orderId } = this.state;
                    if (orderId) {
                      this.createOrder();
                    }
                  }
                }
              );
            } else if (response.status == 500) {
              coupon.apply = false;
              coupon.couponType = "";
              coupon.couponValue = null;
              coupon.couponCode = "";
              coupon.percentageValue = null;
              coupon.isLoading = false;
              this.setState({ coupon });
              common.snack("E", result.error);
            } else {
              coupon.isLoading = false;
              this.setState({ coupon });
              common.snack("E", message.somthing_wrong);
            }
          },
          (error) => {
            coupon.isLoading = false;
            this.setState({ coupon });
            common.snack("E", message.somthing_wrong);
          },
          (final) => {
            coupon.isLoading = false;
            this.setState({ coupon });
          }
        );
      }
    }
  }

  completeOrder() {
    this.setState(
      {
        isCompleteOrder: true,
      },
      () => {
        this.createOrder("FREE");
      }
    );
  }

  render() {
    const {
      ticketInfo,
      movieInfo,
      summaryInfo,
      coupon,
      status,
      isOrderLoading,
      isCompleteOrder,
      paymentLevel,
    } = this.state;
    return (
      <div className="cb-inner-page-wraper">
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Payment Methods</h1>
            {/* <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Payment Methods</li>
            </ul> */}
          </div>

          {movieInfo != "" && (
            <div className="payment-content-wrap">
              <div className="row row-xs-pm cb-ticket-time-info-wrap">
                <div className="col-md-8 mb-5">
                  <div className="tickets cb-ticket-details">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <img
                        src={common.loadCloudImg(movieInfo.image)
                          // movieInfo.image
                          //   ? movieInfo.image.includes("https://")
                          //     ? movieInfo.image
                          //     : envConfig.IMAGE_URL + movieInfo.image
                          //   : ""
                        }
                        // src={"https://d28cifwpfxyimh.cloudfront.net/cholafiles/posters/nZnbkurpJQ.jpeg"}
                        alt="Movie"
                        width={100}
                        height={100}
                        className={"movie-image"}
                      />
                    </div>

                    <div className="col-lg-8 col-md-7 ticket_detail align-self-center">
                      {/* <label>original</label> */}
                      <h2>{movieInfo.movieName}</h2>
                      <ul>
                        <li>{movieInfo.primaryLanguage}</li>
                        <li>|</li>
                        <li>{moment(ticketInfo.date).format("DD MMM YYYY")}</li>
                        <li>|</li>
                        <li>{ticketInfo.time}</li>
                      </ul>
                      <h3>
                        {moment
                          .utc(
                            moment
                              .duration(movieInfo.runningTime, "minutes")
                              .asMilliseconds()
                          )
                          .format("HH[h] mm[m]")}
                      </h3>
                      
                    </div>
                    <div className="col-lg-2 col-md-2 ticket_detail align-self-center">
                    <h1>
                        <span> ₹ </span> {summaryInfo.ticketRate}
                      </h1>
                    </div>
                  </div>
                  </div>
                  <div className="ticket_content index_content movie-container p-3">
                    {/* <h3 className="content_header">
                      {" "}
                      get your <span> ticket </span>
                    </h3> */}

                    <div className="ticket_summary">
                      <h3>Summary</h3>
                      <label>Apply discount code</label>
                      <div className="input-group flex-nowrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Discount code"
                          aria-label="Discount code"
                          aria-describedby="addon-wrapping"
                          onChange={(e) => this.handleOnChange(e)}
                          value={coupon.couponCode}
                          readOnly={
                            coupon.apply ||
                            coupon.isLoading ||
                            coupon.percentageValue === 100 ||
                            isCompleteOrder
                          }
                        />
                        <div className="input-group-prepend">
                          <button
                            className="btn-apply"
                            onClick={() => this.movieCoupon()}
                            disabled={
                              coupon.isLoading ||
                              coupon.percentageValue === 100 ||
                              isCompleteOrder
                            }
                          >
                            {coupon.apply
                              ? "Cancel"
                              : coupon.isLoading
                              ? "Wait..."
                              : "Apply"}
                          </button>
                        </div>
                      </div>
                      <div className="summary_amount">
                        <ul>
                          <li className="d-flex justify-content-between">
                            <p>Ticket</p>
                            <p>₹ {summaryInfo.ticketRate}</p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Coupon</p>
                            <p>₹ {summaryInfo.discount}</p>
                          </li>
                        </ul>
                        <ul>
                          <li className="d-flex justify-content-between">
                            <p>Sub Total</p>
                            <p>₹ {summaryInfo.subTotal}</p>
                          </li>
                        </ul>
                        <ul>
                          <li className="d-flex justify-content-between">
                            <p>GST (18%)</p>
                            <p>₹ {summaryInfo.taxValue}</p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Total</p>
                            <p>₹ {summaryInfo.grandTotal}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="payment-container p-3">
                    <h3 className="content_header">
                      {" "}
                      Select <span> Payment Method </span>
                    </h3>
                    <div className="payment_button_container">
                      {paymentLevel == "done" ? (
                        <ul className="payment-method">
                          <li className="video-link text-center">
                            <p className="payment-opt-txt">
                              You booking has been confirmed!
                            </p>
                            <img
                              src={common.loadImg("success.jpg")}
                              alt="Success"
                              width={100}
                            />
                          </li>
                        </ul>
                      ) : movieInfo.ticketRate == 0 ||
                        summaryInfo.grandTotal == 0 ? (
                        isCompleteOrder ? (
                          <ul className="payment-method">
                            <li className="video-link text-center">
                              <p className="payment-opt-txt">
                                please waiting for booking confirmation.
                              </p>
                              <img
                                src={common.loadImg("loader.gif")}
                                alt="loader"
                                width={100}
                              />
                            </li>
                          </ul>
                        ) : (
                          <ul className="complete-order">
                            <li onClick={() => this.completeOrder()}>
                              <span className="completed-text">
                                Complete Order
                              </span>
                            </li>
                          </ul>
                        )
                      ) : coupon.percentageValue === 100 ? (
                        <ul className="payment-method">
                          <li className="video-link text-center">
                            <p className="payment-opt-txt">
                              You have applied 100% discount, please waiting for
                              booking confirmation.
                            </p>
                            <img
                              src={common.loadImg("loader.gif")}
                              alt="razorpay"
                              width={100}
                            />
                          </li>
                        </ul>
                      ) : (
                        <div>
                          <ul className="payment-method">
                            {isOrderLoading ? (
                              <li className="razorpay">
                                <div className="razorpay_image_button">
                                  <img
                                    src={common.loadImg("razorpay.png")}
                                    alt="razorpay"
                                    className="razorpay_image"
                                  />
                                </div>
                                <p className="payment-opt-txt  video-link text-center">
                                  Payment in progress please wait...
                                </p>
                              </li>
                            ) : (
                              <li
                                className="razorpay"
                                onClick={() => this.createOrder("Razor Pay")}
                              >
                                <div className="razorpay_image_button video-link">
                                  <img
                                    src={common.loadImg("razorpay.png")}
                                    alt="razorpay"
                                    className="razorpay_image"
                                  />
                                </div>
                                <p className="payment-opt-txt video-link text-center">
                                  Credit Cards, Debit Cards, UPI and Net Banking
                                  etc.
                                </p>
                              </li>
                            )}
                          </ul>
                          <PayPalButton
                            amount={1} // {summaryInfo.subTotal}
                            options={{
                              clientId: payment.paypal.production,
                              "disable-funding": "card",
                              currency: "USD",
                            }}
                            onSuccess={(details, data) => {
                              this.updatePaymentStatus(data.orderID, "Paypal");
                            }}
                            onCancel={(data) => {
                              common.snack("E", "Payment has been cancelled");
                              this.updatePaymentStatus(0, "Paypal", true);
                            }}
                            onError={(error) => {
                              common.snack(
                                "E",
                                "There was an error in the payment please try again"
                              );
                            }}
                            onClick={() => {
                              this.createOrder("Paypal");
                            }}
                            style={{ color: "white", tagline: false }}
                            className={"paypal-my-button"}
                          />
                           <p className="payment-opt-txt video-link text-center">
                             Paypal is Only for International Payments. Not valid for India.
                           </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { Payments };
