import React, { Component } from "react";
/** Import React Slick Slider files */
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/Home.css";

import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import { apiServices } from "../../../api";
import { envConfig } from "../../../api/config";
import moment from "moment";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      streamingList: [],
      upcomingList: [],
      topmoviesList: [],
      popularmoviesList: [],
      shortfilmsList: [],
      collections: [],
      // status
      status: "process",
    };
    common.bodyBg("remove");
  }

  componentDidMount() {
    this.getStreamingMovies();
    this.getUpcomingMovies();
    this.getTopMovies();
    this.getPopularMovies();
    this.getShortFilms();
    this.getCollections();
  }

  /* Get All Streaming Movies from DB. */
  getStreamingMovies = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_STREAMING_NOW_MOVIES",
        query: "?page=0&size=12&sort=streamingStartDate,desc",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_STREAMING_NOW",
        query: "?page=0&size=12&sort=streamingStartDate,desc",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          streamingList: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  /* Get All Top Movies from DB. */
  getTopMovies = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_SEARCH_MOVIES",
        query: "?search=avgRating>3&page=0&size=5",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_SEARCH_MOVIES",
        query: "?search=avgRating>3&page=0&size=5",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          topmoviesList: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  /* Get All Upcoming Movies from DB. */
  getUpcomingMovies = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_UPCOMING_MOVIES",
        query: "?page=0&size=10&sort=streamingStartDate,asc",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_UPCOMING_MOVIES",
        query: "?page=0&size=10&&sort=streamingStartDate,asc",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          upcomingList: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  /* Get All Popular Movies from DB. */
  getPopularMovies = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_SEARCH_MOVIES",
        query: "?search=movieType:SERIES&views-&page=0&size=5",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_SEARCH_MOVIES",
        query: "?search=movieType:SERIES&views-&page=0&size=5",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          popularmoviesList: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  /* Get All Short Films from DB. */
  getShortFilms = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_SEARCH_MOVIES",
        query: "?search=movieType:SHORTS&page=0&size=9",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_SEARCH_MOVIES",
        query: "?search=movieType:SHORTS&page=0&size=9",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          shortfilmsList: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  gotoMovies(e, id, key, name) {
    e.preventDefault();
    if (common.isAuth()) {
      common.redirect("/movies-details/" + id);
    } else {
      var movieName = name.replace(/[^A-Z0-9]+/gi, "-");
      common.redirect("/v/" + movieName.toLowerCase() + "/" + key);
    }
  }

  /* Get All Collections from DB. */
  getCollections = () => {
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_COLLECTIONS",
        query: "?sort=ordering,asc",
        method: "GET",
      };
    } else {
      data = {
        url: "GET_COLLECTIONS",
        query: "?sort=ordering,asc",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        let movies = [];
        response.data &&
          response.data.content &&
          response.data.content.length > 0 &&
          response.data.content.map((item) => {
            if (item.active == "Y") {
              movies.push(item);
            }
          });
        this.setState({
          collections: movies,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  render() {
    const {
      streamingList,
      upcomingList,
      topmoviesList,
      popularmoviesList,
      shortfilmsList,
      collections,
    } = this.state;
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 600,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
    };

    return (
      <div>
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cinebazzar OTT | Digital Theater</title>
          <meta
            name="description"
            content="Watch new movies releasing on Cinebazzar OTT on your favorite devices from anywhere with internet"
          />
        </Helmet>

        <div className="banner-wrap">
          <div className="container banner-slider slider">
            <Slider {...settings}>
              {streamingList &&
                streamingList.length > 0 &&
                streamingList.map((movie, key) => {
                  return (
                    <a
                      href="#"
                      onClick={(e) =>
                        this.gotoMovies(
                          e,
                          movie.movieId,
                          movie.movieKey,
                          movie.movieName
                        )
                      }
                      key={key}
                    >
                      <div className="slider-wrap sliders">
                        <img
                          // src={envConfig.POSTER_URL + movie.movieId + ".jpg"}
                          // alt={movie.movieName}
                          src={common.loadCloudImg(movie.webMoviePoster)}
                          alt={movie.movieName}
                          className="imgPoster"
                        />
                      </div>
                    </a>
                  );
                })}
            </Slider>
          </div>
        </div>

        <div className="top-movies-wrap mb-5">
          {streamingList && streamingList.length > 0 && (
            <div className="container">
              <div className="section-title d-flex justify-content-between align-items-center">
                <h2>STREAMING NOW</h2>
                <a href="/movies" className="btn btn-primary">
                  More
                </a>
              </div>
              <ul className="top-movies-list row custom-row">
                {streamingList.map((searchmovies, key) => {
                  console.log("============searchmovies", searchmovies);
                  return (
                    <li className="movie-col" key={key}>
                      <a
                        href={
                          "/v/" +
                          searchmovies.movieName
                            .replace(/[^A-Z0-9]+/gi, "-")
                            .toLowerCase() +
                          "/" +
                          searchmovies.movieKey
                        }
                      >
                        <div className="slider_style">
                          <div className="streaming_now_button">
                            <h5>Streaming Now</h5>
                          </div>
                          <img
                            src={common.loadCloudImg(searchmovies.moviePoster)}
                            alt="Movie Poster"
                            className="img-fluid"
                          />
                          <div className="slider_book">
                            <h5>{searchmovies.movieName}</h5>
                            <h6>
                              <span>{searchmovies.primaryLanguage}</span>
                              <span>{searchmovies.yearReleased}</span>
                              <span>
                                {moment
                                  .utc(
                                    moment
                                      .duration(
                                        searchmovies.runningTime,
                                        "minutes"
                                      )
                                      .asMilliseconds()
                                  )
                                  .format("HH[h] mm[m]")}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <div className="this-week-release mb-5">
          {upcomingList && upcomingList.length > 0 && (
            <div className="container">
              <div className="section-title d-flex justify-content-between align-items-center">
                <h2>UPCOMING</h2>
                <a href="/movies" className="btn btn-primary">
                  More
                </a>
              </div>

              <ul className="this-week-movies-list row custom-row">
                {upcomingList.map((upcomingmovies, key) => {
                  return (
                    <li className="col-md-2" key={key}>
                      <div className="slider_style">
                        <a
                          href={
                            "/v/" +
                            upcomingmovies.movieName
                              .replace(/[^A-Z0-9]+/gi, "-")
                              .toLowerCase() +
                            "/" +
                            upcomingmovies.movieKey
                          }
                        >
                          <img
                            src={common.loadCloudImg(
                              upcomingmovies.moviePoster
                            )}
                            alt="Movie Poster"
                            className="img-fluid"
                          />
                        </a>
                        <div className="slider_book">
                          <h5>{upcomingmovies.movieName}</h5>
                          {/* <h6><span>{upcomingmovies.primaryLanguage}</span>
                            <span>{upcomingmovies.yearReleased}</span>
                            <span>{moment
                              .utc(
                                moment
                                  .duration(upcomingmovies.runningTime, "minutes")
                                  .asMilliseconds()
                              )
                              .format("HH[h] mm[m]")}
                            </span></h6> */}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <div className="popular-movies-wrap mb-5">
          {popularmoviesList && popularmoviesList.length > 0 && (
            <div className="container">
              <div className="section-title d-flex justify-content-between align-items-center">
                <h2>POPULAR WEB SERIES</h2>
                <a href="/movies" className="btn btn-primary">
                  More
                </a>
              </div>

              <ul className="popular-movies-list row custom-row">
                {popularmoviesList.map((searchmovies, key) => {
                  return (
                    <li className="movie-col" key={key}>
                      <div className="slider_style">
                        <a
                          href={
                            "/v/" +
                            searchmovies.movieName
                              .replace(/[^A-Z0-9]+/gi, "-")
                              .toLowerCase() +
                            "/" +
                            searchmovies.movieKey
                          }
                        >
                          <img
                            src={common.loadCloudImg(searchmovies.thumbnail)}
                            alt="Movie Poster"
                            className="img-fluid"
                          />
                        </a>
                        <div className="slider_book">
                          <h5>{searchmovies.movieName}</h5>
                          {/* <h6><span>{searchmovies.primaryLanguage}</span>
                            <span>{searchmovies.yearReleased}</span>
                            <span>{moment
                              .utc(
                                moment
                                  .duration(searchmovies.runningTime, "minutes")
                                  .asMilliseconds()
                              )
                              .format("HH[h] mm[m]")}
                            </span></h6> */}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <div className="short-film-wrap mb-5">
          {shortfilmsList && shortfilmsList.length > 0 && (
            <div className="container">
              <div className="section-title d-flex justify-content-between align-items-center">
                <h2>NEW SHORT FILMS</h2>
                <a href="/movies/shortfilms" className="btn btn-primary">
                  More
                </a>
              </div>

              <ul className="short-film-list row custom-row">
                {shortfilmsList.map((shortfilm, key) => {
                  return (
                    <li className="movie-col" key={key}>
                      <div className="slider_style">
                        <a
                          href={
                            "/v/" +
                            shortfilm.movieName
                              .replace(/[^A-Z0-9]+/gi, "-")
                              .toLowerCase() +
                            "/" +
                            shortfilm.movieKey
                          }
                        >
                          <img
                            src={common.loadCloudImg(shortfilm.thumbnail)}
                            alt="Movie Poster"
                            className="img-fluid"
                          />
                        </a>
                        <div className="slider_book">
                          <h5>{shortfilm.movieName}</h5>
                          {/* <h6><span>{shortfilm.primaryLanguage}</span>
                            <span>{shortfilm.yearReleased}</span>
                            <span>{moment
                              .utc(
                                moment
                                  .duration(shortfilm.runningTime, "minutes")
                                  .asMilliseconds()
                              )
                              .format("HH[h] mm[m]")}
                            </span></h6> */}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <div className="short-film-wrap mb-5">
          {collections && collections.length > 0 && (
            <div>
              {collections.map((collection, key) => {
                console.log("==============collections", collection);
                return (
                  <div className="container collection-container">
                    {collection.movie && collection.movie.length > 0 && (
                      <div className="section-title d-flex justify-content-between align-items-center">
                        <h2>{collection.name}</h2>
                        <a href="/movies" className="btn btn-primary">
                          More
                        </a>
                      </div>
                    )}
                    <ul
                      className="popular-movies-list row custom-row"
                      key={key}
                    >
                      {collection.movie &&
                        collection.movie.length > 0 &&
                        collection.movie.map((movie, index) => {
                          return (
                            <li className="movie-col" key={index}>
                              <div className="slider_style">
                                <a
                                  href={
                                    "/v/" +
                                    movie.movieName
                                      .replace(/[^A-Z0-9]+/gi, "-")
                                      .toLowerCase() +
                                    "/" +
                                    movie.movieKey
                                  }
                                >
                                  <img
                                    src={common.loadCloudImg(movie.thumbnail)}
                                    alt="Movie Poster"
                                    className="img-fluid"
                                  />
                                </a>
                                <div className="slider_book collect">
                                  <h5>
                                    <span>{movie.movieName}</span>
                                  </h5>
                                  {/* <h6><span>{movie.movie.primaryLanguage}</span>
                                  <span>{movie.movie.yearReleased}</span>
                                  <span>{moment
                                    .utc(
                                      moment
                                        .duration(movie.movie.runningTime, "minutes")
                                        .asMilliseconds()
                                    )
                                    .format("HH[h] mm[m]")}
                                  </span></h6> */}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="submit-movie-wrap">
          <div className="container text-center">
            <h2 className="cb-sub-title">Submit Your Film Today!</h2>
            <p>
              Have a great content, submit to us for a great share of revenue
              with up to the minute transparency in revenue and reach.
            </p>
            <a href="/submit-film" className="btn btn-bordered">
              Submit Now
            </a>
          </div>
        </div>

        <div className="join-today-wrap">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-5 mr-5">
                <h2 className="cb-sub-title">Watch Latest Movies on your TV</h2>
                <p>
                  Watch on Smart TVs, PlayStation, Xbox, Chromecast, Apple TV,
                  Blu-ray players and more.
                </p>
                <a href="#" className="btn btn-bordered">
                  Join Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Home };
