import React, { Component } from "react";
import "../css/Film.css";
import { Button, Form, Col } from "react-bootstrap";
import moment from "moment";
import { apiServices } from "../../../api";
import { common, tools, payment } from "../../../_helpers";
import { PayPalButton } from "react-paypal-button-v2";

class Step3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
              signature: {
                error: '',
                isReq: 'Signature is required',
              },
              place: {
                error: '',
                isReq: 'Place is required',
              },
              payAmount: {
                error: '',
                isReq: 'Pay Amount is required',
              },
            },
            fields: {
              declarationName: {
                criteria: {required: true},
                value: '',
                label: 'Name',
              },
              signature: {
                criteria: {required: true},
                value: '',
                label: 'Signature',
              },
              place: {
                criteria: {required: true},
                value: '',
                label: 'Place',
              },
              date: {
                criteria: {required: true},
                value: new Date(),
                label: 'Date',
              },
              payAmount: {
                criteria: {required: true},
                value: '',
                label: 'Pay',
              },
            },
            isLoading: false,
            isFormSubmit: false,
            agreed: false,
            keyboardHeight: 0,
            location: {},
            // birthDate: new Date(),
            currentPosition: 0,
            isDeclare: false,
            isDeclareError: "Please select the declare condition",
            paymentMethod: "",
            paymentMethodError: "Please select the payment method",
            stepOneDetails: {},
            stepTwoDetails: {},
            contactPaymentPaid: 0,
            contactPaymentStatus: "",
            contactPaymentTransno: ""
    }
}

componentDidMount = () => {
  const{ fields } = this.state;
  let stepOne = localStorage.getItem("stepOneDetails");  
    if (stepOne) {
      let stepOneDetails = JSON.parse(stepOne);
      this.setState({
        stepOneDetails
      })
    }

    let stepTwo = localStorage.getItem("stepTwoDetails");            
    if (stepTwo) {
        let stepTwoDetails = JSON.parse(stepTwo);
        fields["declarationName"].value = stepTwoDetails.contactName;
        this.setState({
          stepTwoDetails,
          fields
        })
    }

    let stepThree = localStorage.getItem("stepThreeDetails");
      if (stepThree) {
          let stepThreeDetails = JSON.parse(stepThree);
          fields.signature.value = stepThreeDetails.contactSignature;
          fields.place.value = stepThreeDetails.contactPlace;
          fields.date.value = stepThreeDetails.contactSignDate;
          fields.payAmount.value = stepThreeDetails.contactPayment;

          this.setState({
            paymentMethod: stepThreeDetails.contactPaymentMethod,
            contactPaymentPaid: stepThreeDetails.contactPaymentPaid,
            contactPaymentStatus: stepThreeDetails.contactPaymentStatus,
            contactPaymentTransno: stepThreeDetails.contactPaymentTransno,
            isDeclare: stepThreeDetails.agreed,
            fields
          },
          _=>{
            if(this.state.isDeclare == true ){
              this.setState({
                isDeclareError: ""
              })
            }
          });
    }
}

goToBack = () => {
  const { isDeclare, fields, paymentMethod, contactPaymentPaid, contactPaymentStatus, contactPaymentTransno } = this.state;
    let stepThreeDetails = {
      contactSignature: fields.signature.value,
      contactPlace: fields.place.value,
      contactSignDate: fields.date.value,
      contactPayment: fields.payAmount.value,
      contactPaymentMethod: paymentMethod,
      contactPaymentPaid: contactPaymentPaid,
      contactPaymentStatus: contactPaymentStatus,
      contactPaymentTransno: contactPaymentTransno,
      agreed: isDeclare
    }
  localStorage.setItem("stepThreeDetails", JSON.stringify(stepThreeDetails))
  this.props.goBack();
};

onChange = async (event) => {
  const { name, value } = event.target;
  const { fields } = this.state;
  fields[name].value = value;
  this.setState(
    {
      fields: fields,
    },
    () => {
      this.validateField(name, value);
    }
  );
};

 // validation
 validateField = (name, value) => {
  const { errors, fields } = this.state;
  if (errors.hasOwnProperty(name)) {
    if (!value) {
      errors[name].error = errors[name].isReq;
    } else {
      errors[name].error = "";
    }
  }

  this.setState({
    errors: errors,
  });
};

validateForm() {
  const { fields, errors, contactPaymentStatus, isDeclare } = this.state;
  let isValid = true;
  for (var key of Object.keys(errors)) {
    if (fields[key].value == "" || fields[key].value == null) {
      errors[key].error = errors[key].isReq;
      isValid = false;
    }
  }
  if(fields.payAmount.value > 0){
    if(contactPaymentStatus == "PAID"){
      this.setState({
        paymentMethodError: ""
      })
    } else { 
      isValid = false;
      this.setState({
        paymentMethodError: "Please select the paymentMethod"
      })
    }
  } else {
    this.setState({
      paymentMethodError: ""
    })
  }

  if(isDeclare == false){
    isValid = false;
      this.setState({
        isDeclareError: "Please select the declare condition"
      })
  } else{
    this.setState({
      isDeclareError: ""
    })
  }

  return isValid
}

setDate = (event) => {
  let { value } = event.target;
  const { fields } = this.state;
  fields["date"].value = value;
  this.setState(
    {
      fields: fields,
    },
    () => {
      // this.validateField("date", date);
    }
  );
}

onSubmitHandler = () => {
  const {fields, paymentMethodError, contactPaymentStatus } = this.state;
  this.setState({ formSubmit: true });
  let isValid = this.validateForm();

  if (isValid) {
      this.createFilm();
      // localStorage.setItem("stepTwoDetails", JSON.stringify(stepTwoDetails))
      // this.props.goNext();
  }
};

selectDeclare = () => {
  this.setState({
    isDeclare: !this.state.isDeclare
  },
  _=> {
      this.setState({
        isDeclareError:this.state.isDeclare == true ? "" : "Please select the declare condition"
      })  
  })
}

createFilm = async() => {
  const { fields, paymentMethod, stepOneDetails, stepTwoDetails, contactPaymentPaid, contactPaymentStatus, contactPaymentTransno} = this.state;
 
      this.setState({
          isLoading: true,
      });
      let params = {
        movieName: stepOneDetails.movieName,
        movieNameEn: stepOneDetails.movieNameEn,
        countryOfProduction: stepOneDetails.countryOfProduction,
        runningTime: stepOneDetails.runningTime,
        yearProduced: stepOneDetails.yearProduced,
        yearReleased: stepOneDetails.yearReleased,
        primaryLanguage: stepOneDetails.primaryLanguage,
        website: stepOneDetails.website,
        movieDescription: stepOneDetails.movieDescription,
        contactName: stepTwoDetails.contactName,
        contactEmail: stepTwoDetails.contactEmail,
        contactPhone: stepTwoDetails.contactPhone,
        contactSignature: fields.signature.value,
        contactPlace: fields.place.value,
        contactSignDate: moment(fields.date.value).format("DD-MM-YYYY HH:mm:ss"),
        contactPayment: fields.payAmount.value,
        contactPaymentMethod: paymentMethod,
        contactPaymentPaid: contactPaymentPaid,
        contactPaymentStatus: contactPaymentStatus,
        contactPaymentTransno: contactPaymentTransno,
        active: "N",
        image: "demo image",
        releaseDate: moment().format("DD-MM-YYYY HH:mm:ss"),
        ticketRate: 0,
        moviePoster: "demo poster",
        ageRating: 1
      }

      var data = {
        url: "CREATE_MOVIES",
        body: params,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if(response.status == 201){
          common.snack("S", "Movie Create Succesfully.");
          this.movieGenresUpdate(response.data.movieId);
          } else {
            common.snack("S", response.data.error);
          }
        },
        (error) => {
          common.snack("E", "Movie Create Faild.");
        },
        (final) => {
        }
      );
 
}

movieGenresUpdate = async(movieId) => {
  const { stepOneDetails } = this.state;
  
    let total = 0;
    stepOneDetails.genre.length > 0 && stepOneDetails.genre.map(async(item) => {
      total += 1;
      let param = {
        genreId: item.id,
        active: item.isTrue == true ? "Y" : "N",
        ordering: item.ordering
      }
      var data = {
        url: "ADD_MOVIE_GENRE",
        query: "/" + movieId + "/moviegenres",
        body: param,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if(response.status == 201){
            // if(total == stepOneDetails.genre.length) {
              localStorage.removeItem("stepOneDetails");
              localStorage.removeItem("stepTwoDetails");
              localStorage.removeItem("stepThreeDetails");
              common.redirect("/");
            // }
          }
        },
        (error) => {
          common.snack("E", "An Error Occurred");
        },
        (final) => {
        }
      );      
    }) 
}

selectPaymentMethod = (method) => {
  this.setState({
    paymentMethod: method
  },
  _=> {
      this.setState({
        paymentMethodError: this.state.paymentMethod !== "" ? "" : "Please select the payment method"
      })
      if (method === "Razorpay") {
        this.initializeRazorpay();
      }
  })
}

initializeRazorpay() {
  const { fields } = this.state;
  const options = {
    key: payment.razorPay.production.key,
    name: "Cinebazzar OTT",
    description: "Cinebazzar OTT",
    amount: fields.payAmount.value * 100,
    currency: "INR",
    // receipt: orderId.toString(),
    // notes: {
    //   merchant_order_id: orderId,
    // },
    theme: {
      color: "#f4c21f",
    },
    handler: async (response) => {
          if (response && response.razorpay_payment_id) {
            common.snack("S", `Payment has been Success: ${response.razorpay_payment_id}`);
            this.setState(
              {
                contactPaymentPaid: fields.payAmount.value,
                contactPaymentStatus: "PAID",
                contactPaymentTransno: response.razorpay_payment_id
              });
          } else {
            common.snack("E", "Payment has been failed!");
          }
    },
    modal: {
      ondismiss: async () => {
        // this.updatePaymentStatus(0, "Razor Pay", true);
        this.setState({
          contactPaymentPaid: 0,
          contactPaymentStatus: "CANCELLED",
          contactPaymentTransno: 0
        });
        common.snack("E", "Payment has been cancelled");
      },
    },
  };
  const rzp1 = new window.Razorpay(options);
  rzp1.open();
}

    render() {
        const { errors, fields, formSubmit, isDeclareError, isDeclare, paymentMethodError } = this.state;
        return (
            <div className="step1">
                <h4 className="film-heading">DECLARATIONS</h4>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label=" I declare that I have the authority or have been authorized
                    by the production company/rights holder to submit a copy of
                    this film to Fillum (StarARama, LLC) for review."
                    onClick={() => this.selectDeclare()}
                    checked={isDeclare}
                    />
                    {isDeclareError !== "" && formSubmit && (
                          <span className="rt-submit-err">
                            {isDeclareError}
                          </span>
                        )}
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.declarationName.label}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"declarationName"}
                            placeholder={fields.declarationName.label}
                            value={fields.declarationName.value}
                            onChange={(e) => this.onChange(e)}
                            disabled
                        />
                        {/* {errors.declarationName.error !== "" && formSubmit && (
                          <span className="rt-submit-err">
                            {errors.declarationName.error}
                          </span>
                        )} */}
                    </Form.Group>
                    </Form.Row>

                    <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.signature.label}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"signature"}
                            placeholder={fields.signature.label}
                            value={fields.signature.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.signature.error !== "" && formSubmit && (
                          <span className="rt-submit-err">
                            {errors.signature.error}
                          </span>
                        )}
                    </Form.Group>
                </Form.Row>
               
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.place.label}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"place"}
                            placeholder={fields.place.label}
                            value={fields.place.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.place.error !== "" && formSubmit && (
                          <span className="rt-submit-err">
                            {errors.place.error}
                          </span>
                        )}
                    </Form.Group>
                </Form.Row>
            
                <Form.Row>
              
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.date.label}
                            </Form.Label>
                            <Form.Control
                            type="date"
                            name={"date"}
                            placeholder={fields.date.label}
                            value={fields.date.value}
                            onChange={(e) => this.setDate(e)}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.payAmount.label}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"payAmount"}
                            placeholder={fields.payAmount.label}
                            value={fields.payAmount.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.payAmount.error !== "" && formSubmit && (
                          <span className="rt-submit-err">
                            {errors.payAmount.error}
                          </span>
                        )}
                    </Form.Group>
                </Form.Row>
                {fields.payAmount.value > 0 &&
                  <Form.Row>
                    <li
                      className="razorpay"
                      onClick={() => this.selectPaymentMethod("Razorpay")}
                    >
                      <div className="razorpay_button">
                        <img
                          src={common.loadImg("razorpay.png")}
                          alt="razorpay"
                          className="razorpay_image"
                        />
                      </div>
                      <p className="payment-opt-txt video-link text-center">
                        Credit Cards, Debit Cards, UPI and Net
                        Banking etc.
                                        </p>
                    </li>
                  
                    <PayPalButton
                              amount={fields.payAmount.value} // {summaryInfo.subTotal}
                              options={{
                                clientId: payment.paypal.production,
                                "disable-funding": "card",
                                currency: "USD",
                              }}
                              onSuccess={(details, data) => {
                                if (data && data.orderID) {
                                  common.snack("S", `Payment has been Success: ${data.orderID}`);
                                  this.setState(
                                    {
                                      contactPaymentPaid: fields.payAmount.value,
                                      contactPaymentStatus: "PAID",
                                      contactPaymentTransno: data.orderID
                                    });
                                } 
                              }}
                              onCancel={(data) => {
                                common.snack("E", "Payment has been cancelled");
                              }}
                              onError={(error) => {
                                common.snack(
                                  "E",
                                  "There was an error in the payment please try again"
                                );
                              }}
                              onClick={() => {
                                this.selectPaymentMethod("Paypal");
                              }}
                              style={{ color: "white", tagline: false }}
                              className={"paypal-my-button"}
                            />
                  
                  </Form.Row>
                }
                  {fields.payAmount.value > 0 && paymentMethodError !== "" && formSubmit && (
                    <span className="rt-submit-err">
                      {paymentMethodError}
                    </span>
                  )}

                    <div>
                        <Button
                            className="back-btn"
                            onClick={() =>
                              this.goToBack()
                            }
                        >
                           {"<"} Back
                        </Button>
                        <Button
                            className="continue-btn"
                            onClick={() =>
                              this.onSubmitHandler()
                            }
                        >
                            Submit
                        </Button>
                    </div>
            </div>
        )
    }
}

export { Step3 }