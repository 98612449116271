// Dev
// let AuthApiURL = "http://ott11.cholalabs.com:8080/authentication/api/v1/";
// let OttApiUrl = "http://ott11.cholalabs.com:8080/cholaottservice/api/v1/";

// Production
let AuthApiURL = "https://cholaott.cinebazzar.com/authentication/api/v1/";
let OttApiUrl = "https://cholaott.cinebazzar.com/cholaottservice/api/v1/";
let ImageUrl = "https://d13heh6lmag6t5.cloudfront.net"; //"https://d28cifwpfxyimh.cloudfront.net"
let PosterUrl = "https://d13heh6lmag6t5.cloudfront.net/web/streamingnow/";
let ImageUrl1 = "https://d13heh6lmag6t5.cloudfront.net";

const RestTest = "https://jsonplaceholder.typicode.com"; // Please omit this
let CientId = "690741220393-m5m6hldgnlhrs240nig3caeu0lv743tq.apps.googleusercontent.com" //"425344424424-v0ri1sfo96bcca43jk9c1j93gidhpjil.apps.googleusercontent.com";
let CientSecretId = "sR0gkoI_YU_Jotfluf6Iedxa";

export const envConfig = {
  AUTH_API: `${AuthApiURL}`,
  OTT_API: `${OttApiUrl}`,
  IMAGE_URL: `${ImageUrl}`,
  POSTER_URL: `${PosterUrl}`,
  REST_TEST: `${RestTest}`,
  GOOGLE_CLIENT_ID: `${CientId}`,
  IMAGE_URL1: `${ImageUrl1}`,
};
