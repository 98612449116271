import React from "react";
import "./TheoPlayer.css";

class Player extends React.Component {
  _player = null;
  _el = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      watermark: {
        top: 20,
        left: 20,
      },
      user: {},
    };
    this.playerRef = React.createRef();
  }

  componentDidMount() {
    // props like the source, or the sourcedesciption as a whole can be passed through
    // the can then be used to be called on the player itself
    const { source, onPlay } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      user,
    });
    if (this._el.current) {
      this._player = new window.THEOplayer.Player(this._el.current, {
        libraryLocation: "https://cdn.myth.theoplayer.com/2d95de98-86f8-4598-9df4-e1811fd15823",
        license: "sZP7IYe6T6P1ISXe0KX636zoTQxgFSaL0u5-CKBkCZz_0LfLIlXeTQxeIle6FOPlUY3zWokgbgjNIOf9fKhL3ofk3QXlFDXe0lh-3Q0o0Ok10KBkFSat3l4lISRi3Dak36fVfK4_bQgZCYxNWoryIQXzImf90SCL0LCi0uRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lf_0LBt3L0oTS0LFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz",
        // Picture-in-picture initialisation
        pip: {
          visibility: 0.7,
        },
      });

      this._player.autoplay = true;
      this._player.preload = "auto";
      this._player.source = source;
      
      
      //  this._player.network.addRequestInterceptor(request => {
      //   // The interceptor intercepts ALL requests and we only want to redirect one specific url
      //   if (request.type === 'manifest') {
      //     request.redirect({
      //         url : request.url,
      //         method : 'GET',
      //         headers : {
      //             'Content-Type' : 'application/x-mpegurl',
      //             'dt-sar' : 'GBNV89MGDFRSY'
      //         }
      //     });
      // }
      // });
      
      
      
      let previousPlayheadPosition = this.getCookie(
        "playheadPosition" + source.playId
      );
      if (previousPlayheadPosition) {
        this._player.currentTime = previousPlayheadPosition;
      }
      this._player.addEventListener("timeupdate", () => {
        this.setCookie(
          "playheadPosition" + this._player.source.playId,
          this._player.currentTime
        );
      });
      this._player.addEventListener("play", onPlay);
      if (this._player.source.isMovie == true) {
        this.interval = setInterval(() => this.setWaterMark(), 5000);
      }
    }
  }

  // update a cookie
  setCookie = (cname, cvalue) => {
    let d = new Date();
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); // store 7 days
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  // fetch a cookie
  getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  componentWillUnmount() {
    if (this._player) {
      this._player.destroy();
    }
    clearInterval(this.interval);
  }

  setWaterMark = () => {
    setTimeout(() => {
      let container = this._el;
      let contWidth = container.current.offsetWidth;
      let contHeight = container.current.offsetHeight;
      const watermarkTop = Math.floor(Math.random() * contHeight) + 1;
      const watermarkLeft = Math.floor(Math.random() * contHeight) + 1;
      this.setState({
        watermark: {
          top: watermarkTop,
          left: watermarkLeft,
        },
      });
    }, 50);
  };

  render() {
    const { watermark, user } = this.state;
    const { source } = this.props;
    return (
      <div
        // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
        className={
          "theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
        }
        // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
        // Which is need by the player
        ref={this._el}
      >
        {source.isMovie == true && (
          <p
            className="watermak"
            style={{ top: watermark.top, left: watermark.left }}
          >
            {source.userId}
          </p>
        )}
      </div>
    );
  }
}


export default Player;