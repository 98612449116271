import React, { Component, component } from "react";
import { Footer } from "./footer";
class Signup extends Component {
  render() {
    return (
      <div>
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export { Signup };
