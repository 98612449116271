import React from "react";
import { Container, Row, Col, Media, Button } from "react-bootstrap";
import { common } from "../../../_helpers";
import { StatusBar } from "../../../_includes";
import { Sidebar } from "../../../_layouts/sidebar";
import { MyProfile } from "../index";
import { Header } from "../../../_layouts/header";
import { ProfileHeader } from "../../../_layouts/profileHeader";
import { Helmet } from "react-helmet";
class Notifications extends React.Component {
  constructor(props) {
    super(props);
  }

  componentChange = () => {};
  render() {
    return (
      <div>
        <Header />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="profileContent_detail">
                  <StatusBar page="home" status={"comingSoon"} />
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { Notifications };
