import React from "react";
import { Container, Row, Col, Media, Button } from "react-bootstrap";
import { common } from "../../../_helpers";
import { StatusBar } from "../../../_includes";
import { Sidebar } from "../../../_layouts/sidebar";
import { MyProfile } from "../index";
import { Header } from "../../../_layouts/header";
import { ProfileHeader } from "../../../_layouts/profileHeader";
class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header />
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="profileContent_detail">
                  <StatusBar page="home" status={"comingSoon"} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export { ProfileSettings };
