import React, { Component } from "react";
// _includes
import { common, tools } from "../../../_helpers";
import { Header } from "../../../_layouts/header";
import { apiServices } from "../../../api";
// css
import "../css/Signup.css";
import OtpInput from "react-otp-input";
import { Helmet } from "react-helmet";

class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: "null",
      screen: "",
      errors: {
        otp: {
          error: "",
          isReq: "OTP is required!",
          minReq: "OTP Should be 6 Numbers",
        },
      },
      fields: {
        otp: "",
      },
      isLoading: false,
      formSubmit: false,
      isButtonVisible: false
    };
  }

  componentDidMount() {
    const mobileNumber = localStorage.getItem("mobileNumber");
    const email = localStorage.getItem("email");
    const screen = localStorage.getItem("screen");
    let userDetails = this.props.match.params.userdetail;
    if (
      this.props.match.params &&
      this.props.match.params.userdetail == "email"
    ) {
      this.setState({
        mobileNumber: email,
        screen: screen,
      });
    } else {
      this.setState({
        mobileNumber: mobileNumber,
        screen: screen,
      });
    }
    setTimeout(() => {
      this.setState({ isButtonVisible: true });
    }, 120000);
  }

  /**
   * Resend OTP
   */
  resendOTP = async (e) => {
    e.preventDefault()
    const { fields, mobileNumber } = this.state;
    this.loader(true);
    this.setState({
      isButtonVisible: false
    })

    let param = {}
    let data = {}

    if (isNaN(mobileNumber)) {
      param = {
        emailId: mobileNumber,
      };
      data = {
        url: "FORGOTPASSWORD",
        body: param,
        authorization: "none",
        method: "POST",
      };
    } else {
      param = {
        mobileno: mobileNumber,
      };
      data = {
        url: "FORGOT_PASSWORD_BY_PHONE",
        body: param,
        authorization: "none",
        method: "POST",
      };
      
    }

    apiServices.call(
      data,
      (response) => {
        if (response.error) {
          common.snack("E", "Something went wrong please try again");
          this.setState({
            isButtonVisible: false
          })
        } else {
          common.snack("S", "OTP re-send Succesful.");
          setTimeout(() => {
            this.setState({ isButtonVisible: true });
          }, 120000);
        }
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          isButtonVisible: false
        })
      },
      (final) => {
        this.loader(false);
      }
    );
  };

  /**
   * Mobile login details
   */
  otpVerification = async (e) => {
    e.preventDefault();
    const { fields, mobileNumber } = this.state;
    this.loader(true);
    let param = {
      username: mobileNumber,
      otp: parseInt(fields.otp),
    };

    var data = {
      url: "OTP_VERIFICATION",
      body: param,
      authorization: "none",
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.data.status == "error") {
          common.snack("E", "Please Enter Valid OTP Number");
          this.loader(false)
        } else {
          common.snack("S", "OTP Verification Successfully");
          this.userLogin(e);
          this.loader(false)
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {
        // this.loader(false);
      }
    );
  };

  userLogin = async (e) => {
    e.preventDefault();
    const { mobileNumber, fields, screen } = this.state;
    this.loader(true);
    let param = {
      username: mobileNumber,
      password: fields.otp,
    };

    var data = {
      url: "SIGNIN",
      body: param,
      authorization: "none",
      method: "POST",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.data.error) {
          common.snack("E", "Use valid credentials to login");
        } else {
          common.snack("S", "Login Successful");
          localStorage.setItem(
            "mobileNumber",
            JSON.stringify(fields.mobileNumber)
          );
          localStorage.setItem("screen", "login");
          localStorage.setItem("userToken", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshtoken);
          localStorage.setItem("user", JSON.stringify(response));
          localStorage.setItem("userId", response.data.userId);
          if (screen == "login") {
            common.redirect("/");
          } else {
            common.redirect("/userinfo");
          }
          fields["otp"] = "";
          this.setState({
            fields,
          });
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {
        this.loader(false);
      }
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ formSubmit: true });
    let isValid = this.validateForm();
    if (isValid) {
      this.otpVerification(e);
    }
  };

  handleOnChange = (name, value) => {
    const { fields } = this.state;
    // const { name, value } = event.target;
    var inputValue = value;

    if (name == "otp") {
      inputValue = tools.validateMobile(inputValue);
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateField(name, inputValue);
      }
    );
  };

  // validation
  validateField = (name, value) => {
    const { errors } = this.state;
    if (errors.hasOwnProperty(name)) {
      if (!value) {
        errors[name].error = errors[name].isReq;
      } else {
        errors[name].error = "";
      }
    }

    if (name == "otp" && value != "") {
      if (value.length < 6) {
        errors[name].error = errors[name].minReq;
      }
    }

    this.setState({
      errors: errors,
    });
  };

  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    // unique validation
    let otp = fields["otp"];
    if (otp != "") {
      if (otp.length < 6) {
        errors["otp"].error = errors["otp"].minReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });
    return isValid;
  }

  loader(status) {
    this.setState({
      isLoading: status,
    });
  }

  render() {
    const { isLoading, errors, formSubmit, fields, isButtonVisible } = this.state;
    return (
      <div>
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>OTP Verification</title>
          <meta
            name="description"
            content="Watch new movies releasing on Cinebazzar OTT | Digital Theater on your favorite devices from anywhere with internet"
          />
        </Helmet>
        <section className="signup-wrap">
          <div className="container">
            <div className="signup-wrap-inner text-center">
              <h2>
                Watch unlimited movies online Join{" "}
                <span className="color-primary">Cinebazzar OTT | Digital Theater</span>
              </h2>
              <div className="content-box">
                <form className="signup-form">
                  <h4 className="title" style={{ color: "black" }}>
                    Enter 6 Digit OTP Verification Code We've Sent On Given
                    Number
                  </h4>

                  <div className="input-group otp-input hello">
                    <OtpInput
                      value={fields.otp}
                      name={"otp"}
                      onChange={(val) => this.handleOnChange("otp", val)}
                      numInputs={6}
                      separator={<span>-</span>}
                      inputStyle={{
                        width: "50px",
                        height: "50px",
                        margin: "5px 1px",
                        fontSize: "18px",
                        borderRadius: 4,
                        border: "2px solid rgba(0,0,0,0.5)",
                      }}
                    />
                    {/* <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      placeholder="OTP"
                      // required
                      name={"otp"}
                      onChange={(e) => this.handleOnChange(e)}
                      value={fields.otp}
                    /> */}
                  </div>
                  {errors.otp.error != "" && formSubmit && (
                    <span className="errorMsg">{errors.otp.error}</span>
                  )}
                </form>
                {isButtonVisible &&
                  <button
                    variant="primary"
                    className="btn btn-secondary"
                    onClick={(e) => this.resendOTP(e)}
                    disabled={isLoading}
                  >
                    {!isLoading ? "RESEND" : "Processing..."}
                  </button>
                }
                <button
                  variant="primary"
                  className="btn btn-secondary"
                  onClick={(e) => this.onSubmit(e)}
                  disabled={isLoading}
                >
                  {!isLoading ? "Join Now" : "Processing..."}
                </button>

                {/* <ul className="signup-via-social-links list-inline mb-0">
                  <li className="nav-item">
                    <a
                      className="btn btn-secondary"
                      style={{ paddingTop: 13 }}
                      href="#"
                    >
                      Join Now
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </section>

        {/* <section className="entertainment-wrap">
          <div className="container">
            <div className="entertainment-wrap-inner">
              <div className="section-title text-center">
                <h2 className="title">
                  Entertainment at your comfort, anytime, anywhere across
                  languages across devices
                </h2>
              </div>
              <img
                className="img-fluid"
                src={common.loadImg("videoimg.png")}
                alt="Entertainment Languages"
              />
            </div>
          </div>
        </section> */}

        <section className="banner-wrap">
          <div className="container">
            <div className="banner-text text-center">
              <h2>
                Stream unlimited movies on your <span>phone,</span>{" "}
                <span>tablet,</span> <span>laptop</span> and <span>TV</span>.
              </h2>
            </div>
          </div>
        </section>
        {/* <section className="features-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("anytime_anywhere.png")}
                    alt="Anytime Anywhere Watch Movies"
                  />
                  <h4>
                    Anytime, Anywhere <br />
                    Watch <span>Movies</span>
                  </h4>
                  <p>
                  From all devices you have, from anywhere in the world just access this app and watch movie
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("submityourown.png")}
                    alt="Submit your Own Movie"
                  />
                  <h4>
                    <span>Submit</span> your <br />
                    Own Movie
                  </h4>
                  <p>
                   For film makers all over the world, if you think your content is worth to watch, you can stream with our platform.We can buy or rent your movies.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("languagesorting.png")}
                    alt="Language and Genre Sorting"
                  />
                  <h4>
                    Language <br />
                    and Genre <span>Sorting</span>
                  </h4>
                  <p>
                  Very easy to access, use filters to search languages , genre, actors and directors.Time is valuable.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("choosevideoquality.png")}
                    alt="Choose Video Quality"
                  />
                  <h4>
                    Choose Video <br />
                    <span>Quality</span>
                  </h4>
                  <p>
                  You can choose from  high quality like 4K to low quality.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("dainapictureoriginals.png")}
                    alt="Cinebazzar OTT Originals"
                  />
                  <h4>
                    Cinebazzar OTT <br />
                    <span>Originals</span>
                  </h4>
                  <p>
                  We have a bunch of young talented directors, technicians and actors to provide excellent films for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export { Otp };
