import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { common } from "../../../_helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      username: "",
      email: "",
      mobile: "",
      gender: "",
    };
  }

  componentDidMount = () => {
    common.getUserDetails();
    let userData = JSON.parse(localStorage.getItem("user"));
    this.setState({
      username: userData.data && userData.data.firstName ? userData.data.firstName : "",
      email: userData.data && userData.data.email ? userData.data.email : userData.data && userData.data.emailId ? userData.data.emailId : "",
      mobile: userData.data && userData.data.mobile ? userData.data.mobile : "",
      gender: userData.data && userData.data.gender
        ? userData.data.gender == "0"
          ? "Male"
          : "Female"
        : "-",
    })
  }
  render() {
    return (
      <div>
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <div className="myProfile_header">
          <Row>
            <Col md={9}>
              <h3>My Profile</h3>
            </Col>
            <Col md={3}>
              <p>
                <a href="/editprofile">edit Profile</a>
              </p>
            </Col>
          </Row>
        </div>{" "}
        {/* myProfile_header end here */}
        <div className="myProfile_detail">
          {/* <div className="profile_upload">
            <img src={common.loadImg("profile.png")} alt="profile"
              className="profile_pic" />
            <div className="profile_upload--icon">
              <img src={common.loadImg("upload.png")} alt="profile"
                className="upload_icon" />
                <Form.Control type="file"/>
            </div> 
          </div> */}
          <div className="myprofile_content">
            <Row>
              <Col md={12} className="mb-20">
                <h5>Name</h5>
                <h3>{this.state.username ? this.state.username : "-"}</h3>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-20">
                <h5>Email</h5>
                <h3>{this.state.email ? this.state.email : "-"}</h3>
              </Col>
            </Row>
            <Row>
              {/* <Col md={6} className="mb-20">
              <h5>Name</h5>
              <h3>{this.state.username ? this.state.username : "-"}</h3>
            </Col>
            <Col md={6} className="mb-20">
              <h5>Email</h5>
              <h3>{this.state.email ? this.state.email : "-"}</h3>
            </Col> */}
              {/* <Col md={6} className="mb-20">
              <h5>Edit Profile</h5>
              <h3>johndoe@gmail.com</h3>
            </Col> */}
              <Col md={6} className="mb-20">
                <h5>Mobile Number</h5>
                <h3>{this.state.mobile ? this.state.mobile : "-"}</h3>
              </Col>
              <Col md={6} className="mb-20">
                <h5>Gender</h5>
                <h3>{this.state.gender ? this.state.gender : "-"}</h3>
              </Col>
            </Row>
          </div>
        </div>{" "}
        {/* myProfile_detail End here */}
      </div>
    );
  }
}
export { MyProfile };
