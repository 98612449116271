import React, { Component } from "react";
import "../css/Film.css";
import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import {Step1} from "./step1";
import {Step2} from "./step2";
import {Step3} from "./step3";

class SubmitFilm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step1: true,
      step2: false,
      step3: false,
    };
  }

  goNextStep = (fieldName, val) => {
    this.setState({
      [fieldName]: val,
    });
  };

  render() {
    const {step1, step2, step3} = this.state;
    return (
      <div>
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Submit Your Film</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Submit Your Film</li>
            </ul>
          </div>

            <div className="col-md-12 movie-container">
                <div className="row step-header">
                    <div className="col-md-4">
                        <p>Step1</p>
                        <div className="step-active-bar"></div>
                    </div>
                    <div className="col-md-4">
                        <p>Step2</p>
                        {step2 ?
                          <div className="step-active-bar"></div>
                          :
                          <div className="step-bar"></div>
                        }
                        
                    </div>
                    <div className="col-md-4">
                        <p>Step3</p>
                        {step3 ?
                          <div className="step-active-bar"></div>
                          :
                          <div className="step-bar"></div>
                        }
                    </div>
                </div>

                <div className="row">
                  {step1 && !step2 && !step3 && (
                    <Step1 goNext={() => this.goNextStep('step2', true)} />
                  )}
                  {step1 && step2 && !step3 && (
                    <Step2
                      goNext={() => this.goNextStep('step3', true)}
                      goBack={() => this.goNextStep('step2', false)}
                    />
                  )}
                  {step1 && step2 && step3 && (
                    <Step3 goBack={() => this.goNextStep('step3', false)} navigation={this.props.navigation} />
                  )}
                </div>
            </div>

        </div>
      </div>
    );
  }
}

export { SubmitFilm };
