import React from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { Header } from "../../../_layouts/header";
import { common, message } from "../../../_helpers";
import { apiServices } from "../../../api";
import { StatusBar } from "../../../_includes";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Helmet } from "react-helmet";
class ProducerOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movieId: this.props.match.params.movie_id,
      channelId: this.props.match.params.channel_id,
      movieName: "Movie",
      orderList: [],
      status: "process",
      paging: {
        page: 1,
        size: 30,
        total: 1,
      },
      csv: {
        file: "movie_orders",
        export: false,
        set: false,
        headers: [
          { label: "Order Date", key: "orderDate" },
          { label: "Order Source", key: "orderSource" },
          { label: "Order Country", key: "orderCountry" },
          { label: "Trasaction Currency", key: "transactionCurrencyCode" },
          { label: "Price", key: "price" },
          { label: "Tax", key: "tax" },
          { label: "Discount", key: "discount" },
          { label: "Total", key: "total" },
          { label: "Payment Status", key: "paymentStatus" },
        ],
        data: [],
      },
    };
  }

  componentDidMount() {
    const { movieId } = this.state;
    common.checkRole("Producer", true);
    this.getMyOrders("init");
    this.getMovieDetails(movieId);
  }

  getMovieDetails = (id) => {
    if (id) {
      let data = {
        url: "GET_MOVIE_INFO",
        query: id,
        method: "GET",
      };

      apiServices.call(
        data,
        (response) => {
          this.setState({
            movieName: response.data.movieName,
          });
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  getMyOrders(type) {
    const { movieId, orderList, paging } = this.state;
    let data = {
      url: "GET_MOVIE_ORDER",
      query:
        "/" +
        movieId +
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=orderDate,desc",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            orderList.push(item);
          });
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          orderList,
          paging,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {
        if (type == "load") {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        status: "process",
        paging,
      },
      () => {
        this.getMyOrders("load");
      }
    );
  }

  refreshData() {
    this.setState(
      {
        orderList: [],
        status: "process",
        paging: {
          page: 1,
          size: 30,
          total: 1,
        },
      },
      () => {
        this.getMyOrders("init");
      }
    );
  }

  // Export
  exportCSV() {
    const { movieId, movieName, csv } = this.state;
    csv.export = true;
    this.setState({ csv });
    let data = {
      url: "GET_MOVIE_ORDER",
      query: "/" + movieId + "?page=0&size=100000&sort=orderDate,desc",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        let csvData = response.data.content.map((item) => {
          return {
            orderDate: moment
              .utc(item.orderDate, "DD-MM-YYYY HH:mm:ss")
              .local()
              .format("DD-MM-YYYY"),
            orderSource: item.orderSource,
            orderCountry: item.orderCountry,
            transactionCurrencyCode: item.transactionCurrencyCode,
            price: item.orderMovie.ticketRate,
            tax: item.totalTaxValue,
            discount: item.discountValue,
            total: item.totalRoundedValue,
            paymentStatus: item.paymentStatus,
          };
        });
        csv.file =
          movieName.replace(/[^A-Z0-9]+/gi, "_").toLowerCase() + "_orders";
        csv.set = true;
        csv.data = csvData;
        this.setState(
          {
            csv,
          },
          () => {
            setTimeout(() => {
              let order_csv = this.refs.order_csv;
              order_csv.link.click();
            }, 100);
          }
        );
      },
      (error) => {},
      (final) => {
        csv.export = false;
        this.setState({ csv });
      }
    );
  }

  goBack() {
    const { channelId } = this.state;
    common.redirect("/producer/movies/" + channelId, 0);
  }

  render() {
    const { orderList, paging, status, csv, movieName } = this.state;
    return (
      <div>
        <Header />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={12} className="profileContent_detail">
                  <div className="myProfile_header order-detail-wrap-header">
                    <Row>
                      <Col md={6}> 
                        <h3>{movieName} - Orders</h3>
                      </Col>
                      <Col md={6} className="rt-right">
                      <Button
                          className="btn-booknow btn-goback rt-ml-10"
                          onClick={() => this.exportCSV()}
                          disabled={csv.export}
                        >
                          {csv.export ? "Exporting..." : "Export CSV"}
                        </Button>
                        {csv.set && (
                          <CSVLink
                            ref="order_csv"
                            data={csv.data}
                            headers={csv.headers}
                            filename={csv.file + ".csv"}
                            className="csvData"
                          />
                        )}

                        <Button
                          className="btn-booknow btn-goback"
                          onClick={() => this.goBack()}
                        >
                          Go Back
                        </Button>
                        <Button
                          className="btn-booknow btn-goback rt-ml-10"
                          onClick={() => this.refreshData()}
                        >
                          Refresh
                        </Button>
                        
                      </Col>
                    </Row>
                  </div>
                  <StatusBar page="movieOrder" loader="small" status={status} />
                  {status == "success" &&
                    (orderList == "" ? (
                      <StatusBar
                        page="movieOrder"
                        type="orderList"
                        status={"noData"}
                      />
                    ) : (
                      <div className="history_table">
                        <Table>
                          <thead>
                            <tr>
                              <th>Order Date</th>
                              <th>Source</th>
                              <th>Country</th>
                              <th>Currency</th>
                              <th>Price</th>
                              <th>Tax</th>
                              <th>Discount</th>
                              <th>Total</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderList.map((item, idx) => (
                              <tr key={idx}>
                                <td>
                                  {" "}
                                  {item.orderDate
                                    ? moment
                                        .utc(
                                          item.orderDate,
                                          "DD-MM-YYYY HH:mm:ss"
                                        )
                                        .local()
                                        .format("DD-MM-YYYY")
                                    : "-"}
                                </td>
                                <td>
                                  {item.orderSource ? item.orderSource : "-"}
                                </td>
                                <td>
                                  {item.orderCountry ? item.orderCountry : "-"}
                                </td>
                                <td>
                                  {item.transactionCurrencyCode
                                    ? item.transactionCurrencyCode
                                    : "-"}
                                </td>
                                <td>
                                  {item.orderMovie && item.orderMovie.ticketRate
                                    ? item.orderMovie.ticketRate
                                    : "0.00"}
                                </td>
                                <td>
                                  {item.totalTaxValue
                                    ? item.totalTaxValue
                                    : "0.00"}
                                </td>
                                <td>
                                  {item.discountValue
                                    ? item.discountValue
                                    : "0.00"}
                                </td>
                                <td>
                                  {item.totalRoundedValue
                                    ? item.totalRoundedValue
                                    : "-"}
                                </td>
                                <td
                                  className={
                                    item.paymentStatus == "NEW"
                                      ? "rt-yellow"
                                      : item.paymentStatus == "PAID"
                                      ? "rt-green"
                                      : "rt-red"
                                  }
                                >
                                  {item.paymentStatus
                                    ? item.paymentStatus
                                    : "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {paging.page != paging.total && (
                          <a
                            href="#"
                            onClick={(e) => this.pagination(e)}
                            className="loadMore"
                          >
                            <img src={common.loadImg("loadmore.png")} /> Load
                            More
                          </a>
                        )}
                      </div>
                    ))}
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { ProducerOrders };
