import React, { Component } from "react";
import { Helmet } from "react-helmet";
// _includes
import { common, tools, countryData } from "../../../_helpers";
import { Header } from "../../../_layouts/header";
import { apiServices } from "../../../api";
// css
import "../css/Signup.css";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { envConfig } from "../../../api/config";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      countryDetails: countryData,
      fields: {
        mobileNumber: "",
        selectedCountry: "91",
        email: "",
      },
      errors: {
        mobileNumber: {
          error: "",
          isReq: "Mobile Number is required!",
          minReq: "Mobile Number must be at least 8 digits",
        },
        email: {
          error: "",
          isReq: "Email is required!",
          isValid: "Please enter a valid email address",
        },
      },
      isSignUp: false,
      isSignIn: false,
      isGoogleLoading: false,
      isFacebookLoading: false,
    };
  }

  onSubmit(e, value) {
    e.preventDefault();
    this.setState({ formSubmit: true });
    let isValid = this.validateForm();
    if (isValid) {
      if (value == "register") {
        this.register();
      } else {
        this.login();
      }
    }
  }

  /*
   * user register
   */
  register = () => {
    const { fields } = this.state;
    this.setState({
      isSignUp: true,
    });
    let param = {};
    var data = {};
    if (fields.selectedCountry == "91") {
      param = {
        mobileno: fields.mobileNumber,
        countryCode: fields.selectedCountry,
      };
      data = {
        url: "SIGNUP",
        body: param,
        authorization: "none",
        method: "POST",
      };
    } else {
      param = {
        mobileno: fields.mobileNumber,
        countryCode: fields.selectedCountry,
        emailId: fields.email,
      };
      data = {
        url: "SIGNUP_EMAIL",
        body: param,
        authorization: "none",
        method: "POST",
      };
    }

    apiServices.call(
      data,
      (response) => {
        if (response.data.error) {
          common.snack("E", response.data.error);
        } else {
          localStorage.setItem("mobileNumber", parseInt(fields.mobileNumber));
          if (fields.selectedCountry == "91") {
            common.snack(
              "S",
              "Please check your Mobile for the Temporary Password"
            );
          } else {
            common.snack(
              "S",
              "Please check your Email ID for the Temporary Password"
            );
          }
          localStorage.setItem("screen", "register");
          fields["mobileNumber"] = "";
          this.setState({
            fields,
          });
          if (fields.selectedCountry == "91") {
            common.redirect("/otp/" + "mobile");
          } else {
            common.redirect("/otp/" + "email");
          }
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {
        this.setState({
          isSignUp: false,
        });
      }
    );
  };

  login = () => {
    const { fields } = this.state;
    this.loader(true);
    let param = {};
    var data = {};

    if (fields.selectedCountry == "91") {
      param = {
        mobileno: fields.mobileNumber,
      };
      data = {
        url: "FORGOT_PASSWORD_BY_PHONE",
        body: param,
        authorization: "none",
        method: "POST",
      };
    } else {
      param = {
        mobileno: fields.mobileNumber,
        emailId: fields.email,
      };
      data = {
        url: "FORGOTPASSWORD",
        body: param,
        authorization: "none",
        method: "POST",
      };
    }

    apiServices.call(
      data,
      (response) => {
        if (response.status != 200) {
          common.snack("E", response.data.error);
          this.loader(false);
        } else {
          if (fields.selectedCountry == "91") {
            common.snack(
              "S",
              "Please check your Mobile for the Temporary Password"
            );
          } else {
            common.snack(
              "S",
              "Please check your Email ID for the Temporary Password"
            );
          }
          localStorage.setItem("mobileNumber", parseInt(fields.mobileNumber));
          localStorage.setItem("email", fields.email);
          localStorage.setItem("screen", "login");
          fields["mobileNumber"] = "";
          this.setState({
            fields,
          });
          if (fields.selectedCountry == "91") {
            common.redirect("/otp/" + "mobile");
          } else {
            common.redirect("/otp/" + "email");
          }
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {
        this.loader(false);
      }
    );
  };

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    var inputValue = value;

    if (name == "mobileNumber") {
      inputValue = tools.validateMobile(inputValue);
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateField(name, inputValue);
      }
    );
  };

  // validation
  validateField = (name, value) => {
    const { errors } = this.state;
    if (errors.hasOwnProperty(name)) {
      if (!value) {
        errors[name].error = errors[name].isReq;
      } else {
        errors[name].error = "";
      }
    }

    if (name == "mobileNumber" && value != "") {
      if (value.length < 8) {
        errors[name].error = errors[name].minReq;
      }
    } else if (name == "email") {
      if (tools.ValidateEmail(value) == false) {
        errors[name].error = errors[name].isValid;
      }
    }

    this.setState({
      errors: errors,
    });
  };

  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    // for (var key of Object.keys(errors)) {
    //   if (fields[key] == "" || fields[key] == null) {
    //     errors[key].error = errors[key].isReq;
    //     isValid = false;
    //   }
    // }

    // unique validation
    let mobileNumber = fields["mobileNumber"];
    let email = fields["email"];

    if (mobileNumber != "") {
      if (mobileNumber.length < 8) {
        errors["mobileNumber"].error = errors["mobileNumber"].minReq;
        isValid = false;
      }
    } else if (mobileNumber == "" || mobileNumber == null) {
      errors["mobileNumber"].error = errors["mobileNumber"].isReq;
      isValid = false;
    }

    if (fields.selectedCountry !== "91") {
      if (email != "") {
        if (tools.ValidateEmail(email) == false) {
          errors["email"].error = errors["email"].isValid;
          isValid = false;
        }
      } else if (email == "" || email == null) {
        errors["email"].error = errors["email"].isReq;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });
    return isValid;
  }

  loader(status) {
    this.setState({
      isSignIn: status,
    });
  }

  socialSignIn = async (data, key) => {
    const { fields } = this.state;
    if (key == "google") {
      this.setState({
        isGoogleLoading: true,
      });
    } else {
      this.setState({
        isFacebookLoading: true,
      });
    }
    apiServices.call(
      data,
      (response) => {
        if (response.status !== 200) {
          common.snack("E", response.data.error);
          if (key == "google") {
            this.setState({
              isGoogleLoading: false,
            });
          } else {
            this.setState({
              isFacebookLoading: false,
            });
          }
        } else {
          common.snack("S", "Login Successful");
          localStorage.setItem("userToken", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshtoken);
          localStorage.setItem("user", JSON.stringify(response));
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("socialLogin", key);
          common.redirect("/");
          if (key == "google") {
            this.setState({
              isGoogleLoading: false,
            });
          } else {
            this.setState({
              isFacebookLoading: false,
            });
          }
        }
      },
      (error) => {
        common.snack("E", error);
        if (key == "google") {
          this.setState({
            isGoogleLoading: false,
          });
        } else {
          this.setState({
            isFacebookLoading: false,
          });
        }
      },
      (final) => {}
    );
  };

  responseGoogle = (response) => {
    console.log("============response", response)
    if (response && response.profileObj && response.profileObj.googleId) {
      let param = {
        username: response.profileObj.email,
        password: response.profileObj.googleId,
        firstName: response.profileObj.name,
        loginSource: "Google",
      };

      let data = {
        url: "SOCIAL_SIGN_IN",
        body: param,
        authorization: "none",
        method: "POST",
      };

      this.socialSignIn(data, "google");
    }
  };

  responseFacebook = (response) => {
    console.log("==================response", response)
    if (response && response.id) {
      let param = {
        username: response.email,
        password: response.id,
        firstName: response.name,
        loginSource: "Facebook",
      };
      let data = {
        url: "SOCIAL_SIGN_IN",
        body: param,
        authorization: "none",
        method: "POST",
      };

      this.socialSignIn(data, "facebook");
    }
  };

  render() {
    const { isSignUp, isSignIn, errors, formSubmit, fields, countryDetails } =
      this.state;

    return (
      <div>
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Signup</title>
          <meta
            name="description"
            content="Watch new movies releasing on Cinebazzar OTT on your favorite devices from anywhere with internet"
          />
        </Helmet>
        <section className="signup-wrap">
          <div className="container">
            <div className="signup-wrap-inner text-center">
              <h2>
                Watch unlimited movies online Join{" "}<br/>
                <span className="color-primary">Cinebazzar</span>
              </h2>
              <div className="content-box">
                <form className="signup-form">
                  <div className="input-group mb-4">
                    <select
                      name="selectedCountry"
                      value={fields.selectedCountry}
                      className="country-select"
                      onChange={(e) => this.handleOnChange(e)}
                    >
                      {countryDetails.map((item, index) => {
                        return (
                          <option key={index} value={item.dial_code}>
                            {item.name} - {item.dial_code}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      className="form-control"
                      id="phone"
                      placeholder="Mobile Number"
                      // required
                      maxLength={10}
                      name={"mobileNumber"}
                      onChange={(e) => this.handleOnChange(e)}
                      value={fields.mobileNumber}
                    />

                    
                  </div>
                  {errors.mobileNumber.error != "" && formSubmit && (
                    <p className="errorMsg mb-3">
                      {errors.mobileNumber.error}
                    </p>
                  )}
                  {fields.selectedCountry == "91" && (
                      <div className="btn-actions d-flex">
                        <button
                          variant="primary"
                          className="btn btn-secondary"
                          onClick={(e) => this.onSubmit(e, "register")}
                          disabled={isSignUp}
                        >
                          {!isSignUp ? "Sign Up" : "Processing..."}
                        </button>
                        <button
                          variant="primary"
                          className="btn btn-secondary"
                          onClick={(e) => this.onSubmit(e, "login")}
                          disabled={isSignIn}
                        >
                          {!isSignIn ? "Sign In" : "Processing..."}
                        </button>
                      </div>
                    )}
                  
                  {fields.selectedCountry !== "91" && (
                    <div>
                      <div className="email-form">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          name="email"
                          value={fields.email}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.email.error != "" && formSubmit && (
                          <span className="errorMsg">{errors.email.error}</span>
                        )}
                      </div>

                      <button
                        variant="primary"
                        className="btn btn-secondary mr-1"
                        onClick={(e) => this.onSubmit(e, "register")}
                        disabled={isSignUp}
                      >
                        {!isSignUp ? "Sign Up" : "Processing..."}
                      </button>
                      <button
                        variant="primary"
                        className="btn btn-secondary"
                        onClick={(e) => this.onSubmit(e, "login")}
                        disabled={isSignIn}
                      >
                        {!isSignIn ? "Sign In" : "Processing..."}
                      </button>
                    </div>
                  )}
                </form>
                {/* 
                <p className="or mb-4">- OR -</p>
                <ul className="signup-via-social-links list-inline mb-0">
                  {/* <li> 
                    <FacebookLogin
                      appId="4341416429272320"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile, email,"
                      onClick={() => this.responseFacebook()}
                      onFailure={(res) => this.responseFacebook(res)}
                      callback={(res) => this.responseFacebook(res)} 
                      icon={
                          <img
                            src={common.loadImg("continue_fb.png")}
                            alt="Facebook"
                            className="facebook-image"
                          />
                        }
                    >                      
                    </FacebookLogin>
                  {/* <a href="#">
                      <img
                        src={common.loadImg("continue_fb.png")}
                        alt="Facebook"
                      />
                      Continue with Facebook
                    </a> */}
                  {/* </li> */}
                  {/* <li>
                    <GoogleLogin
                      clientId={envConfig.GOOGLE_CLIENT_ID}
                      // buttonText="Continue with Google"
                      onSuccess={(res) => this.responseGoogle(res)}
                      onFailure={(res) => this.responseGoogle(res)}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      style={{width: "100%"}}
                    >
                      <a href="#">Login With Google</a>
                    </GoogleLogin>
                    <a href="#">
                      <img
                        src={common.loadImg("continue_google.png")}
                        alt="Google"
                      />
                      Continue with Google
                    </a> 
                  </li>
                </ul>*/}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export { Signup };
