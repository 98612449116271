import * as Services from "./services";
import { common } from "../_helpers";

const apiServices = {
  // fetch
  call(data = {}, response, error, final) {
    getData(data);
    let apiURL = "";
    if (data.type == "third") {
      apiURL = data.url + data.query;
    } else {
      apiURL = Services[data.url] + data.query;
    }
    let reqOptions = {
      method: data.method,
      headers: getHeaders(data),
    };
    if (data.body) {
      reqOptions.body = data.body;
    }
    fetch(apiURL, reqOptions)
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((result) => {
        if (data.type == "standard") {
          if (result.status === 403 && data.authorization == "token") {
            // call refresh
            var refData = {
              url: "GENERATE_TOKEN",
              body: {
                refreshToken: localStorage.getItem("refreshToken"),
              },
              authorization: "none",
              method: "POST",
              type: "refresh",
            };
            this.call(
              refData,
              (result) => {
                if (result.status === 403) {
                  this.callRedirect();
                } else {
                  localStorage.setItem("userToken", result.data.token);
                  localStorage.setItem(
                    "refreshToken",
                    result.data.refreshtoken
                  );
                  // call again
                  data.type = "refresh";
                  setTimeout(() => {
                    this.call(
                      data,
                      (result) => {
                        response(result);
                      },
                      (err) => {
                        error(err);
                      },
                      (fin) => {
                        final();
                      }
                    );
                  }, 500);
                }
              },
              (error) => {
                this.callRedirect();
              },
              (final) => {}
            );
          } else {
            response(result);
          }
        } else {
          response(result);
        }
      })
      .catch((err) => {
        error(err);
      })
      .finally(() => final());
  },

  callRedirect() {
    var rememberMe = localStorage.getItem("rememberMe");
    localStorage.clear();
    localStorage.setItem("rememberMe", rememberMe);
    common.redirect("/");
  },
};

function getHeaders(data) {
  let headers = {};
  if (data.contentType === 1) {
    headers = {
      "Content-Type": "application/json",
    };
  } else if (data.contentType === 2) {
    headers = {};
  }
  if (data.authorization == "token") {
    headers.Authorization = "Bearer " + localStorage.getItem("userToken");
  } else if (data.authorization == "temp") {
    headers.Authorization = "Bearer " + localStorage.getItem("tempToken");
  } else if (data.authorization == "basic") {
    headers.Authorization =
      "Basic " +
      btoa(data.credentials.username + ":" + data.credentials.password);
  }
  return headers;
}

function getData(data) {
  data.authorization = data.authorization || "token";
  data.type = data.type || "standard";
  data.contentType = data.contentType || 1;
  data.query = data.query || "";
  data.body = data.body ? JSON.stringify(data.body) : "";
}

export { apiServices };
