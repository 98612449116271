import React, { Component } from "react";
import "../css/Static.css";
import { Header } from "../../../_layouts/header";
import { common, message } from "../../../_helpers";
import { apiServices } from "../../../api";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsContent : "",
      status: ""
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = async _ => {
    try {
      let data = {
        url: "GET_TERMS_CONTENT",
        method: "GET",
      };
      apiServices.call(
        data,
        (response) => {
          this.setState({
            termsContent: response.data.pageContent.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            status: "success",
          });
        },
        (error) => {
          common.snack("E", message.somthing_wrong);
        },
        (final) => {}
      );
    } catch (e) {
      common.snack("E", e.toString())
    }
  }

  render() {
    const { termsContent } = this.state;
    return (
      <div>
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Terms and conditions</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Terms and conditions</li>
            </ul>
          </div>

          <div className="row">
            <div className="col-md-12 movie-container about">
              <img
                src={common.loadImg("headerlogo.png")}
                alt="aboutus_image"
                className="img-fluid center"
              />
              {/* <h4>TERMS & CONDITIONS OF USE</h4> */}
              <p>
                {termsContent.split('<br />').map((item, key) => {
                  return (
                    <span key={key}>{item}<br/></span>
                  )
                })}
              </p>
              {/* <p>[Last updated: July 2021]</p>
              <p>
                Cinebazzar Media Private Limited (hereinafter “CMPL/We/Us/Our”)
                is a video-on-demand service.
                <br />
                <br />
                These CMPL Terms and Conditions of Use create a contract between
                you and CMPL (“the Agreement/Terms"). Please read these Terms,
                along with the Privacy Policy, and all other rules and policies
                related to CMPL’s services. Your transactions and use of the
                Services (defined below) are subject to these Terms. Each time
                you visit, browse or use the Services, you accept these Terms.
                The Services can be used by any person under 18 years of age
                only with the guidance of a parent or guardian.
                <br />
                <br />
              </p>
              <h4>SERVICES</h4>
              <p>
                CMPL allows you to view the audio-visual content (“Digital
                Content”) on its website and/or its application/s made available
                over various devices including but not limited to television,
                mobile phones, etc. (“Platform”) in exchange for either a
                one-time fee, enabling you to rent Digital Content (“Transaction
                Video-on-Demand/TVOD”)or for a recurring subscription to access
                the Digital Content(“Subscription Video-on-Demand/SVOD”), (each
                a “Paid Service” and collectively “Paid Services”). In addition
                to TVOD and SVOD, Paid Services also include any other services
                which may be offered by CMPL in the future.
                <br />
                <br />
                Further, certain Digital Content may be made available by CMPL
                free of cost on its Platform at its sole discretion (“Free
                Video-on-Demand/FVOD”). Paid Services and FVOD shall hereinafter
                be cumulatively referred to as “Services”.
                <br /> <br />
                Each time you place an order for a Service, you enter into a
                separate contract for services on these Terms. When you complete
                payment for any Paid Service or access any FVOD service, you
                signify that you wish to enter into a binding contract for the
                applicable Services.
                <br />
                <br />
                You are required to create an account to use our Services. You
                confirm that you are at least eighteen (18) years of age and are
                hence eligible to create an account and use our Services.
                <br />
                <br />
              </p>
              <h4>CONTENT</h4>
              <p>
                CMPL may allow you to access the Digital Content in the
                following manner: (1) Digital Content available on a rental
                basis for up to thirty (30) days (“Rental Content”); (2) Digital
                Content available under a subscription plan (“Subscription
                Content”); (3) Digital Content available on a free to watch
                basis (“Free Content”).
                <br />
                <br />
                You may cancel an order for Subscribed Content or Rental Content
                within _ hours of subscription or rental by __; except that you
                may not cancel an order for Subscribed Content or Rental Content
                once you have started watching or downloading such Digital
                Content.
                <br />
                <br />
                You may cancel a pre-order for Subscribed Content or Rental
                Content any time before its release date. The release date of
                pre-ordered Digital Content is subject to change at the sole
                discretion of CMPL.
                <br />
                <br />
                CMPL grants you a non-exclusive, non-transferable,
                non-sublicensable, limited license, during the applicable
                Viewing Period, to access and view the Digital Content in
                accordance with this Agreement, for personal, non-commercial,
                private use. We may automatically remove Digital Content from
                your compatible device after the end of its Viewing Period.
                ‘Viewing Period’ for the purpose of this policy shall mean the
                period during which a user/subscriber is allowed access to any
                Digital Content as per the viewing policy
                <br />
                <br />
                Subscribed Digital Content will generally continue to be
                available to you for download or streaming from the Service, as
                applicable, but may become unavailable due to potential content
                provider licensing restrictions or for other reasons, and CMPL
                will not be liable to you if any Free Content or Subscribed
                Content becomes unavailable for further download or streaming.
                <br />
                <br />
              </p>

              <h4>USAGE OF PAID SERVICES</h4>

              <p>
                Usage of Services by you must strictly follow the rules set
                forth under the following clauses. Any other use the Services is
                a material breach of this Agreement.
                <br />
                <br />
                You may use the Services only for personal, non-commercial
                purposes. CMPL’s delivery of Digital Content does not transfer
                any commercial or promotional use rights to you and does not
                constitute a grant or waiver of any rights of the copyright
                owners.
                <br />
                <br />
                Digital Content is viewable only on a single device at a time
                and must be played within _ days, and completed within _ hours
                of the start of play (stopping, pausing, or restarting does not
                extend this period).
                <br />
                <br />
              </p>

              <h4>CANCELLATION</h4>
              <p>
                You can cancel your subscription to the Service at any time, and
                you will continue to have access to the Services through the end
                of your billing period. Please notice that since the Service is
                provided to you online, there are no delivery costs associated
                with the cost of the subscription fee.
              </p>
              <p>
                We will honour your request for cancellation of your
                subscription to the Services if such a request is made within 24
                hours of you signing up to the Service. Upon receipt of your
                cancellation request, for any reason whatsoever, we will refund
                100% of your payment within __ days from the requested date.
                Please note that we do not provide refunds or credits for any
                partial membership periods or unwatched content.
              </p>
              <p>
                To cancel, navigate to the "Account" tab and follow the
                cancellation instructions. Your account will automatically close
                at the end of your current billing cycle if you cancel your
                membership. If you signed up for the Services using a payment
                mode originating from a third party and want to cancel your
                membership, you may have to turn off auto-renew or unsubscribe
                from the Services through that third-party payment platform.
              </p>
              <p>
                We reserve the right to change our subscription plans and the
                price of our service at any time; however, any price increases
                or changes to your subscription plans will take effect no sooner
                than __ days after you have been notified.
              </p>

              <h4>PERSONALIZED RECOMMENDATIONS</h4>
              <p>
                CMPL may recommend Digital Content to you based on your
                downloads, purchases, rentals, viewing history and other
                activities. You may opt-out from receiving such personalized
                recommendations in your account settings.
                <br />
                <br />
                Some recommendation features may require your permission before
                they are turned. If you turn these features on, you will be
                asked to give CMPL permission to collect and store certain data,
                including but not limited to your device activity, location, and
                usage.
              </p>

              <h4>AMENDMENT/MODIFICATION</h4>
              <p>
                CMPL reserves the right at any time to amend or modify this
                Agreement and to add new or additional terms or conditions on
                your use of the Services. Such
                modifications/amendments/additional terms and conditions will be
                effective immediately and incorporated into this Agreement. YOUR
                CONTINUED USE OF THE SERVICES WILL BE DEEMED ACCEPTANCE THEREOF.
                <br />
                <br />
              </p>

              <h4>THIRD-PARTY MATERIALS</h4>
              <p>
                CMPL is not responsible or liable for third party materials
                included within the Digital Content.
                <br />
                <br />
              </p>

              <h4>INTELLECTUAL PROPERTY</h4>
              <p>
                The Digital Content, including but not limited to audio clips,
                video clips, editorial content, scripts, and any other work
                contain proprietary information and material that is owned by
                CMPL and/or its licensors and is protected by applicable
                intellectual property and other laws.
                <br />
                <br />
                You agree that you will not use such proprietary information or
                materials in any manner whatsoever except for the use of the
                Digital Content for personal, non-commercial uses in compliance
                with this Agreement. No part of the Digital Contentmay be
                reproduced in any form or by any means, except as expressly
                permitted by this Agreement.
                <br />
                <br />
                You agree not to modify, rent, loan, sell or distribute the
                Digital Content in any manner, and you shall not exploit the
                Digital Content in any manner not expressly authorized by CMPL.
                <br />
                <br />
                You are granted no right or license with respect to CMPL’s
                trademarks.
              </p>

              <h4>TERMINATION </h4>
              <p>
                If you violate or fail to comply with any of the terms of the
                Agreement, your rights under this Agreement will forthwithbe
                terminated without notice, and CMPL may, in its discretion,
                immediately revoke your access to the Services and the Digital
                Content without refund of any payments made by you towards the
                Services. In such an event, you must immediately delete all
                copies of the Digital Content that you may have downloaded.
                <br />
                <br />
                CMPL also reserves the right to modify, suspend, or discontinue
                the Services at any time without notice to you, and CMPL will
                not be liable to you or any third party.
                <br />
                <br />
              </p>

              <h4>WARRANTIES</h4>
              <p>
                You agree that you shall not (i) transfer, copy or display the
                Digital Content, except as permitted in this Agreement; (ii)
                sell, rent, lease, distribute, or broadcast any right to the
                Digital Content; (iii) remove any proprietary notices or labels
                on the Digital Content; (iv) attempt to disable, bypass, modify,
                defeat, or otherwise circumvent any digital rights management or
                other content protection system used as part of the Service; or
                (v) use the Paid Service or Digital Content for any commercial
                or illegal purpose.
                <br />
                <br />
                CMPL does not guarantee, represent, or warrant that your use of
                the Services will be uninterrupted, or error-free.
                <br />
                <br />
                You agree that from time to time, CMPL may remove the Services
                for indefinite periods, cancel the Services at any time, or
                otherwise limit or disable your access to the Services without
                notice to you.
                <br />
                <br />
                You expressly agree that your use of, or inability to use, or
                activity in connection with the Services is at your sole risk.
                <br />
                <br />
                The Services and Digital Content delivered to you are provided
                “as is” for your use, without warranties of any kind, either
                express or implied.
                <br />
                <br />
                You agree that you shall not sue or recover any damages from
                CMPL, its directors, officers, employees, affiliates, agents,
                contractors, and licensors as a result of its decision to remove
                or refuse to process any information or content, to warn you, to
                suspend or terminate your access to the services, or to take any
                other action during the investigation of a suspected violation
                or as a result of CMPL’s conclusion that a violation of this
                agreement has occurred.
                <br />
                <br />
              </p>

              <h4>LIMITATION OF LIABILITY</h4>
              <p>
                In no case shall CMPL, its directors, officers, employees,
                affiliates, agents, contractors, or licensors be liable for any
                direct, indirect, incidental, punitive, special, or
                consequential damages arising from your use of any of the Paid
                Services or for any other claim related in any way to your use
                of the services and/or content, including, but not limited to,
                any errors or omissions in any content, including Digital
                Content, or any injury, loss or damage of any kind incurred as a
                result of the use of any content posted, transmitted, or
                otherwise made available via the Services, even if advised of
                their possibility.
                <br />
                <br />
                You agree that your submission of any information in connection
                with the Platform and the Services is at your sole risk, and you
                hereby release CMPL from any and all liability to you for any
                loss or liability relating to such information in any way.
                <br />
                <br />
                CMPL does not represent or guarantee that the Services will be
                free from loss, corruption, attack, viruses, interference,
                hacking, or other security intrusions, and you hereby release
                CMPL from any liability relating thereto. You shall be
                responsible for backing up your own system, including any
                Digital Content acquired or rented through the Services.
                <br />
                <br />
                CMPL is not responsible for data charges you may incur in
                connection with your use of the Services.
                <br />
                <br />
              </p>

              <h4>WAIVER</h4>
              <p>
                Your access or use or subscription of any contenton the
                Platform, including Digital Content,does not transfer any
                commercial or promotional use rights to you and does not
                constitute a grant or waiver of any rights of the copyright
                owners thereof.
                <br />
                <br />
                CMPL’s failure to insist upon or enforce your strict compliance
                with this Agreement will not constitute a waiver of any of our
                rights, and nor shall it impair such right on future occasions.
                <br />
                <br />
              </p>

              <h4>SEVERABILITY</h4>
              <p>
                In the event that any term, condition, or provision of this
                Agreement is held to be a violation of any applicable law,
                statute, or regulation the same shall be deemed to be deleted
                from this Agreement and shall be of no force and effect and this
                Agreement shall remain in full force and effect as if such term,
                condition, or provision had not originally been contained in
                this Agreement.
                <br />
                <br />
              </p>

              <h4>INDEMNITY</h4>
              <p>
                You agree and undertake to indemnify and hold CMPL, its
                subsidiaries, affiliates, officers, employees, agents, partners
                and licensors (collectively ‘CMPL Parties’) harmless from and
                against any losses, costs, liabilities, expenses (including
                reasonable attorneys’ fees), claims, damages, complaints or
                legal proceedings, including the cost or including without
                limitation any legal fees or attorneys’ fees, arising out of or
                relating to:
                <br />
                <br />
                Any deficiency, error, inaccuracy in the information,
                representations and warranties provided by you;
                <br />
                <br />
                Any of your unfulfilled obligations;
                <br />
                <br />
                Breach by you of any of your undertakings, obligations,
                representations and warranties under this Agreement;
                <br />
                <br />
                Breach by you of any rights of third parties, including without
                limitation any intellectual property rights;
                <br />
                <br />
                Your violation of any applicable laws, rules or regulations.
                <br />
                <br />
                In the event of any claims, actions, suits, complaints or
                proceedings, you will promptly adjust, settle, defend or
                otherwise dispose of such claim exclusively at its own cost
                without binding CMPL to any obligations or liabilities and
                without prejudice to any rights of CMPL. We will reasonably
                co-operate with you in all such matters, and reserve the right
                to take over control of any such matter at our sole discretion
                if we so deem fit. In the event of our takeover of control over
                such a matter, you confirm that you will fully cooperate with us
                in asserting available defences. You agree and acknowledge that
                this provision will survive even upon cessation of your use of
                our Platform.
                <br />
                <br />
              </p>

              <h4>GOVERNING LAW & JURISDICTION</h4>
              <p>
                This Agreement and the relationship between you and CMPL and all
                transactions on the Services and any interactions thereof shall
                be governed by the laws of India.
                <br />
                <br />
                Any dispute arising out of or in connection with this Agreement
                shall be referred to and finally resolved by arbitration under
                the Arbitration and Conciliation Act, 1996, as amended or
                re-enacted from time-to-time. CMPL and you shall mutually
                appoint a sole arbitrator. All proceedings in any such
                arbitration shall be conducted in English and the venue shall be
                in Puneand the Courts in Pune alone shall have exclusive
                jurisdiction to deal with the arbitration proceedings and the
                awards in accordance with the law. The arbitration award shall
                be final and binding on the Parties, and the Parties agree to be
                bound thereby and to act accordingly.
                <br />
                <br />
              </p>

              <h4>CUMULATIVE RIGHTS </h4>
              <p>
                All rights and remedies granted hereunder to CMPL are in the
                cumulative and not alternative to each other and CMPL may
                exercise one or more of such rights and remedies in conjunction
                with the other.
                <br />
                <br />
              </p>

              <h4>ASSIGNMENT</h4>
              <p>
                You shall not assign or transfer any of your rights or
                obligations under this Agreement to any third-party.
                <br />
                <br />
              </p>

              <h4>SURVIVAL</h4>
              <p>
                The provisions of this Agreement that should, by their nature
                survive termination and/or expiration, shall and do survive such
                termination and/or expiration.
                <br />
                <br />
              </p>

              <h4>CONTACT INFORMATION</h4>
              <p>
                For any further queries regarding the above Terms and Conditions
                of Use, please contact us here.
                <br />
                <br />
              </p>

              <h3>IP DISCLAIMER</h3>
              <h4>INTELLECTUAL PROPERTY</h4>
              <p>
                AmugiMediatech Private Limited(‘CMPL’) owns several intellectual
                property rights. All rights are reserved worldwide across all
                modes, media and formats.
                <br />
                <br />
              </p>

              <h4>Copyright:</h4>
              <p>
                The Services, including but not limited to all the content
                provided on the ‘Cinebazzar’ website and/or the corresponding
                application/s thereof (‘Platform’), are protected by copyright,
                trade secret or other intellectual property laws and treaties.
                All materials on the Platform are ©️ 2021AmugiMediatech Private
                Limited. You are not granted any right or license in the
                copyright, or other intellectual property right in, including
                but not limited to, any of the content available on thePlatform.
                You are prohibited from republishing or creating a hypertext
                link or frame to any content provided on our Platform without
                the prior written consent from CMPL.Without the prior written
                consent of CMPL, you are prohibited from assimilating in whole
                or in part any element of our Platform in any other websites or
                programs or by any other means.
                <br />
                <br />
              </p>

              <h4>Trademark:</h4>
              <p>
                ‘Cinebazzar’ is a licensed trademark of CMPL. You are not
                granted any right or license in the trademark or other
                intellectual property right, either owned or licensed, in any
                content available on our Platform. You may not use our trademark
                without the prior written consent from Our Company. The Platform
                contains elements protected by trademarks and other intellectual
                property rights. Without the prior written consent of CMPL, it
                is prohibited to assimilate in whole or in part any element of
                our Platform in any other websites or programs or by any other
                means.
                <br />
                <br />
              </p>

              <h4>INFRINGEMENT CLAIMS POLICY</h4>
              <p>
                Upon receipt of a notice, we will investigate all claims of
                intellectual property infringement concerning the content of our
                Platform in accordance with the applicable intellectual property
                laws. To report any alleged copyright infringement, please share
                the following details with us:
                <br />
                <br />
                A copy of the copyrighted work alleged to have been infringed;
                <br />
                <br />
                A physical or electronic version of the evidence that the person
                alleging the infringement is the copyright owner or the person
                authorized to act on behalf of the owner;
                <br />
                <br />
                A link and description to where the allegedly infringing
                material is located on our Platform;
                <br />
                <br />
                An affidavit by you that the report has been made in good faith,
                believing that the copyright owner has not authorized the use of
                the copyrighted material to us;
                <br />
                <br />
                An affidavit by you made under penalty of perjury stating that
                all the information provided by you in the notice is accurate
                and that you are the copyright owner or the authorized
                representative of the copyright owner.
                <br />
                <br />
              </p>

              <p>
                We can be reached through the following email address:{" "}
                <b>reachcinebazzar@gmail.com</b>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Terms };
