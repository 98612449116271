import React from "react";
import { history } from "../_helpers";
var jwt = require('jsonwebtoken');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles : ''
    }
  }
  componentDidMount(){
    // let userData = localStorage.getItem("userToken");
    // let decodeData = jwt.decode(userData);
    // this.setState({
    //   roles : decodeData.roles
    // });
  }
  render() {
    const { roles } = this.state;
    return (
      <ul className="sidenav">
        <li
          className={history.location.pathname === "/profile" ? "active" : ""}
        >
          <a href="/profile" className="link">
            <span>My Profile</span>
          </a>
        </li>
        <li
          className={
            history.location.pathname === "/purchasehistory" ? "active" : ""
          }
        >
          <a href="/purchasehistory">Booking History</a>
        </li>
        <li
          className={
            history.location.pathname === "/resetpassword" ? "active" : ""
          }
        >
          <a href="/resetpassword">Reset Password</a>
        </li>
        <li
          className={
            history.location.pathname === "/notification" ? "active" : ""
          }
        >
          <a href="/notification" className="link">
            <span>Notification</span>
          </a>
        </li>
        <li
          className={
            history.location.pathname === "/profilesettings" ? "active" : ""
          }
        >
          <a href="/profilesettings">Settings</a>
        </li>
      </ul>
    );
  }
}
export { Sidebar };
