import React from "react";
import { apiServices } from "../../api";
import { common, tools } from "../../_helpers";
import { Form, Modal } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { StatusBar } from "../../_includes";
import moment from "moment";
import "./Reviews.css";
class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movieDetails: [],
      reviewModal: false,
      reviewListModal: false,
      reviews: {
        rating: 0,
        comments: "",
        isLoading: false,
      },
      reviewList: [],
      status: "process",
      isLoadMore: false,
      paging: {
        page: 1,
        size: 10,
        total: 1,
      },
      isLand: false,
    };
  }

  // modal
  closeModal(type) {
    if (type == "review") {
      this.resetReview();
    } else if (type == "reviewList") {
      this.resetReviewList();
    }
  }

  showModal(type) {
    if (type == "review") {
      this.setState({
        reviewModal: true,
      });
    } else if (type == "reviewList") {
      this.setState({
        reviewListModal: true,
      });
    }
  }

  // ratings
  changeRating(rating) {
    const { reviews } = this.state;
    reviews.rating = rating;
    this.setState({
      reviews,
    });
  }

  createReview() {
    const { movieDetails, reviews } = this.state;
    let allow = true;

    if (reviews.rating == 0) {
      common.snack("E", "Select your ratings");
      allow = false;
    } else if (reviews.comments == "") {
      common.snack("E", "Please enter your comments");
      allow = false;
    }

    if (allow) {
      reviews.isLoading = true;
      this.setState({ reviews });
      let param = {
        userId: common.lsUser("userId"),
        rating: reviews.rating,
        reviewComments: reviews.comments,
      };
      var data = {
        url: "REVIEWS_MOVIE",
        query: "/" + movieDetails.movieId + "/moviereviews",
        body: param,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status == 201) {
            common.snack("S", "Thank you for your review");
            this.resetReview();
          }
        },
        (error) => {
          common.snack("E", error);
        },
        (final) => {
          this.setState({
            reviewModal: false,
          });
        }
      );
    }
  }

  updateReview() {
    const { movieDetails, reviews } = this.state;
    reviews.isLoading = true;
    this.setState({ reviews });

    let param = {
      movieId: movieDetails.movieId,
      userId: common.lsUser("userId"),
      rating: reviews.rating,
      reviewComments: reviews.comments,
      active: "Y",
    };

    var data = {
      url: "REVIEWS_MOVIE_REVIEWS",
      query: "/1",
      body: param,
      method: "PUT",
    };

    apiServices.call(
      data,
      (response) => {
        common.snack("S", "Your review has been updated");
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {
        reviews.isLoading = false;
        this.setState({ reviews });
      }
    );
  }

  getMovieReviews(type) {
    const { movieDetails, reviewList, paging } = this.state;
    var data = {
      url: "REVIEWS_MOVIE_REVIEWS_BY_MOVIE",
      query:
        "/" +
        movieDetails.movieId +
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=createdOn,desc",
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            reviewList.push(item);
          });
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          reviewList,
          paging,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {
        if (type == "load") {
          this.setState({ isLoadMore: false });
          tools.scrollEnd("movieReviews");
        }
      }
    );
  }

  resetReview() {
    this.setState({
      movieDetails: [],
      reviews: {
        rating: 0,
        comments: "",
        isLoading: false,
      },
      reviewModal: false,
    });
  }

  resetReviewList() {
    this.setState({
      reviewListModal: false,
    });
  }

  refreshReviewList() {
    this.setState(
      {
        reviewList: [],
        status: "process",
        paging: {
          page: 1,
          size: 10,
          total: 1,
        },
      },
      () => {
        this.getMovieReviews("init");
      }
    );
  }

  pagination() {
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        isLoadMore: true,
        paging,
      },
      () => {
        this.getMovieReviews("load");
      }
    );
  }

  // handle
  handleOnChange = (e) => {
    const { reviews } = this.state;
    reviews[e.target.name] = e.target.value;
    this.setState({ reviews });
  };

  // Instance functions
  writeReview(movieDetails) {
    this.setState({
      movieDetails,
      reviewModal: true,
    });
  }

  listReview(movieDetails) {
    const { isLand } = this.state;
    this.setState(
      {
        movieDetails,
        reviewListModal: true,
        isLand: true,
      },
      () => {
        if (!isLand) {
          this.getMovieReviews("init");
        }
      }
    );
  }

  getRatings(rating) {
    let ratingList = [];
    for (let i = 0; i < rating; i++) {
      ratingList.push(<span className="star-img" key={i}></span>);
    }
    return ratingList;
  }

  render() {
    const {
      movieDetails,
      reviewModal,
      reviewListModal,
      reviews,
      reviewList,
      status,
      isLoadMore,
      paging,
    } = this.state;
    return (
      <div>
        {/* Write Review */}
        <Modal
          show={reviewModal}
          onHide={() => this.closeModal("review")}
          className="movie-songs-modal"
        >
          <Modal.Header>
            <Modal.Title className="socialShareTitle">
              Write a Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="socialShare">
              <StarRatings
                rating={reviews.rating}
                starRatedColor="#f2a404"
                changeRating={(rating) => this.changeRating(rating)}
                numberOfStars={5}
                name="rating"
                starDimension="30px"
              />
            </div>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Write your review</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="comments"
                value={reviews.comments}
                onChange={(e) => this.handleOnChange(e)}
                style={{ color: "black" }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="SocialShareFooter">
            <div className="modal-footer-wrap">
              <button
                onClick={() => this.createReview()}
                className="btn btn-bordered mr-3"
                disabled={reviews.isLoading}
              >
                {reviews.isLoading ? "Submiting..." : "Submit"}
              </button>
              <button
                onClick={() => this.closeModal("review")}
                className="btn btn-bordered rt-ml-5"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Review List Popup */}
        <Modal
          show={reviewListModal}
          size="lg"
          className="movie-songs-modal"
          onHide={() => this.closeModal("reviewList")}
        >
          <Modal.Header closeButton />
          <div className="reviewContent">
            <p className="reviewTitle">{movieDetails.movieName} - Reviews</p>
            <div className="reviewCount">
              <p>
                <span>Ratings</span> ({movieDetails.avgRating} / 5)
              </p>
              <p>
                <span>Total Reviews: </span> {movieDetails.overallRank}
              </p>
            </div>
          </div>
          <Modal.Body>
            <StatusBar page="review" loader="small" status={status} />
            {status == "success" &&
              (reviewList == "" ? (
                <StatusBar page="review" type="reviewList" status={"noData"} />
              ) : (
                <ul className="review-list" id="movieReviews">
                  {reviewList.map((item, key) => (
                    <li key={key}>
                      <div className="review-list-header media">
                        <img
                          src={
                            item.user.userImageName
                              ? item.user.userImageName
                              : common.loadImg("no_actor_img.png")
                          }
                          alt="user"
                          className="mr-3 user-img"
                        />
                        <div className="media-body">
                          <h2 className="reviewer-name">
                            {item.user.firstName}
                          </h2>
                          <p className="review-date">
                            {moment(
                              tools.convertToLocal(item.createdOn)
                            ).format("MMM DD, YYYY")}
                          </p>
                        </div>
                        <div className="user-ratings">
                          {this.getRatings(item.rating)}
                        </div>
                      </div>
                      <p className="review-txt">{item.reviewComments}</p>
                    </li>
                  ))}
                </ul>
              ))}
          </Modal.Body>
          <Modal.Footer className="SocialShareFooter">
            <div className="modal-footer-wrap">
              {paging.page != paging.total && (
                <button
                  onClick={() => this.pagination()}
                  className="btn btn-bordered mr-3"
                  disabled={isLoadMore}
                >
                  {isLoadMore ? "Loading..." : "Load More"}
                </button>
              )}
              {status != "process" && (
                <button
                  onClick={() => this.refreshReviewList()}
                  className="btn btn-bordered rt-ml-5"
                  disabled={isLoadMore}
                >
                  Refresh
                </button>
              )}
              <button
                onClick={() => this.closeModal("reviewList")}
                className="btn btn-bordered rt-ml-5"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export { Reviews };
