import React, { Component } from "react";
import "../css/Static.css";
import { Header } from "../../../_layouts/header";
import { common, message } from "../../../_helpers";
import { apiServices } from "../../../api";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutContent : "",
      status: ""
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = async _ => {
    try {
      let data = {
        url: "GET_ABOUT_CONTENT",
        method: "GET",
      };
      apiServices.call(
        data,
        (response) => {
          this.setState({
            aboutContent: response.data.pageContent.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            status: "success",
          });
        },
        (error) => {
          common.snack("E", message.somthing_wrong);
        },
        (final) => {}
      );
    } catch (e) {
      common.snack("E", e.toString())
    }
  }

  render() {
    const { aboutContent } = this.state;
    return (
      <div>
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">About Us</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">About Us</li>
            </ul>
          </div>

          <div>
            <div className="movie-container about">
              <img
                src={common.loadImg("headerlogo.png")}
                alt="aboutus_image"
                className="img-fluid center"
              />
              {/* <h4>About Us</h4> */}
              <p>
                {aboutContent.split('<br />').map((item, key) => {
                  return (
                    <span key={key}>{item}<br/></span>
                  )
                })}
              </p>
              {/* <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cinebazzar – is an 
                Bangalore based OTT platform focussed on bringing 
                quality Kannada movies to the worldwide audience.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Launched officially in August 2021, 
                we aim to bring the Kannada movies with great quality to you directly.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { About };
