import React, { Component } from "react";
import "../css/Static.css";
import { Header } from "../../../_layouts/header";
import { common, message } from "../../../_helpers";
import { apiServices } from "../../../api";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactContent : "",
      status: ""
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = async _ => {
    try {
      let data = {
        url: "GET_CONTACT_CONTENT",
        method: "GET",
      };
      apiServices.call(
        data,
        (response) => {
          this.setState({
            contactContent: response.data.pageContent.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            status: "success",
          });
        },
        (error) => {
          common.snack("E", message.somthing_wrong);
        },
        (final) => {}
      );
    } catch (e) {
      common.snack("E", e.toString())
    }
  }

  render() {
    const { contactContent } = this.state;
    return (
      <div>
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3 ">
            <h1 className="page-title">Contact Us</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Contact Us</li>
            </ul>
          </div>

          <div className="row">
            <div className="col-md-12 movie-container about">
              <img
                src={common.loadImg("headerlogo.png")}
                alt="aboutus_image"
                className="img-fluid center"
              />
               <p>
                {contactContent.split('<br />').map((item, key) => {
                  return (
                    <span key={key}>{item}<br/></span>
                  )
                })}
              </p>

              {/* <div className="row founder-content">
                <div className="col-md-3 founder-img-wrap">
                  <img
                     src={common.loadImg("founder.jpeg")}
                     alt="aboutus_image"
                     className="img-fluid founder-img"
                  />
                  </div>
                  <p className="col-md-9">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suresh Nallusamy is the founder of Daina Pictures and hails from the southern part of india. Suresh completed his Masters degree in Rajasthan Technical University, and he later moved to Japan, where he resides now. Currently Suresh is running his trading business along with film production & distribution.
                  </p>
              </div> */}

              {/* <h3>Cinebazzar nextlevel pvt ltd:-</h3>
              <div className="row address">
                <p>  <p>1177, 4th main, 3rd cross, </p> <p>BEML 3rd stage,</p>
                  <p>R.R.Nagar,</p>
                  <p>Bangalore- 560098</p>
                  <p>INDIA.</p>
                </p>
                <p className="contactDetails">
                  <p><b>m</b>: +91 7019797179, +91 7795912344</p>
                  <p><b>e</b> : Infocinebazzar@gmail.com</p>
                  <p><b>w</b>: cinebazzar.com</p>
                </p> */}
                {/* <p className="gstNo"><b>CIN No</b> : - | <b>GST No</b> : -</p> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Contact };
