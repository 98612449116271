import React, { Component } from "react";
import { Helmet } from "react-helmet";
// _includes
import { common, tools } from "../../../_helpers";
import { Header } from "../../../_layouts/header";
import { apiServices } from "../../../api";
// css
import "../css/Signup.css";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstName: "",
        lastName: "",
        email: "",
      },
      errors: {
        firstName: {
          error: "",
          isReq: "First Name is required!",
        },
        lastName: {
          error: "",
          isReq: "Last Name is required!",
        },
        email: {
          error: "",
          isReq: "Email is required!",
          isValid: "Please enter a valid email address",
        },
      },
      gender: "male",
      isLoading: false,
      formSubmit: false,
      userId: "",
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("userId");
    this.setState({
      userId: user,
    });
  }

  // validation
  validateField = (name, value) => {
    const { errors } = this.state;
    if (errors.hasOwnProperty(name)) {
      if (!value) {
        errors[name].error = errors[name].isReq;
      } else {
        errors[name].error = "";
      }
    }

    // unique validation
    if (name == "email") {
      if (tools.ValidateEmail(value) == false) {
        errors[name].error = errors[name].isValid;
      }
    }

    this.setState({
      errors: errors,
    });
  };

  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    // unique validation
    let emailId = fields["email"];
    if (emailId != "") {
      if (tools.ValidateEmail(emailId) == false) {
        errors["email"].error = errors["email"].isValid;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });
    return isValid;
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    var inputValue = value;

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateField(name, inputValue);
      }
    );
  };

  loader(status) {
    this.setState({
      isLoading: status,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ formSubmit: true });
    let isValid = this.validateForm();
    if (isValid) {
      this.updateProfile();
    }
  };

  updateProfile = async (_) => {
    const { fields, userId, gender } = this.state;
    this.loader(true);
    let param = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      emailId: fields.email,
      gender: gender == "male" ? 0 : 1,
    };
    var data = {
      url: "UPDATE_PROFILE",
      query: userId,
      body: param,
      authorization: "token",
      method: "PUT",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.error) {
          common.snack("E", "Use valid credentials");
        } else {
          localStorage.setItem("user", JSON.stringify(response));
          common.snack("S", "User Information added successfully");
          common.redirect("/");
          fields["firstName"] = "";
          fields["lastName"] = "";
          fields["email"] = "";

          this.setState({
            fields,
          });
        }
      },
      (error) => {
        common.snack("E", error);
      },
      (final) => {
        this.loader(false);
      }
    );
  };

  selectGender = (event) => {
    const { name } = event.target;
    this.setState({
      gender: name,
    });
  };

  render() {
    const { fields, gender, errors, isLoading, formSubmit } = this.state;
    return (
      <div>
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>User Information</title>
          <meta
            name="description"
            content="Watch new movies releasing on Cinebazzar OTT | Digital Theater on your favorite devices from anywhere with internet"
          />
        </Helmet>
        <section className="signup-wrap">
          <div className="container">
            <div className="signup-wrap-inner text-center">
              <h2>
                Watch unlimited movies online Join{" "}
                <span className="color-primary">
                  Cinebazzar OTT | Digital Theater
                </span>
              </h2>
              <div className="content-box">
                <form className="signup-form">
                  <h4 className="title" style={{ color: "black" }}>
                    User Information
                  </h4>
                  <div className="input-group">
                    <input
                      className="form-control check"
                      id="phone"
                      placeholder="First Name"
                      name={"firstName"}
                      onChange={(e) => this.handleOnChange(e)}
                      value={fields.firstName}
                    />
                  </div>
                  {errors.firstName.error != "" && formSubmit && (
                    <span className="errorMsg">{errors.firstName.error}</span>
                  )}
                  <div className="input-group">
                    <input
                      className="form-control check"
                      id="phone"
                      placeholder="Last Name"
                      name={"lastName"}
                      onChange={(e) => this.handleOnChange(e)}
                      value={fields.lastName}
                    />
                  </div>
                  {errors.lastName.error != "" && formSubmit && (
                    <span className="errorMsg">{errors.lastName.error}</span>
                  )}
                  <div className="input-group">
                    <input
                      className="form-control check"
                      id="phone"
                      placeholder="Email Address"
                      name={"email"}
                      onChange={(e) => this.handleOnChange(e)}
                      value={fields.email}
                    />
                  </div>
                  {errors.email.error != "" && formSubmit && (
                    <span className="errorMsg">{errors.email.error}</span>
                  )}
                  <div className="input-group">
                    <div className="check">
                      <input
                        type="radio"
                        className="form-check-input check"
                        id="female"
                        name="male"
                        onChange={(e) => this.selectGender(e)}
                        checked={gender == "male" ? true : false}
                      />
                      <label
                        className="form-check-label"
                        for="male"
                        style={{ color: "black" }}
                      >
                        Male
                      </label>
                    </div>
                    <div className="check">
                      <input
                        type="radio"
                        className="form-check-input check"
                        id="female"
                        name="female"
                        onChange={(e) => this.selectGender(e)}
                        checked={gender == "female" ? true : false}
                      />
                      <label
                        className="form-check-label"
                        for="female"
                        style={{ color: "black" }}
                      >
                        Female
                      </label>
                    </div>
                  </div>

                  <button
                    variant="primary"
                    className="btn btn-secondary"
                    onClick={(e) => this.onSubmit(e)}
                    disabled={isLoading}
                  >
                    {!isLoading ? "Submit" : "Processing..."}
                  </button>
                </form>
                {/* <ul className="signup-via-social-links list-inline mb-0">
                  <li className="nav-item">
                    <a
                      className="btn btn-secondary"
                      style={{ paddingTop: 13 }}
                      href="#"
                    >
                      Submit
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </section>

        {/* <section className="entertainment-wrap">
          <div className="container">
            <div className="entertainment-wrap-inner">
              <div className="section-title text-center">
                <h2 className="title">
                  Entertainment at your comfort, anytime, anywhere across
                  languages across devices
                </h2>
              </div>
              <img
                className="img-fluid"
                src={common.loadImg("img.png")}
                alt="Entertainment Languages"
              />
            </div>
          </div>
        </section> */}

        {/* <section className="banner-wrap">
          <div className="container">
            <div className="banner-text text-center">
              <h2>
                Stream unlimited movies on your <span>phone,</span>{" "}
                <span>tablet,</span> <span>laptop</span> and <span>TV</span>.
              </h2>
            </div>
          </div>
        </section> */}

        {/* <section className="features-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("anytime_anywhere.png")}
                    alt="Anytime Anywhere Watch Movies"
                  />
                  <h4>
                    Anytime, Anywhere <br />
                    Watch <span>Movies</span>
                  </h4>
                  <p>
                  From all devices you have, from anywhere in the world just access this app and watch movie
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("submityourown.png")}
                    alt="Submit your Own Movie"
                  />
                  <h4>
                    <span>Submit</span> your <br />
                    Own Movie
                  </h4>
                  <p>
                   For film makers all over the world, if you think your content is worth to watch, you can stream with our platform.We can buy or rent your movies.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("languagesorting.png")}
                    alt="Language and Genre Sorting"
                  />
                  <h4>
                    Language <br />
                    and Genre <span>Sorting</span>
                  </h4>
                  <p>
                  Very easy to access, use filters to search languages , genre, actors and directors.Time is valuable.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("choosevideoquality.png")}
                    alt="Choose Video Quality"
                  />
                  <h4>
                    Choose Video <br />
                    <span>Quality</span>
                  </h4>
                  <p>
                  You can choose from  high quality like 4K to low quality.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 p-0">
                <div className="features-col">
                  <img
                    src={common.loadImg("dainapictureoriginals.png")}
                    alt="Cinebazzar OTT Originals"
                  />
                  <h4>
                  Cinebazzar OTT | Digital Theater <br />
                    <span>Originals</span>
                  </h4>
                  <p>
                  We have a bunch of young talented directors, technicians and actors to provide excellent films for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export { UserInfo };
