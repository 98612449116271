import React, { Component } from "react";
import { common, history } from "../_helpers";
import { apiServices } from "../api";
import { GoogleLogout } from "react-google-login";
import { envConfig } from "../api/config";
import { Link } from "react-router-dom";
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
var jwt = require("jsonwebtoken");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSocialLogin: localStorage.getItem("socialLogin"),
      isCollapsed: false,
      roles: null,
      value: "",
      enableSearch: false,
      movieData: [],
      autoloading: false,
      autoFields: {
        movieSearchTerm: "",
      },
      items: [
        {
          id: 0,
          name: "Cobol",
        },
        {
          id: 1,
          name: "JavaScript",
        },
        {
          id: 2,
          name: "Basic",
        },
        {
          id: 3,
          name: "PHP",
        },
        {
          id: 4,
          name: "Java",
        },
      ],
    };
  }

  componentDidMount() {
    if (common.isAuth()) {
      let userData = localStorage.getItem("userToken");
      let decodeData = jwt.decode(userData);
      this.setState({
        roles: decodeData.roles,
      });
    }
  }

  // Logout user
  logoutUser = () => {
    var rememberMe = localStorage.getItem("rememberMe");
    localStorage.clear();
    localStorage.setItem("rememberMe", rememberMe);
    common.redirect("/");
  };

  logout = (event) => {
    // this.logoutUser();
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  handleInputChange = (event, terms = "") => {
    const { autoFields } = this.state;

    autoFields["movieSearchTerm"] = terms;
    this.setState({
      autoloading: true,
      movieData: [],
      autoFields: autoFields,
    });
    let data = {};
    if (common.isAuth()) {
      data = {
        url: "GET_SEARCH_MOVIES",
        query: "?search=movieNameLIKE=" + terms + "&page=0&size=10",
        method: "GET",
      };
    } else {
      data = {
        url: "PUBLIC_SEARCH_MOVIES",
        query: "?search=movieNameLIKE=" + terms + "&page=0&size=10",
        method: "GET",
        authorization: "none",
      };
    }

    apiServices.call(
      data,
      (response) => {
        this.setState({
          movieData: response.data.content ? response.data.content : [],
          autoloading: false,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    let MovieId = selectedValue.movieId;
    let MovieKey = selectedValue.movieKey;
    let MovieName = selectedValue.movieName;
    this.setState(
      {
        MovieId: MovieId,
      },
      () => {
        this.gotoMovies(MovieId, MovieKey, MovieName);
      }
    );
  };
  toggleMenu = () => {
    const { isCollapsed } = this.state;
    this.setState({
      isCollapsed: !isCollapsed,
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  gotoMovies(id, key, name) {
    if (common.isAuth()) {
      common.redirect("/movies-details/" + id);
    } else {
      var movieName = name.replace(/[^A-Z0-9]+/gi, "-");
      common.redirect("/v/" + movieName.toLowerCase() + "/" + key);
    }
  }

  showSearch = () => {
    this.setState({ enableSearch: true });
  };

  render() {
    const {
      isCollapsed,
      roles,
      value,
      items,
      enableSearch,
      movieData,
      autoFields,
      autoloading,
    } = this.state;
    const inputProps = {
      placeholder: "Search",
      value,
      onChange: this.onChange,
    };
    return (
      <header
        className={
          history.location.pathname === "/signup" ? "header" : "header-inner"
        }
        id={history.location.pathname === "/" ? "headerHome" : "header"}
      >
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md navbar-dark">
            <div className="header-logo mx-auto order-0">
              <Link to="/" className="navbar-brand mx-auto">
                <img
                  src={
                    history.location.pathname === "/signup"
                      ? common.loadImg("headerlogo.png")
                      : common.loadImg("headerlogo.png")
                  }
                  alt="Cinebazzar OTT | Digital Theater"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target=".dual-collapse2"
                onClick={() => this.toggleMenu()}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className={
                isCollapsed
                  ? "navbar-collapse enu-wrap w-100 order-1 order-md-0 dual-collapse2"
                  : "collapse navbar-collapse menu-wrap w-100 order-1 order-md-0 dual-collapse2"
              }
              id="navbarMenu"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <div className="serchMovieIcon">
                    {enableSearch && (
                      <AutoComplete
                        freeSolo
                        id="MovieId"
                        disableClearable
                        options={movieData}
                        loading={autoloading}
                        getOptionLabel={(option) => option.movieName}
                        onChange={this.handleAutoChange}
                        inputValue={autoFields.movieSearchTerm}
                        onInputChange={this.handleInputChange}
                        // className="searchBar"
                        style={{ width: "300px", height: "1px" }}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop: 5 }}>{children}</Paper>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={'Search Movie'}
                            placeholder="Search Movie"
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              endAdornment: (
                                <React.Fragment>
                                  {autoloading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={15}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                    <img
                      src={common.loadImg("search.png")}
                      alt="Search"
                      className="searchImgSet"
                      onClick={() =>
                        this.setState({ enableSearch: !enableSearch })
                      }
                    />
                  </div>
                  {/* <Link className="nav-link icon-search" to="/">
                    <img src={common.loadImg("search.png")} alt="Search" />
                  </Link> */}
                </li>
                <li
                  // className="nav-item"
                  className={history.location.pathname === "/" ? "active" : ""}
                >
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                {common.isAuth() && (
                  <li
                    // className="nav-item"
                    className={
                      history.location.pathname === "/upcoming" ? "active" : ""
                    }
                  >
                    <Link className="nav-link" to="/upcoming">
                      Your Videos
                    </Link>
                  </li>
                )}
                <li
                  // className="nav-item"
                  className={
                    history.location.pathname === "/movies" ? "active" : ""
                  }
                >
                  <Link className="nav-link" to="/movies">
                    Movies
                  </Link>
                </li>
                <li
                  // className="nav-item"
                  className={
                    history.location.pathname === "/movies/shortfilms"
                      ? "active"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/movies/shortfilms">
                    Short Films
                  </Link>
                </li>
                {/* <li 
                className="nav-item"
                className={
                  history.location.pathname === "/movies/webseries" ? "active" : ""
                }
                >
                  <Link className="nav-link" to="/movies/webseries">
                    Web Series
                  </Link>
                </li> */}
              </ul>

              <ul className="navbar-nav ml-3 mr-3">
                {common.isAuth() ? (
                  <li className="nav-item">
                    {/* { this.state.isSocialLogin == "google" ? 
                    <GoogleLogout
                    clientId={envConfig.GOOGLE_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.logoutUser}
                    onFailure={this.logoutUser}
                  >
                  </GoogleLogout> 
                  : */}
                    <Link to="/profile">
                      <img src={common.loadImg("profile-icn.png")} alt="" />
                    </Link>
                    {/* }  */}
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="btn btn-primary btn-signin" to="/signup">
                      Sign in
                    </Link>
                  </li>
                )}
                {roles && roles.includes("Producer") && (
                  <li className="nav-item">
                    <a className="nav-link" href="/producer/channels">
                      Dashboard
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export { Header };
