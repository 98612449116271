import React, { Component } from "react";
import "../css/Film.css";
import { Button, Form, Col } from "react-bootstrap";

class Step2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                name: {
                    error: '',
                    isReq: "Name is required",
                },
                email: {
                    error: '',
                    isReq: 'Email Address is required'
                },
                telephone: {
                    error: '',
                    isReq: 'Telephone Number is required'
                },
            },
            fields: {
                name: {
                    criteria: {required: true},
                    value: '',
                    label: 'Name'
                },
                email: {
                    criteria: {required: true},
                    value: '',
                    label: 'Email Address'
                },
                telephone: {
                    criteria: {required: true},
                    value: '',
                    label: 'Telephone'
                },
            },
            isLoading: false,
            isFormSubmit: false,
            agreed: false,
            keyboardHeight: 0,
            location: {},
            // birthDate: new Date(),
            currentPosition: 0,
            }
        }
        
        componentDidMount = async (_) => {
            const { fields } = this.state;
            let stepTwo = localStorage.getItem("stepTwoDetails");            
            if (stepTwo) {
                let stepTwoDetails = JSON.parse(stepTwo);
                fields.name.value = stepTwoDetails.contactName;
                fields.email.value = stepTwoDetails.contactEmail;
                fields.telephone.value = stepTwoDetails.contactPhone;
        
                this.setState({
                  fields,
                })
              }
        }
        

        onChange = async (event) => {
            const { name, value } = event.target;
            const { fields } = this.state;
            fields[name].value = value;
            this.setState(
              {
                fields: fields,
              },
              () => {
                this.validateField(name, value);
              }
            );
          };
          
           // validation
           validateField = (name, value) => {
            const { errors, fields } = this.state;
            if (errors.hasOwnProperty(name)) {
              if (!value) {
                errors[name].error = errors[name].isReq;
              } else {
                errors[name].error = "";
              }
            }
          
            this.setState({
              errors: errors,
            });
          };
          
          validateForm() {
            const { fields, errors } = this.state;
            let isValid = true;
            for (var key of Object.keys(errors)) {
              if (fields[key].value == "" || fields[key].value == null) {
                errors[key].error = errors[key].isReq;
                isValid = false;
              }
            }
            return isValid
          }

          goToNext = () => {
            const {fields } = this.state;
            this.setState({ formSubmit: true });
            let isValid = this.validateForm();
            if (isValid) {
                let stepTwoDetails = {
                 contactName: fields.name.value,
                 contactEmail: fields.email.value,
                 contactPhone: fields.telephone.value
               }
                localStorage.setItem("stepTwoDetails", JSON.stringify(stepTwoDetails))
                this.props.goNext();
            }
          };

          goToBack = () => {
            const{ fields } = this.state;
            let stepTwoDetails = {
              contactName: fields.name.value,
              contactEmail: fields.email.value,
              contactPhone: fields.telephone.value
            }
            localStorage.setItem("stepTwoDetails", JSON.stringify(stepTwoDetails))
            this.props.goBack();
          };

    render() {
        const { errors, fields, formSubmit } = this.state;
        return (
            <div className="step1">
                <h4 className="film-heading">CONTACT PERSON</h4>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.name.label}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"name"}
                            placeholder={fields.name.label}
                            value={fields.name.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.name.error !== "" && formSubmit && (
                              <span className="rt-submit-err">
                              {errors.name.error}
                             </span>
                            )}
                    </Form.Group>
                    </Form.Row>

                    <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.email.label}
                            </Form.Label>
                        <Form.Control
                            type="text"
                            name={"email"}
                            placeholder={fields.email.label}
                            value={fields.email.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.email.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                               {errors.email.error}
                              </span>
                            )}
                    </Form.Group>
                </Form.Row>
               
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="step1-label">
                            <span>*</span>{fields.telephone.label}
                            </Form.Label>
                        <Form.Control
                            type="number"
                            name={"telephone"}
                            placeholder={fields.telephone.label}
                            value={fields.telephone.value}
                            onChange={(e) => this.onChange(e)}
                        />
                        {errors.telephone.error !== "" && formSubmit && (
                               <span className="rt-submit-err">
                               {errors.telephone.error}
                              </span>
                            )}
                    </Form.Group>
                </Form.Row>
                    <div>
                        <Button
                            className="back-btn"
                            onClick={() =>
                                this.goToBack()
                            }
                        >
                           {"<"} Back
                        </Button>
                        <Button
                            className="continue-btn"
                            onClick={() =>
                                this.goToNext()
                            }
                        >
                            Continue
                        </Button>
                    </div>
            </div>
        )
    }
}

export { Step2 }