import React from "react";
import { Container, Row, Col, Form, Media } from "react-bootstrap";
import { common, history, tools } from "../../../_helpers";
import { Sidebar } from "../../../_layouts/sidebar";
import { ProfileHeader } from "../../../_layouts/profileHeader";
import { Header } from "../../../_layouts/header";
// Import Snackbar utils
import { Helmet } from "react-helmet";
import { apiServices } from "../../../api";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        newPassword: "",
        confirmPassword: "",
      },
      formSubmit: false,
      errors: {
        newPassword: {
          error: "",
          isReq: "Password is required!",
          minReq: "Password must be at least 6 characters",
        },
        confirmPassword: {
          error: "",
          isReq: "Confirm Password is required!",
          minReq: "Confirm Password must be at least 6 characters",
          matchField: "Confirm password should match with Password",
        },
      },
      isLoading: false,
    };
  }

  loader(status) {
    this.setState({
      isLoading: status,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { fields } = this.state;
    fields[name] = value;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  // validation
  validateField = (name, value) => {
    const { errors, fields } = this.state;
    if (errors.hasOwnProperty(name)) {
      if (!value) {
        errors[name].error = errors[name].isReq;
      } else {
        errors[name].error = "";
      }
    }

    // unique validation
    if (name == "confirmPassword") {
      if (value.length < 6) {
        errors[name].error = errors[name].minReq;
      } else if (value != fields["newPassword"]) {
        errors[name].error = errors[name].matchField;
      }
    } else if (name == "newPassword" && value != "") {
      if (value.length < 6) {
        errors[name].error = errors[name].minReq;
      } else if (
        fields["confirmPassword"].length > 5 &&
        fields["confirmPassword"] === value
      ) {
        errors["confirmPassword"].error = "";
      }
    }

    this.setState({
      errors: errors,
    });
  };

  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }

    // unique validation
    let password = fields["newPassword"];
    let confirmPassword = fields["confirmPassword"];

    if (password != "") {
      if (password.length < 6) {
        errors["newPassword"].error = errors["newPassword"].minReq;
        isValid = false;
      }
    }

    if (confirmPassword != "") {
      if (confirmPassword.length < 6) {
        errors["confirmPassword"].error = errors["confirmPassword"].minReq;
        isValid = false;
      } else if (confirmPassword != fields["newPassword"]) {
        errors["confirmPassword"].error = errors["confirmPassword"].matchField;
        isValid = false;
      }
    }

    this.setState({
      errors,
    });
    return isValid;
  }

  onSubmit = () => {
    this.setState({ formSubmit: true });
    let isValid = this.validateForm();
    if (isValid) {
      this.resetPassword();
    }
  };

  resetPassword = async (e) => {
    this.setState({
      isLoading: true,
    });
    const { fields } = this.state;
    let userData = JSON.parse(localStorage.getItem("user"));
    let param = {
      username: userData.data.email,
      password: fields.newPassword,
      confirmPassword: fields.confirmPassword,
    };

    var data = {
      url: "RESETPASSWORD",
      body: param,
      method: "POST",
    };

    await apiServices.call(
      data,
      (response) => {
        if (response.status === 200) {
          common.snack("S", "Password reset Successfully!");
          //reset passowrd
          fields["newPassword"] = "";
          fields["confirmPassword"] = "";
          this.setState({
            fields: fields,
            formSubmit: false,
          });
        }
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          isLoading: false,
        });
      },
      (final) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  render() {
    const { isLoading, fields, formSubmit, errors } = this.state;
    return (
      <div>
        <Header />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="profileContent_detail">
                  <div className="myProfile_header">
                    <Row>
                      <Col md={9}>
                        <h3>Reset Password</h3>
                      </Col>
                    </Row>
                  </div>
                  <div className="editForm">
                    <Row>
                      <Col md={12}>
                        <Form>
                          <Form.Group>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              value={fields.newPassword}
                              name="newPassword"
                              onChange={this.handleChange}
                            />
                            {errors.newPassword.error != "" && formSubmit && (
                              <span className="rt-sign-err">
                                {errors.newPassword.error}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              value={fields.confirmPassword}
                              name="confirmPassword"
                              onChange={this.handleChange}
                            />
                            {errors.confirmPassword.error != "" &&
                              formSubmit && (
                                <span className="rt-sign-err">
                                  {errors.confirmPassword.error}
                                </span>
                              )}
                          </Form.Group>
                          <button
                            type="button"
                            className="btn-submit btn-rate up-btn"
                            onClick={() => this.onSubmit()}
                            disabled={isLoading}
                          >
                            {!isLoading ? "Submit" : "Processing..."}
                          </button>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { ResetPassword };
