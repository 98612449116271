import React from "react";
import { Header } from "../../../../../_layouts/header";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  FormControl,
  Form,
} from "react-bootstrap";
import { ProfileHeader } from "../../../../../_layouts/profileHeader";
import { Sidebar } from "../../../../../_layouts/sidebar";
import { common } from "../../../../../_helpers";
import { Helmet } from "react-helmet";
// import {MyLocation} from './MyLocation';
class SettingsLanguage extends React.Component {
  render() {
    return (
      <div>
        <Header />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="setting_language profileContent_detail">
                  <div className="myProfile_header">
                    <h3>Settings</h3>
                  </div>
                  <h4>Language</h4>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Search language"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                      <Button>
                        {" "}
                        <img
                          src={common.loadImg("lan_search.png")}
                          alt="profile"
                        />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <ul>
                    <li>
                      <p>Arabic</p>
                      <Form.Check
                        type="checkbox"
                        id="customControlInline1"
                        label=""
                        custom
                      />
                    </li>
                    <li>
                      <p>Tamil</p>
                      <Form.Check
                        type="checkbox"
                        id="customControlInline2"
                        label=""
                        custom
                      />
                    </li>
                    <li>
                      <p>English</p>
                      <Form.Check
                        type="checkbox"
                        id="customControlInline3"
                        label=""
                        custom
                      />
                    </li>
                    <li>
                      <p>Spanish</p>
                      <Form.Check
                        type="checkbox"
                        id="customControlInline4"
                        label=""
                        custom
                      />
                    </li>
                    <li>
                      <p>French</p>
                      <Form.Check
                        type="checkbox"
                        id="customControlInline5"
                        label=""
                        custom
                      />
                    </li>
                    <li>
                      <p>German</p>
                      <Form.Check
                        type="checkbox"
                        id="customControlInline6"
                        label=""
                        custom
                      />
                    </li>
                  </ul>
                  <div>
                    <Button className="go-back-btn">Go Back</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { SettingsLanguage };
