import React, { Component } from "react";
import "../css/Upcoming.css";

import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";

import { apiServices } from "../../../api";

// package
import moment from "moment";
import { Helmet } from "react-helmet";
import { envConfig } from "../../../api/config";
import { StatusBar } from "../../../_includes";

class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicUpcomingMovieList: [],
      status: "process",
      paging: {
        page: 1,
        size: 12, // 20
        total: 1,
      },
    };
  }

  componentDidMount() {
    this.getMovies();
  }

  getMovies = () => {
    const { paging, publicUpcomingMovieList } = this.state;
    let data = {
      url: "PUBLIC_UPCOMING_MOVIES",
      query:
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=streamingStartDate,desc",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        let movieList = [];
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            publicUpcomingMovieList.push(item);
          });
          movieList = this.groupMovies(publicUpcomingMovieList, "releaseMonth");
          // paging
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          movieList,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  groupMovies = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        status: "process",
        paging,
      },
      () => {
        if (paging.total > 1) {
          this.getMovies();
        }
      }
    );
  }

  render() {
    const { publicUpcomingMovieList, paging, status } = this.state;
    return (
      <div>
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Upcoming List</title>
          <meta
            name="description"
            content="Watch new movies releasing on Cinebazzar OTT | Digital Theater on your favorite devices from anywhere with internet"
          />
        </Helmet>
        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">UPCOMING</h1>
            {/* <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Upcoming</li>
            </ul> */}
          </div>

          <StatusBar page="movie" loader="small" status={status} />
            {publicUpcomingMovieList && status == "success" && publicUpcomingMovieList.length == 0 && (
              <StatusBar page="movie" loader="small" status={"noData"} />
            )}

          <ul className="movies-list row mb-3">
            {publicUpcomingMovieList &&
              publicUpcomingMovieList.map((movies, key) => {
                return (
                  <li className="col-md-3" key={key}>
                    <a href={"/v/"  + ((movies.movieName).replace(/[^A-Z0-9]+/ig, "-")).toLowerCase() + "/" + movies.movieKey}>
                      <img
                        src={common.loadCloudImg(movies.moviePoster)}
                        alt="Movie"
                      />

                      <div className="movie-details">
                        <h3 className="movie-name">
                          {movies.movieName}
                        </h3>
                        <p className="movie-date">
                          {moment
                            .utc(movies.releaseDate, "DD-MM-YYYY HH:mm:ss")
                            .local()
                            .format("DD MMM YYYY")}
                        </p>
                      </div>
                    </a>
                  </li>
                );
              })}
          </ul>
          {paging.total > 1 && paging.total != paging.page && (
            <div className="load-more mb-5 text-center">
              <a className="read-more text-uppercase">
                <span onClick={(e) => this.pagination(e)}>Load More</span>
              </a>
            </div>
          )}

          {/* <div className="section-title d-flex justify-content-between">
            <h2 className="sub-title">We Recommend</h2>
            <a href="#" className="read-more mt-2 text-uppercase">
              More
            </a>
          </div>

          <ul className="upcoming-list row mb-5">
            {publicUpcomingMovieList &&
              publicUpcomingMovieList.map((movies, key) => {
                return (
                  <li className="col-md-2 mb-3" key={key}>
                    <a href={"/v/"  + ((movies.movieName).replace(/[^A-Z0-9]+/ig, "-")).toLowerCase() + "/" + movies.movieKey}>
                      <img
                        src={common.loadCloudImg(movies.thumbnail)
                          // movies.moviePoster
                          //   ? movies.moviePoster.includes("https://")
                          //     ? movies.moviePoster
                          //     : envConfig.IMAGE_URL +
                          //       movies.moviePoster
                          //   : ""
                        }
                        alt="Movie Poster"
                      />
                      <div className="movie-details">
                        <h5 className="movie-name text-capitalize">
                          {movies.movieName}
                        </h5>
                        <h6 className="movie-date">
                          {moment
                            .utc(movies.releaseDate, "DD-MM-YYYY HH:mm:ss")
                            .local()
                            .format("DD MMM YYYY")}
                        </h6>
                      </div>
                    </a>
                  </li>
                );
              })}
          </ul> */}
        </div>
      </div>
    );
  }
}

export { Upcoming };
