import React from "react";
import { apiServices } from "../../../api";
import { common, message } from "../../../_helpers";
import { Container, Row, Col, ListGroup, Media, Button, Modal } from "react-bootstrap";
import moment from "moment";
import { StatusBar } from "../../../_includes";
import { envConfig } from "../../../api/config";

class MovieOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      orderDetails: [],
      status: "process",
    };
  }

  getOrderDetails(movieId) {
    try {
      this.setState({
        status: "process",
      });
      let data = {
        url: "GET_MY_MOVIE_ORDERS",
        query: "/" + movieId,
        method: "GET",
      };
      apiServices.call(
        data,
        (response) => {
          this.setState({
            orderDetails: response.data,
            modal: true,
            status: "success",
          });
        },
        (error) => {
          common.snack("E", message.somthing_wrong);
          this.setState({
            modal: false,
          });
        },
        (final) => {
          this.props.success();
        }
      );
    } catch (e) {
      common.snack("E", e.toString());
    }
  }

  closeModal() {
    this.setState({
      orderDetails: [],
      modal: false,
    });
  }

  render() {
    const { modal, status, orderDetails } = this.state;

    return (
      <div>
        {/* Review List Popup */}
        <Modal
          show={modal}
          size="lg"
          className="movie-songs-modal"
          onHide={() => this.closeModal()}
        >
          <Modal.Header>
            <Modal.Title className="socialShareTitle">
              Booking Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StatusBar page="review" loader="small" status={status} />
            {status == "success" &&
              (orderDetails == "" ? (
                <StatusBar page="review" type="reviewList" status={"noData"} />
              ) : (
                <div>
                  <ListGroup className="historyTicket_detail">
                    {orderDetails.content.map((item, idx) => (
                      <ListGroup.Item key={idx} className="rt-cp">
                        <Row>
                          <Col md={9}>
                            <Media>
                              <img
                              src={
                                item.orderMovie && item.orderMovie.moviePoster
                                  ? item.orderMovie.moviePoster.includes("https://")
                                    ? item.orderMovie.moviePoster
                                    : envConfig.IMAGE_URL +
                                    item.orderMovie.moviePoster
                                  : ""
                              }
                                // src={
                                //   item.orderMovie && item.orderMovie.moviePoster
                                //     ? item.orderMovie.moviePoster
                                //     : null
                                // }
                                alt="image"
                                className="purchase_img"
                              />
                              <Media.Body>
                                <h5>
                                  {item.orderMovie && item.orderMovie.movieName
                                    ? item.orderMovie.movieName + item.orderNo
                                    : "-"}
                                </h5>
                                <ul>
                                  <li>
                                    <p>
                                      {item.orderMovie &&
                                      item.orderMovie.primaryLanguage
                                        ? item.orderMovie.primaryLanguage
                                        : "-"}{" "}
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      {item.bookingStartTime
                                        ? moment
                                            .utc(
                                              item.bookingStartTime,
                                              "DD-MM-YYYY HH:mm:ss"
                                            )
                                            .local()
                                            .format("DD MMM, YYYY | hh:mm A")
                                        : "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <p
                                      className={
                                        item.paymentStatus === "PAID"
                                          ? "payment-status success"
                                          : "payment-status danger"
                                      }
                                    >
                                      {item.paymentStatus}
                                    </p>
                                  </li>
                                  {item.paymentStatus === "PAID" && (
                                    <li>
                                      <a
                                        href={
                                          "/movies-details/" +
                                          (item.orderMovie &&
                                          item.orderMovie.movieId
                                            ? item.orderMovie.movieId
                                            : 0)
                                        }
                                        className="nav-movie"
                                      >
                                        {" "}
                                        <img
                                          src={common.loadImg("play_icon.png")}
                                          alt="image"
                                          className={"play-image"}
                                        />
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </Media.Body>
                            </Media>
                          </Col>
                          <Col md={3} className="text-md-right">
                            <p>
                              {item.orderDate
                                ? moment
                                    .utc(item.orderDate, "DD-MM-YYYY HH:mm:ss")
                                    .local()
                                    .format("DD MMM")
                                : "-"}
                            </p>
                            <h3>
                              ₹
                              {item.totalRoundedValue}
                            </h3>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              ))}
          </Modal.Body>
          <Modal.Footer className="SocialShareFooter">
            <div className="modal-footer-wrap">
              <button
                onClick={() => this.closeModal()}
                className="btn btn-bordered rt-ml-5"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default MovieOrders;
