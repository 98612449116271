import React from "react";
import { Container, Row, Col, Form, Media } from "react-bootstrap";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import { common, history } from "../../../_helpers";
import { Sidebar } from "../../../_layouts/sidebar";
import { ProfileHeader } from "../../../_layouts/profileHeader";
import { Header } from "../../../_layouts/header";
// Import Snackbar utils
import { Helmet } from "react-helmet";
import { apiServices } from "../../../api";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      firstName: "",
      emailId: "",
      mobile: "",
      gender: "",
      userId: "",
      isLoading: false,
      errors: {
        firstName: {
          error: "",
          isReq: "Name is required!",
        },
      },
    };
  }

  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("user"));
    let firstName = userData.data && userData.data.firstName ? userData.data.firstName : "";
    let emailId =  userData.data && userData.data.email ? userData.data.email : userData.data && userData.data.emailId ? userData.data.emailId : ""
    let mobile = userData.data && userData.data.mobile ? userData.data.mobile : "";
    let gender =userData.data && userData.data.gender ? userData.data.gender: "";
    let userId = userData.data && userData.data.userId ? userData.data.userId : "";
    this.setState({ firstName, emailId, mobile, gender, userId });
  }

  loader(status) {
    this.setState({
      isLoading: status,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  // validation
  validateField = (name, value) => {
    const { errors } = this.state;
    if (errors.hasOwnProperty(name)) {
      if (!value) {
        errors[name].error = errors[name].isReq;
      } else {
        errors[name].error = "";
      }
    }

    this.setState({
      errors: errors,
    });
  };

  validateForm() {
    const { firstName, errors } = this.state;
    let isValid = true;
    if (firstName == "" || firstName == null) {
      errors["firstName"].error = errors["firstName"].isReq;
      isValid = false;
    }
    return isValid;
  }
  // rest services
  onSubmit() {
    this.setState({ formSubmit: true });
    let isValid = this.validateForm();
    if (isValid) {
      this.editProfile();
    }
  }

  async editProfile() {
    let userData = JSON.parse(localStorage.getItem("user"));

    this.setState({
      isLoading: true,
    });
    const { firstName, mobile, gender, userId, emailId } = this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : null;
    let location = await common.myLocation();

    let param = {
      firstName: firstName,
      mobile: mobile,
      gender: gender,
      emailId: emailId,
      ipAddress: location ? location.ip : null,
      timezone: timezone,
    };

    if (location.location) {
      param["latitude"] = location.location.lat;
      param["longitude"] = location.location.lng;
    }

    var data = {
      url: "UPDATE_PROFILE",
      query: userId,
      body: param,
      method: "PUT",
    };

    userData["mobile"] = mobile;
    userData["gender"] = gender;
    userData["firstName"] = firstName;

    apiServices.call(
      data,
      (response) => {
        if (response.status === 202) {
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("userData.firstName", firstName);
          common.getUserDetails();
          common.snack("S", "Profile Updated Successfully!");
          common.redirect("/profile");
        }
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          isLoading: false,
        });
      },
      (final) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  render() {
    const {
      isLoading,
      firstName,
      emailId,
      mobile,
      gender,
      formSubmit,
      errors,
    } = this.state;
    return (
      <div>
        <Header />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ProfileHeader />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <Row className="m-0">
                <Col md={3} className="sidebar_bg">
                  <Sidebar />
                </Col>
                <Col md={9} className="profileContent_detail">
                  <div className="myProfile_header">
                    <Row>
                      <Col md={9}>
                        <h3>Edit Profile</h3>
                      </Col>
                    </Row>
                  </div>
                  <div className="editForm">
                    <Row>
                      {/* <Col md={12}>
                        <img src={common.loadImg("profile_1.png")} alt="profile" 
                          className="profile_image mr-3" />
                      </Col> */}
                      <Col md={12}>
                        <Form>
                          <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={firstName}
                              name="firstName"
                              onChange={this.handleChange}
                            />
                            {errors.firstName.error != "" && formSubmit && (
                              <span className="rt-sign-err">
                                {errors.firstName.error}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              value={emailId}
                              name="email"
                              onChange={this.handleChange}
                              disabled
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="text"
                              value={mobile}
                              name="mobile"
                              onChange={this.handleChange}
                            />
                          </Form.Group>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                value="0"
                                name="gender"
                                onChange={this.handleChange}
                                checked={gender === "0"}
                              />{" "}
                              Male
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                value="1"
                                name="gender"
                                onChange={this.handleChange}
                                checked={gender === "1"}
                              />{" "}
                              Female
                            </Label>
                          </FormGroup>
                          <button
                            type="button"
                            className="btn-submit btn-rate up-btn"
                            onClick={() => this.onSubmit()}
                            disabled={isLoading}
                          >
                            {!isLoading ? "Update" : "Processing..."}
                          </button>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { EditProfile };
