import React, { Component } from "react";
import "../css/Movies.css";

import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import { apiServices } from "../../../api";
import { Multiselect } from "multiselect-react-dropdown";
// package
import moment from "moment";
import { Helmet } from "react-helmet";
import { envConfig } from "../../../api/config";

import { ComingSoon } from "../../../_layouts/ComingSoon";

class ShortFilms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicShortFilmAllList: [],
      status: "process",
      paging: {
        page: 1,
        size: 12, // 20
        total: 1,
      },
      languageList: [],
      genreList: [],
      selectedLanguages: [],
      selectedGenres: [],
      isRating: false,
      isDate: false,
      publicUpcomingShortFilms: [],
    };
  }

  componentDidMount() {
    this.getShortFilms();
    this.getLanguage();
    this.getGenre();
  }

  getShortFilms = () => {
    const { paging, publicShortFilmAllList } = this.state;
    let data = {
      url: "PUBLIC_SEARCH_MOVIES",
      query:
        "?search=active:Y,movieType:SHORTS" +
        "&page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "",
      method: "GET",
    };

    apiServices.call(
      data,
      (response) => {
        let shortFilmList = [];
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            publicShortFilmAllList.push(item);
          });
          shortFilmList = this.groupShortFilms(
            publicShortFilmAllList,
            "releaseMonth"
          );
          // paging
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          publicShortFilmAllList: response.data.content,
          shortFilmList,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  groupShortFilms = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        status: "process",
        paging,
      },
      () => {
        this.getShortFilms();
      }
    );
  }

  getLanguage = () => {
    let data = {
      url: "PUBLIC_LANGUAGE",
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        let language = [];
        response.data.content.length > 0 &&
          response.data.content.map((item) => {
            let data = {
              name: item.languageName,
              id: item.languageId,
            };
            language.push(data);
          });
        this.setState({
          languageList: language,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  getGenre = () => {
    let data = {
      url: "PUBLIC_GENRE",
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        let genre = [];
        response.data.content.length > 0 &&
          response.data.content.map((item) => {
            let data = {
              name: item.genreName,
              id: item.genreId,
            };
            genre.push(data);
          });
        this.setState({
          genreList: genre,
        });
      },
      (error) => {},
      (final) => {}
    );
  };

  onLanguageSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedLanguages: selectedList,
    });
  };

  onLanguageRemove = (selectedList, removedItem) => {
    this.setState(
      {
        selectedLanguages: selectedList,
      },
      (_) => {
        // if(this.state.selectedLanguages.length == 0){
        //   this.getShortFilms();
        // }
      }
    );
  };

  onGenreSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedGenres: selectedList,
    });
  };

  onGenreRemove = (selectedList, removedItem) => {
    this.setState(
      {
        selectedGenres: selectedList,
      },
      (_) => {
        // if(this.state.selectedLanguages.length == 0){
        //   this.getShortFilms();
        // }
      }
    );
  };

  filterMovies = (e) => {
    e.preventDefault();
    const {
      selectedLanguages,
      selectedGenres,
      publicShortFilmAllList,
      paging,
      isRating,
      isDate,
    } = this.state;
    let language = "";
    let genre = "";
    selectedLanguages &&
      selectedLanguages.length > 0 &&
      selectedLanguages.map((item) => {
        language += item.name + ";";
      });
    selectedGenres &&
      selectedGenres.length > 0 &&
      selectedGenres.map((item) => {
        genre += item.id + ";";
      });
    // this.setState({
    //     isLoading: true,
    // });
    let url = "active!N";
    if (isDate) {
      url += ",releaseDate-";
    }

    if (isRating) {
      url += ",avgRating-";
    }

    if (language !== "") {
      url += ",primaryLanguageLISTSTR=" + language;
    }

    if (genre !== "") {
      url += ",movieGenresDSgenreIdLIST=" + genre;
    }

    if (!isDate && !isRating && language == "" && genre == "") {
      url += "movieId>0,releaseDate-";
    }

    var data = {
      url: "PUBLIC_FITER_MOVIES",
      query:
        url +
        ",movieType:SHORTS&page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size,
      body: null,
      authorization: "none",
      method: "GET",
    };
    apiServices.call(
      data,
      async (response) => {
        if (response.error) {
          // console.log("========error", response);
        } else {
          let shortFilmList = [];
          // this.setState({
          //   publicShortFilmAllList: []
          // })
          // if (response.data.totalElements > 0) {
          //   await response.data.content.map((item) => {
          //     publicShortFilmAllList.push(item);
          //   });
          // }
          //   shortFilmList = await this.groupShortFilms(publicShortFilmAllList, "releaseMonth");
          //   // paging
          //   paging.total = await Math.ceil(response.data.totalElements / paging.size);

          this.setState({
            publicShortFilmAllList: response.data.content,
            shortFilmList,
            status: "success",
          });
        }
      },
      (error) => {
        // common.snack("E", error);
      },
      (final) => {}
    );
  };

  setValues = (fields, value) => {
    const { isDate, isRating } = this.state;
    this.setState({
      [fields]: value,
    });
  };

  resetFilter = (e) => {
    this.setState({
      isDate: false,
      isRating: false,
      selectedGenres: [],
      selectedLanguages: [],
    });
    this.getShortFilms();
  };

  gotoMovies(e, id, key, name) {
    e.preventDefault();
    if (common.isAuth()) {
      common.redirect("/shortfilm-details/" + id);
    } else {
      var movieName = name.replace(/[^A-Z0-9]+/ig, "-");
      common.redirect("/v/" + movieName.toLowerCase() +"/" + key);
    }
  }

  goToUpcoming = (e) => {
    e.preventDefault();
    common.redirect("/upcoming");
  };

  render() {
    const {
      publicShortFilmAllList,
      languageList,
      genreList,
      isDate,
      isRating,
      publicUpcomingShortFilms,
      paging,
    } = this.state;
    return (
      <div className="cb-inner-page-wraper">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Short Films List</title>
          <meta
            name="description"
            content="Watch new short films releasing on Cinebazzar OTT | Digital Theater on your favorite devices from anywhere with internet"
          />
        </Helmet>
        <div className="container">
          {/* <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Short Films</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Short Films</li>
            </ul>
          </div> */}

          <div className="movie-filter-wrap mb-5 mt-5">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="filter-title">Sort by</h3>
                  <button
                    className={
                      "btn-sorting mr-3" + (isRating == true ? " active" : "")
                    }
                    onClick={() => this.setValues("isRating", !isRating)}
                  >
                    Rating
                  </button>
                  <button
                    className={
                      "btn-sorting" + (isDate == true ? " active" : "")
                    }
                    onClick={() => this.setValues("isDate", !isDate)}
                  >
                    Dates
                  </button>
                </div>
              </div>
              <div className="col-md-9">
                <form>
                  <div className="form-row row align-items-center justify-content-between">
                    <h3 className="filter-title col-md-2 text-right">
                      Filter by
                    </h3>
                    <div className="col-md-4">
                      <label className="sr-only" for="language">
                        Language
                      </label>
                      <Multiselect
                        options={languageList}
                        onSelect={this.onLanguageSelect}
                        onRemove={this.onLanguageRemove}
                        displayValue="name"
                        placeholder="Select Language"
                        style={{
                          chips: { background: "#fed30a", color: "black" },
                          searchBox: {
                            border: "1px #ffffff solid",
                            "border-radius": "5px",
                            color: "white",
                            background: "rgba(255, 255, 255, 0.5)",
                          },
                          option: {
                            color: "black",
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label class="sr-only" for="genre">
                        Genre
                      </label>
                      <Multiselect
                        options={genreList}
                        onSelect={this.onGenreSelect}
                        onRemove={this.onGenreRemove}
                        displayValue="name"
                        placeholder="Select Genre"
                        style={{
                          chips: { background: "#fed30a", color: "black" },
                          searchBox: {
                            border: "1px #ffffff solid",
                            "border-radius": "5px",
                            color: "white",
                            background: "rgba(255, 255, 255, 0.5)",
                          },
                          option: {
                            color: "black",
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn-apply mr-1"
                        onClick={(e) => this.filterMovies(e)}
                      >
                        Apply
                      </button>
                      <button
                        type="submit"
                        className="btn-apply"
                        onClick={(e) => this.resetFilter(e)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="section-title d-flex justify-content-between align-items-center">
            <h2>SHORT FILMS</h2>
          </div>

          <ul className="all-movies-list row mb-3">
            {publicShortFilmAllList &&
              publicShortFilmAllList.length > 0 &&
              publicShortFilmAllList.map((movie, key) => {
                return (
                  <li
                    className="col-md-3"
                    key={key}
                    onClick={(e) =>
                      this.gotoMovies(e, movie.movieId, movie.movieKey, movie.movieName)
                    }
                  >
                    <a
                      href="#"
                      // onClick={(e) =>
                      //   this.gotoMovies(e, movie.movieId, movie.movieKey)
                      // }
                      key={key}
                    >
                      <img
                        src={
                          movie.moviePoster
                            ? movie.moviePoster.includes("https://")
                              ? movie.moviePoster
                              : envConfig.IMAGE_URL + movie.moviePoster
                            : ""
                        }
                        alt="Movie"
                      />
                      <div className="movie-rating">
                        {movie.avgRating && movie.avgRating <= 5
                          ? movie.avgRating.toFixed(1)
                          : "-"}
                        /5
                      </div>

                      <div className="movie-detail">
                        <h3 className="movie-name">
                          {movie.movieName}
                        </h3>
                        <p className="movie-info info">
                          {moment
                            .utc(movie.releaseDate, "DD-MM-YYYY HH:mm:ss")
                            .local()
                            .format("DD MMM YYYY")}{" "}
                          | {movie.primaryLanguage} |{" "}
                          {movie.movieGenres.length > 0 &&
                            movie.movieGenres[0].genre &&
                            movie.movieGenres[0].genre.genreName}
                        </p>
                      </div>
                    </a>
                  </li>
                );
              })}
          </ul>
          {publicShortFilmAllList && publicShortFilmAllList.length == 0 && (
            <ComingSoon />
          )}

          {/* {paging.total > 1 && paging.total != paging.page &&
            <div className="load-more mb-5 text-center">
              <a className="read-more text-uppercase">
                <span onClick={(e) => this.pagination(e)}>
                  Load More
                </span>
              </a>
            </div>
          } */}
        </div>
      </div>
    );
  }
}

export { ShortFilms };
