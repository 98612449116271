import React, { Component } from "react";
/** Import React Slick Slider files */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/Movies.css";

import { Container, Modal, Button, Row, Col } from "react-bootstrap";

import { Header } from "../../../_layouts/header";
import { common, message, tools, constant } from "../../../_helpers";
import { apiServices } from "../../../api";

// Video

import { Replay } from "vimond-replay";
import "vimond-replay/index.css";
import Player from "../../TheoPlayer/TheoPlayer";

// AUdio Player
import ReactAudioPlayer from "react-audio-player";

// package
import moment from "moment";
import { Helmet } from "react-helmet";
import MovieOrders from "./MovieOrders";
import { Reviews } from "../../../_includes";
import ReadMoreAndLess from "react-read-more-less";
import StarRatings from "react-star-ratings";
import MetaTags from "react-meta-tags";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import ReactPlayer from "react-player";
import { StatusBar } from "../../../_includes";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";

class MovieDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movieId: this.props.match.params.movie_id || 0,
      movieDetails: [],
      isLoading: false,
      showVideoPlayer: false,
      videoUrl: null,
      isMovie: false,
      moviePlayId: null,
      isShow: false,
      watchNowModal: false,
      status: "process",
      isBookedLoading: false,
      source: {
        sources: [],
        movieId: null,
        isMovie: false,
        playId: "",
      },
      users: [],
      isViewMore: false,
      reviewList: [],
      isLoadMore: false,
      paging: {
        page: 1,
        size: 10,
        total: 1,
      },
      shareUrl: "",
      isYoutubeUrl: false,
      youtubeUrl: "",
      moviePlayId: "",
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      users: user,
    });
    this.getMovieDetails(this.state.movieId);
    // this.getPublicReviews(this.state.movieId);
    this.getMovieReviews("init");
  }

  getMovieDetails = (id) => {
    if (id) {
      let data = {
        url: "GET_MOVIE_INFO",
        query: id,
        method: "GET",
      };

      apiServices.call(
        data,
        (response) => {
          let title = response.data.movieName.replace(/[^A-Z0-9]+/gi, "-");
          let shareUrl =
            constant.shareUrl +
            title.toLowerCase() +
            "/" +
            response.data.movieKey;

          this.setState({
            movieDetails: response.data,
            status: "success",
            shareUrl: shareUrl,
          });
        },
        (error) => {
          common.snack("E", message.md_no_movies);
          common.redirect("/movies", 2);
        },
        (final) => {}
      );
    }
  };

  getPublicReviews = (id) => {
    const { paging, publicReviews } = this.state;
    if (id) {
      let data = {
        url: "GET_PUBLIC_REVIEWS",
        query:
          id +
          "?page=" +
          (paging.page - 1) +
          "&size=" +
          paging.size +
          "&sort=createdOn,desc",
        method: "GET",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.data.totalElements > 0) {
            response.data.content.map((item, key) => {
              publicReviews.push(item);
            });
            paging.total = Math.ceil(response.data.totalElements / paging.size);
          }
          this.setState({
            publicReviews,
            paging,
            status: "success",
            publicReviewsTotal: response.data.totalElements,
          });
        },
        (error) => {
          common.snack("E", message.somthing_wrong);
        },
        (final) => {}
      );
    }
  };

  getMovieReviews(type) {
    const { movieId, reviewList, paging } = this.state;
    var data = {
      url: "GET_PUBLIC_REVIEWS",
      query:
        "/" +
        movieId +
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=createdOn,desc",
      method: "GET",
    };
    apiServices.call(
      data,
      (response) => {
        if (response.data.totalElements > 0) {
          response.data.content.map((item, key) => {
            reviewList.push(item);
          });
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          reviewList,
          paging,
          status: "success",
          publicReviewsTotal: response.data.totalElements,
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {
        if (type == "load") {
          this.setState({ isLoadMore: false });
          tools.scrollEnd("movieReviews");
        }
      }
    );
  }

  pagination() {
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        isLoadMore: true,
        paging,
      },
      () => {
        this.getMovieReviews("load");
      }
    );
  }

  checkCopyrights = () => {
    this.setState({ watchNowModal: true });
  };

  async getMovieLink(id) {
    this.setState({
      watchNowModal: false,
    });

    if (id) {
      let ipResult = await common.myIp();
      let param = {
        movieId: id,
        ipAddress: ipResult.ip,
      };
      let data = {
        url: "GET_MOVIE_LINK",
        body: param,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status === 200) {
            this.playVideo(response.data[0].movieUrl, true, "movie");
          } else {
            common.snack(
              "E",
              response.data.error ? response.data.error : "An Error Occurred"
            );
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  }

  playVideo = (url, isMovie, playId) => {
    const { source, movieDetails, users } = this.state;
    // if (movieDetails.movieType !== "Trailer") {
    //   if (url.includes("https://www.youtube.com/")) {
    //     this.setState({
    //       isYoutubeUrl: true,
    //       youtubeUrl: url
    //     })
    //   } else {
    window.scrollTo(0, 0);
    // source.sources[0].src = url;
    if (typeof url === "object") {
      let movieData = [];
      url.map((item, idx) => {
        let obj = { src: item.movieUrl, type: item.contentType };
        movieData.push(obj);
      });
      source["sources"] = movieData;
    } else {
      let videoData = [];
      let videoObj = { src: url, type: "application/x-mpegurl" };
      videoData.push(videoObj);
      source["sources"] = videoData;
    }
    source.isMovie = isMovie;
    source.movieId = movieDetails.movieId;
    source.playId = playId + "_" + movieDetails.movieId;
    source.userId = users.data.userId;
    this.setState({
      // videoUrl: url,
      source,
      showVideoPlayer: true,
      isMovie: isMovie ? true : false,
    });
    // }
    // } else {
    //   this.setState({
    //     isYoutubeUrl: true,
    //     youtubeUrl: url
    //   })
    // }
  };

  closeVideo = () => {
    this.setState({
      videoUrl: null,
      showVideoPlayer: false,
    });
  };

  closeYoutubeVideo = () => {
    this.setState({
      youtubeUrl: null,
      isYoutubeUrl: false,
    });
  };

  // Songs Modal
  handleShow = () => {
    this.setState({ isShow: true });
  };

  handleClose = () => {
    this.setState({ isShow: false });
  };

  // goto
  gotoOrder() {
    const { movieId } = this.state;
    this.bookedLoad(true);
    this.MovieOrders.getOrderDetails(movieId);
  }

  // rating
  rateMovie() {
    const { movieDetails } = this.state;
    this.Reviews.writeReview(movieDetails);
  }

  reviewsList() {
    const { movieDetails } = this.state;
    this.Reviews.listReview(movieDetails);
  }

  bookedLoad(status) {
    this.setState({
      isBookedLoading: status,
    });
  }

  gotoTicketBooking = (e, id) => {
    e.preventDefault();
    if (common.isAuth()) {
      common.redirect("/movies/ticket-booking/" + id);
    } else {
      common.redirect("/signup");
    }
  };

  toggleCastCrew = () => {
    const { isViewMore } = this.state;
    this.setState({
      isViewMore: !isViewMore,
    });
  };

  getRatings(rating) {
    let ratingList = [];
    for (let i = 0; i < rating; i++) {
      ratingList.push(<span className="star-img" key={i}></span>);
    }
    return ratingList;
  }

  playTrailor = (movie) => {
    // this.setState({
    //   youtubeUrl: movie.trailorUrl ? movie.trailorUrl : movie.teaserUrl,
    //   isYoutubeUrl: true,
    // });
    let url = movie.trailorUrl ? movie.trailorUrl : movie.teaserUrl;
    let playId = movie.trailorUrl ? "trailer" : "teaser";
    const { source, movieDetails, users } = this.state;
    // if (movieDetails.movieType !== "Trailer") {
    //   if (url.includes("https://www.youtube.com/")) {
    //     this.setState({
    //       isYoutubeUrl: true,
    //       youtubeUrl: url
    //     })
    //   } else {
    window.scrollTo(0, 0);
    // source.sources[0].src = url;
    if (typeof url === "object") {
      let movieData = [];
      url.map((item, idx) => {
        let obj = { src: item.movieUrl, type: item.contentType };
        movieData.push(obj);
      });
      source["sources"] = movieData;
    } else {
      let videoData = [];
      let videoObj = { src: url, type: "application/x-mpegurl" };
      videoData.push(videoObj);
      source["sources"] = videoData;
    }
    source.isMovie = false;
    source.movieId = movieDetails.movieId;
    source.playId = playId + "_" + movieDetails.movieId;
    source.userId = users.data.userId;
    this.setState({
      // videoUrl: url,
      source,
      showVideoPlayer: true,
      isMovie: false,
    });

    // }
  };

  moviePlay = (val, e) => {
    const { isMovie } = this.state;
    const moviePlayId = JSON.parse(localStorage.getItem("moviePlayId"));
    if (isMovie == true) {
      if (val == "play" && !moviePlayId) {
        this.addMoviePlay(e);
      }
      if (val == "pause") {
        if (moviePlayId) {
          this.updateMoviePlay(moviePlayId, e);
        }
      }
    }
  };

  addMoviePlay = (e) => {
    const { movieId, users } = this.state;
    if (movieId) {
      let param = {
        movieId: movieId,
        userId: users.userId,
        playStartTime: moment.utc().format("DD-MM-YYYY HH:mm:ss"),
        playEndTime: null,
        playSeekTime: 1,
        operatingSystem: null,
        ipAddress: null,
        deviceInfo: null,
        country: null,
        timezone: null,
      };
      let data = {
        url: "MOVIE_PLAYS",
        query: movieId + "/movieplays",
        body: param,
        method: "POST",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status === 201) {
            localStorage.setItem("moviePlayId", response.data.moviePlayId);
            this.setState({
              moviePlayId: response.data.moviePlayId,
            });
          } else {
            common.snack(
              "E",
              response.data.error ? response.data.error : "An Error Occurred"
            );
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  updateMoviePlay = (playId, e) => {
    if (playId) {
      let currentTime = 0;
      if (e.target.currentTime) {
        currentTime = Math.round(e.target.currentTime / 60) || 0;
      }
      let param = {
        playEndTime: moment.utc().format("DD-MM-YYYY HH:mm:ss"),
        playSeekTime: currentTime,
        operatingSystem: null,
        ipAddress: null,
        deviceInfo: null,
        country: "IN",
        timezone: null,
      };
      let data = {
        url: "MOVIE_PLAYS_UPDATE",
        query: playId,
        body: param,
        method: "PUT",
      };

      apiServices.call(
        data,
        (response) => {
          if (response.status === 202) {
            this.setState({
              moviePlayId: response.data.moviePlayId,
            });
          } else {
            common.snack(
              "E",
              response.data.error ? response.data.error : "An Error Occurred"
            );
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  render() {
    const {
      movieDetails,
      status,
      videoUrl,
      showVideoPlayer,
      source,
      isBookedLoading,
      playId,
      movieId,
      isViewMore,
      publicReviews,
      publicReviewsTotal,
      reviewList,
      isLoadMore,
      paging,
      shareUrl,
      isYoutubeUrl,
      youtubeUrl,
      users,
    } = this.state;

    return (
      <div>
        <StatusBar page="movie" loader="small" status={status} />
        <div
          style={{
            background:
              "url(" +
              common.loadCloudImg(movieDetails.webMovieDetail) +
              ") no-repeat center center /cover",
            maxHeight: "484px",
          }}
          className="mb-5"
        >
          <Header />
          <MetaTags>
            <meta charSet="utf-8" />
            <meta property="og:title" content={movieDetails.movieName} />
            <meta
              property="og:description"
              content={movieDetails.movieDescription}
            />
            <meta
              property="og:url"
              content={`https://www.cinebazzar.com/v/${movieDetails.movieKey}`}
            />
            <title>{movieDetails.movieName}</title>
            <meta name="description" content={movieDetails.movieDescription} />

            <meta
              property="og:video"
              content={
                movieDetails.teaserUrl
                  ? movieDetails.teaserUrl
                  : movieDetails.trailorUrl
              }
            />
            <meta
              property="og:video:secure_url"
              content={
                movieDetails.teaserUrl
                  ? movieDetails.teaserUrl
                  : movieDetails.trailorUrl
              }
            />
            <meta property="og:video:type" content="application/x-mpegURL" />
            <meta
              property="al:ios:url"
              content="https://apps.apple.com/in/app/cinebazzar/id1583183674"
            />
            <meta property="al:ios:app_store_id" content="1583183674" />
            <meta property="al:ios:app_name" content="Cinebazzar" />
            <meta
              property="al:android:url"
              content="https://play.google.com/store/apps/details?id=com.cinebazzar"
            />
            <meta property="al:android:package" content="com.cinebazzar" />
            <meta property="al:android:app_name" content="Cinebazzar" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@Cinebazzarott" />
          </MetaTags>
          <div className="streamnow-movies-wrap mb-5 cb-movie-details-wrap">
            <div className="container">
              <div className="slider-wrap">
                <div className="bannerRadius movie-info-banner">
                  <div className="d-flex movie-info-wrap mb-4">
                    <div className="align-self-end">
                      <img
                        src={
                          movieDetails.moviePoster
                            ? common.loadCloudImg(movieDetails.moviePoster)
                            : ""
                        }
                        alt={movieDetails.movieName}
                        className="img-fluid"
                      />
                      {/* {movieDetails.movieType !== "Trailer" && movieDetails.playStatus === "PAID" && (
                        <div className="row justify-content-center mb-3">
                          <a
                            onClick={() => {
                              this.checkCopyrights();
                            }}
                            className="btn btn-watchnow btn-booknow watchnow mr-3"
                          >
                            Watch Now
                          </a>
                        </div>
                      )}
                      {movieDetails.movieType == "Trailer" && (
                        <div className="row justify-content-center mb-3">
                          <a
                            onClick={() => {
                              this.playTrailor(movieDetails);
                            }}
                            className="btn btn-watchnow btn-booknow watchnow mr-3"
                          >
                            Watch Now
                          </a>
                        </div>
                      )}

                      {movieDetails.movieType !== "Trailer" && movieDetails.playStatus == null &&
                        movieDetails.paymentStatus == 0 && (
                          <a
                            href="#"
                            className="btn btn-booknow mr-3"
                            onClick={(e) => this.gotoTicketBooking(e, movieId)}
                          >
                            Book Now
                          </a>
                        )}
                      {movieDetails.movieType !== "Trailer" && movieDetails.playStatus == null &&
                        movieDetails.paymentStatus > 0 && (
                          <a
                            onClick={() => {
                              this.gotoOrder();
                            }}
                            className="btn btn-booknow mr-3"
                          >
                            {isBookedLoading ? "Loading..." : "Booked"}
                          </a>
                        )} */}
                    </div>
                    <div className="generdetailsContainer">
                      <p className="md-movie-name">
                        {movieDetails.movieName}
                        <span className="socialShare">
                          <FacebookShareButton
                            url={shareUrl}
                            quote={
                              movieDetails.movieName +
                              " -- Book Now at Cinebazzar App"
                            }
                            className="m-2"
                          >
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>

                          <TwitterShareButton
                            url={shareUrl}
                            title={
                              movieDetails.movieName +
                              " -- Book Now at Cinebazzar App"
                            }
                            className="m-2"
                          >
                            <TwitterIcon size={30} round={true} />
                          </TwitterShareButton>

                          <WhatsappShareButton
                            url={shareUrl}
                            title={
                              movieDetails.movieName +
                              " -- Book Now at Cinebazzar App"
                            }
                            className="m-2"
                          >
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                        </span>
                      </p>

                      <p className="generdetails">
                        {movieDetails.primaryLanguage} |{" "}
                        {moment
                          .utc(
                            moment
                              .duration(movieDetails.runningTime, "minutes")
                              .asMilliseconds()
                          )
                          .format("HH[h] mm[m]")}{" "}
                        |{" "}
                        {movieDetails.releaseMonth
                          ? movieDetails.releaseMonth
                          : "-"}{" "}
                        |{" "}
                        {movieDetails.ageRating
                          ? movieDetails.ageRating + "+"
                          : "-"}
                      </p>
                      <div className="row">
                        {movieDetails.movieGenres &&
                          movieDetails.movieGenres.length > 0 &&
                          movieDetails.movieGenres.map((data, idx) => (
                            <h6 className="gener">
                              <span key={idx}>
                                {data.genre && data.genre.genreName
                                  ? data.genre.genreName
                                  : "-"}
                              </span>
                            </h6>
                          ))}
                      </div>

                      {movieDetails.movieType !== "Trailer" &&
                        movieDetails.playStatus === "PAID" && (
                          <a
                            onClick={() => {
                              this.checkCopyrights();
                            }}
                            className="btn btn-watchnow btn-booknow watchnow mr-3"
                          >
                            Watch Now
                          </a>
                        )}
                      {movieDetails.movieType == "Trailer" && (
                        <a
                          onClick={() => {
                            this.playTrailor(movieDetails);
                          }}
                          className="btn btn-watchnow btn-booknow watchnow mr-3"
                        >
                          Watch Now
                        </a>
                      )}

                      {movieDetails.movieType !== "Trailer" &&
                        movieDetails.playStatus == null &&
                        movieDetails.paymentStatus == 0 && (
                          <a
                            href="#"
                            className="btn btn-booknow mr-3"
                            onClick={(e) => this.gotoTicketBooking(e, movieId)}
                          >
                            Book Now
                          </a>
                        )}
                      {movieDetails.movieType !== "Trailer" &&
                        movieDetails.playStatus == null &&
                        movieDetails.paymentStatus > 0 && (
                          <a
                            onClick={() => {
                              this.gotoOrder();
                            }}
                            className="btn btn-booknow mr-3"
                          >
                            {isBookedLoading ? "Loading..." : "Booked"}
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isYoutubeUrl == true && (
          <div className="popup-wrap">
            <div ref={this.playerRef} className="video-player-wrap">
              <div className={"PlayerDiv"}>
                <ReactPlayer
                  url={youtubeUrl}
                  width="960px"
                  height="480px"
                  playing={true}
                  controls={true}
                />
              </div>

              <div className="popup-control d-flex justify-content-center mt-3">
                <div
                  className="video-link close-button"
                  onClick={() => this.closeYoutubeVideo()}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        {showVideoPlayer == true && (
          <div className="popup-wrap">
            <div ref={this.playerRef} className="video-player-wrap">
              <div className={"PlayerDiv"}>
                {/* <Player source={source} /> */}
                <VideoPlayer
                  user={users.data}
                  isMovie={source && source.isMovie}
                  playId={source && source.playId}
                  src={
                    isYoutubeUrl
                      ? youtubeUrl
                      : source.sources &&
                        source.sources.length > 0 &&
                        source.sources[0].src
                  }
                  onPlay={(status, event) => this.moviePlay(status, event)}
                />
              </div>

              <div className="popup-control d-flex justify-content-center mt-3">
                <div
                  className="video-link close-button"
                  onClick={() => this.closeVideo()}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="upcoming-movies-wrap mb-5">
          <div className="container">
            <div className="moviesContainer row">
              <div className="movieleft col-md-9 col-sm-12">
                {movieDetails && movieDetails.movieDescription && (
                  <div>
                    <h5 className="sub-title">SYNOPSIS</h5>
                    <div className="read-more-container mb-5">
                      <ReadMoreAndLess
                        ref={this.ReadMore}
                        className="read-more-content"
                        charLimit={290}
                        readMoreText="Read more"
                        readLessText="Read less"
                      >
                        {movieDetails.movieDescription}
                      </ReadMoreAndLess>
                    </div>
                  </div>
                )}
                {movieDetails &&
                  movieDetails.movieActors &&
                  movieDetails.movieActors.length > 0 && (
                    <div>
                      <h5 className="sub-title">CAST & CREW</h5>
                      <Row>
                        {movieDetails.movieActors.map((actors, key) => {
                          return (
                            <Col md={2} key={key}>
                              <div className="crewdetails text-center">
                                {actors.actor.actorImage ? (
                                  <img
                                    src={common.loadCloudImg(
                                      actors.actor.actorImage
                                    )}
                                    alt="Movie Image"
                                    className="profile_pic"
                                  />
                                ) : (
                                  <img
                                    src={common.loadImg("user_icon.png")}
                                    alt="profile"
                                    className="profile_pic"
                                  />
                                )}
                                <h5>{actors.actor.actorName}</h5>
                                <p>{actors.actorRole}</p>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  )}

                {reviewList && reviewList.length > 0 && (
                  <div>
                    <h5 className="sub-title">
                      {"Reviews (" + publicReviewsTotal + ")"}
                    </h5>
                    <ul className="review-list" id="movieReviews">
                      {reviewList.map((item, key) => (
                        <li key={key}>
                          <div className="review-list-header media">
                            <img
                              src={
                                item.user.userImageName
                                  ? item.user.userImageName
                                  : common.loadImg("no_actor_img.png")
                              }
                              alt="user"
                              className="mr-3 user-img"
                            />
                            <div className="media-body">
                              <h2 className="reviewer-name">
                                {item.user.firstName}
                              </h2>
                              <p className="review-date">
                                {moment(
                                  tools.convertToLocal(item.createdOn)
                                ).format("MMM DD, YYYY")}
                              </p>
                            </div>
                            <div className="user-ratings">
                              {this.getRatings(item.rating)}
                            </div>
                          </div>
                          <p className="review-txt">{item.reviewComments}</p>
                        </li>
                      ))}
                    </ul>
                    {paging.page != paging.total && (
                      <button
                        onClick={() => this.pagination()}
                        className="btn btn-bordered mr-3"
                        disabled={isLoadMore}
                      >
                        {isLoadMore ? "Loading..." : "Load More"}
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className="movieright col-md-3 col-sm-12">
                {movieDetails.avgRating && movieDetails.avgRating > 0 && (
                  <div onClick={() => this.reviewsList()}>
                    <h5 className="sub-title">Rating</h5>
                    <div className="rating-wrap d-flex justify-content-between mb-4">
                      <StarRatings
                        rating={movieDetails.avgRating}
                        starRatedColor="#ffffff"
                        starEmptyColor="#506483"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                      />
                      <span>{movieDetails.avgRating} / 5</span>
                    </div>
                  </div>
                )}
                {movieDetails.playStatus === "PAID" && (
                  <button
                    className="btn btn-add-rating text-center"
                    onClick={() => this.rateMovie()}
                  >
                    <img
                      src={common.loadImg("star.png")}
                      alt=""
                      className="mr-3"
                    />
                    RATE THIS MOVIE
                  </button>
                )}
                <h5 className="sub-title">Teaser / Trailer</h5>
                <div className="mb-5">
                  {movieDetails.teaserUrl && (
                    <div className="mb-3">
                      <div
                        className="video-link"
                        onClick={() =>
                          this.playVideo(
                            movieDetails.teaserUrl,
                            false,
                            "teaser"
                          )
                        }
                      >
                        <div className="teaser-trailer-wrap mb-3">
                          <img
                            src={
                              movieDetails.thumbnail
                                ? common.loadCloudImg(movieDetails.thumbnail)
                                : ""
                            }
                            alt={movieDetails.movieName}
                            className="img-fluid teaserImg"
                          />
                          <div className="overlayTr">
                            <img src={common.loadImg("teaser_play1.png")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {movieDetails.trailorUrl && (
                    <div className="mb-3">
                      <div
                        className="video-link"
                        onClick={() =>
                          this.playVideo(
                            movieDetails.trailorUrl,
                            false,
                            "trailer"
                          )
                        }
                      >
                        <div className="teaser-trailer-wrap">
                          <img
                            src={
                              movieDetails.thumbnail
                                ? common.loadCloudImg(movieDetails.thumbnail)
                                : ""
                            }
                            alt={movieDetails.movieName}
                            className="img-fluid teaserImg"
                          />
                          <div className="overlayTr">
                            <img src={common.loadImg("teaser_play1.png")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* {movieDetails.movieGenres &&
                  movieDetails.movieGenres.length > 0 && (
                    <div>
                      <h5 className="sub-title">Genre</h5>
                      <div className="d-flex genre-list mb-5">
                        {movieDetails.movieGenres.map((data, idx) => (
                          <div className="genre-name" key={idx}>
                            {data.genre && data.genre.genreName
                              ? data.genre.genreName
                              : "-"}
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.watchNowModal}
          className="movie-songs-modal copyright-info-modal"
        >
          <Modal.Body>
            <Row className="copyright-info-content">
              <Col sm={12}>
                <p>
                  The Unauthorized reproduction or distribution of this
                  copyrighted work is illegal. Criminal copyright infringement
                  without monetary gain also is punishable by up to 3 years in
                  jail and a fine of Rs 3,00,000
                </p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="acc-close-btn"
              onClick={() => {
                this.getMovieLink(movieDetails.movieId);
              }}
            >
              Accept
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ watchNowModal: false });
              }}
              className="close-btn"
            >
              Decline
            </Button>
          </Modal.Footer>
        </Modal>
        <MovieOrders
          ref={(instance) => {
            this.MovieOrders = instance;
          }}
          success={() => this.bookedLoad(false)}
        />
        <Reviews
          ref={(instance) => {
            this.Reviews = instance;
          }}
        />
      </div>
    );
  }
}

export { MovieDetail };
