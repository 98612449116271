import React, { Component } from "react";
import "../css/Static.css";
import { Header } from "../../../_layouts/header";
import { common, message } from "../../../_helpers";
import { apiServices } from "../../../api";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyContent : "",
      status: ""
    };
  }

  componentDidMount = () => {
    this.getContent();
  }

  getContent = async _ => {
    try {
      let data = {
        url: "GET_PRIVACY_CONTENT",
        method: "GET",
      };
      apiServices.call(
        data,
        (response) => {
          this.setState({
            privacyContent: response.data.pageContent.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            status: "success",
          });
        },
        (error) => {
          common.snack("E", message.somthing_wrong);
        },
        (final) => {}
      );
    } catch (e) {
      common.snack("E", e.toString())
    }
  }

  render() {
    const { privacyContent } = this.state;
    return (
      <div>
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Privacy Policy</h1>
            <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Privacy Policy</li>
            </ul>
          </div>

          <div className="row">
            <div className="col-md-12 movie-container about">
              <img
                src={common.loadImg("headerlogo.png")}
                alt="aboutus_image"
                className="img-fluid center"
              />
              {/* <h4>Privacy Policy</h4> */}
              <p>
                {privacyContent.split('<br />').map((item, key) => {
                  return (
                    <span key={key}>{item}<br/></span>
                  )
                })}
              </p>
              {/* <p>
                Cinebazzar App is the trade name operated by Cinebazzar Media
                Pvt Ltd. It provides online movie streaming service through an
                online software application and website operated by Cinebazzar
                App.
                <br />
                <br />
                This privacy policy explains how we collect personal information
                from you and what we intend to do with that information.
              </p>
              <h4>HOW WE COLLECT INFORMATION FROM YOU</h4>
              <p>
                <b>
                  Cinebazzar App collects the following personal information
                  from you: Name, address, telephone number, email address and
                  geographic details when you create an account.
                </b>
                <br />
                Communications with us e.g. comments, suggestions, a request for
                information about our products and services or any other
                correspondence or phone calls.
                <br />
                <br />
                The way in which you use our services including in-App purchases
                e.g. Power-ups, price quotations or email subscriptions.
                <br />
                <br />
                Interactions with third-party service providers, marketing
                partners and affiliate interactions including Google Analytics,
                Google Adwords, Facebook Ads, Linkedin Ads and Paypal.
                <br />
                <br />
                We collect location based data (GPS coordinates) through our
                geolocation feature to make sure the content rights respective
                to the region or country is respected.
                <br />
                <br />
                Your Internet Protocol (IP) address (if using a browser),
                operating system, the browser type, the address of a referring
                site and any device identifier (if using a mobile device or
                tablet).
                <br />
                <br />
                <b>The lawful basis for collecting your data in this way is:</b>
                <br />
                (a) You provide your consent at the point of registration for us
                to do so and by accepting our Terms & Conditions. (b) Processing
                your data in the way explained above is necessary for us to
                provide Cinebazzar App services to users. (c) Some of that data
                is processed for the purposes of our legitimate interests.
              </p>
              <h4>HOW WE USE YOUR INFORMATION</h4>
              <p>
                Cinebazzar App acts as a data processor and uses personal
                information to improve the quality of the services that we
                offer. Ways in which we may do this include by:
                <br />
                <br />
                Cross-referencing your information with that received from our
                partners in order to provide a more enhanced and personalized
                App experience.
                <br />
                <br />
                Using the information you provide in our communications with
                you. For instance, in responding to your comments or inquiries,
                to resolve disputes, to contact you regarding any other
                operational issues with the App or matters involving product or
                service transactions, to address problems on the App or our
                Service and to enforce our agreements with you. Cinebazzar App
                also acts as a data controller for users who create an account
                for other team members to use. As a data controller we can
                provide a data processing agreement to team owners. Please
                contact us at <b>reachcinebazzar@gmail.com</b> to request one.
              </p>
              <h4>SHARING YOUR INFORMATION</h4>
              <p>
                We will not share personal information about you with any third
                party unless we have your permission or under the following
                circumstances:
                <br />
                To facilitate the operation of the App and Service and to
                perform related services. For example and without limitation,
                maintenance services, database management, web analytics and
                improvement of the Service's features or to assist us in
                analyzing how our App and Service is used. A third party here
                may have access to the personal information needed to perform
                their functions but it may not use it for other purposes.
                <br />
                We may share aggregated information that does not include
                personal information and we may otherwise disclose such
                information with third parties for industry analysis,
                demographic and analytical profiling and other purposes. Any
                aggregated information shared in these contexts will not contain
                your personal information.
                <br />
                We may sell, transfer or otherwise share some or all of our
                assets, which could include your personal information, in
                connection with a merger, acquisition, reorganization or sale of
                assets or in the event of company liquidation.
              </p>
              <h4>MARKETING</h4>
              <p>
                Cinebazzar App offers its users a number of email services such
                as a 'Daily Log', 'New Movie' reminder.
                <br />
                Access To Your Information And Correction You have the right to
                request a copy of the information that we hold about you
                <br />
                <br />
                If you would like a copy of some or all of your personal
                information, please email us at the following address:
                <b>reachcinebazzar@gmail.com</b>
                <br />
                <br />
                You may review, update, correct or delete your personal
                information at any time either through your account settings or
                by contacting us at the above address.
                <br />
                <br />
                If you do delete all such information then it will affect your
                ability to access our Service.
                <br />
                <br />
              </p>
              <h4>COOKIES</h4>
              <p>
                We automatically collect certain information through the use of
                "cookies”. These are small files that your browser places on
                your computer. Most Internet browsers automatically accept
                cookies. You can instruct your browser, by editing its options,
                to stop accepting cookies or to prompt you before accepting a
                cookie from the websites you visit. If you do not allow cookies,
                however, some parts of the Service may be restricted.
                <br />
                <br />
                <b>We use cookies and Non PII data to:</b>
                <br />
                1.Monitor and analyse your use of the App and our Service.
                <br />
                2.For technical administration of the App.
                <br />
                3.To increase the functionality and user-friendliness of our App
                and our Service.
                <br />
                4.To better tailor the App to our visitors needs.
                <br />
                <br />
                <b>
                  We may use both session cookies and persistent cookies to:
                </b>
                <br />
                1.Better understand how you interact with our App.
                <br />
                2.Monitor aggregate usage by our users and web traffic routing
                on our App.
                <br />
                3.Improve our App.
              </p>

              <h4>Other Websites</h4>
              <p>
                Our App contains links to other websites. Any information you
                provide on those sites is provided directly to the owner of that
                site and you should therefore, read their own privacy policies.
              </p>
              <p>
                This privacy policy is only applicable to our Service and App.
              </p>
              <h4>Security</h4>
              <p>
                We employ administrative, physical and electronic measures
                designed to protect your information from unauthorized access.
                <br />
                <br />
                However, be aware that there are no security controls that are
                100% impenetrable and thus there remains a small risk that data
                may be intercepted or misused.
                <br />
                <br />
              </p>

              <h4>Holding Information On Minors</h4>
              <p>
                The Service is not directed to minors and and we do not
                knowingly collect personal information from them. Our Terms &
                Conditions state the Service can only be used by those who have
                reached the age of majority in the State and Province they
                reside.
                <br />
                <br />
                If we learn that we have collected personal information of a
                minor then we will take steps to delete such information from
                our files as soon as possible.
              </p>
              <h4>Changes to our Privacy Policy</h4>
              <p>
                We keep our privacy policy under regular review and we will
                place any updates on this web page and will communicate them to
                you. This privacy policy was last updated on 8 July 2020.
                <br />
                <br />
                Any information we collect is subject to the privacy policy in
                effect at the time it is collected.
                <br />
                <br />
                By continuing to access or use our Service after any changes
                come into effect, you are agreeing to the revised policy.
              </p>
              <h4>Previous Versions:</h4>
              <p>
                <b>Complaints</b>
                <br />
                Our App contains links to other websites. Any information you
                provide on those sites is provided directly to the owner of that
                site and you should therefore, read their own privacy policies.
                <br />
                <br />
                This privacy policy is only applicable to our Service and App.
              </p>
              <h4>How to contact us</h4>
              <p>
                Please contact us if you have any questions about our privacy
                policy or information we hold about you at{" "}
                <b>reachcinebazzar@gmail.com</b>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Privacy };
