import React from "react";
import { common } from "../_helpers";

class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
      page: this.props.page,
      type: this.props.type,
      loader: this.props.loader,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    return Object.keys(nextState).length
      ? (nextState.status = nextProps.status)
      : null;
  }

  render() {
    const { page, type, loader, status } = this.state;
    return status == "process" ? (
      loader == "small" ? (
        <div className="rt-medium-loader text-center">
          <img
            src={common.loadImg("loader.gif")}
            alt={"Loader"}
            className="img-fluid"
            width={100}
          />
          <p>Loading details please wait...</p>
        </div>
      ) : (
        <div className="loader-wrap text-center">
          <img
            src={common.loadImg("loader.gif")}
            alt={"Loader"}
            className="img-fluid"
          />
          <p>Loading please wait...</p>
        </div>
      )
    ) : status == "error" ? (
      <p>Something went wrong please try again</p>
    ) : status == "comingSoon" ? (
      <div className="coming_soon text-center">
        <img
          src={common.loadImg("comingsoon.png")}
          alt="profile"
          className="img-fluid"
        />
        <p>
          This page is under construction,{"\n"} So the page will be soon here
        </p>
      </div>
    ) : (
      status == "noData" && (
        <div className="coming_soon text-center">
          <img
            src={common.loadImg("nodatafound@3x.png")}
            alt="profile"
            className="img-fluid"
          />
          {page == "home" && type == "upcoming" ? (
            <p>Upcoming movies not found</p>
          ) : (
            <p>Movie not found</p>
          )}
        </div>
      )
    );
  }
}

export { StatusBar };
