import React, { Component } from "react";
import "../css/Movies.css";
import { Container } from "react-bootstrap";
import { Header } from "../../../_layouts/header";
import { common, tools, message } from "../../../_helpers";
import { apiServices } from "../../../api";
import { Multiselect } from "multiselect-react-dropdown";
// package
import moment from "moment";
import { envConfig } from "../../../api/config";

class TicketBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekData: [],
      timeData: [
        "12.00 AM",
        "01.00 AM",
        "02.00 AM",
        "03.00 AM",
        "04.00 AM",
        "05.00 AM",
        "06.00 AM",
        "07.00 AM",
        "08.00 AM",
        "09.00 AM",
        "10.00 AM",
        "11.00 AM",
        "12.00 PM",
        "01.00 PM",
        "02.00 PM",
        "03.00 PM",
        "04.00 PM",
        "05.00 PM",
        "06.00 PM",
        "07.00 PM",
        "08.00 PM",
        "09.00 PM",
        "10.00 PM",
        "11.00 PM",
      ],
      selectedTime: moment().format("hh.mm A"),
      selectedDate: moment(),
      movieId: 0,
      movieDetails: [],
      status: "process",
      subscriptions: []
    };
  }

  componentDidMount() {
    const movieId = this.props.match.params.movie_id || "";
    if (movieId) {
      this.getMovieDetails(movieId);
      this.getSubscription();
    } else {
      common.snack("E", "Something went wrong!!!");
      common.redirect("/movies");
    }
  }

  getMovieDetails = (id) => {
    try {
      if (id) {
        let data = {
          url: "GET_MOVIE_INFO",
          query: id,
          method: "GET",
        };
        apiServices.call(
          data,
          (response) => {
            this.setState({
              movieDetails: response.data,
              status: "success",
            });
            this.getCurrentWeek(response.data.streamingStartDate);
          },
          (error) => {
            common.snack("E", message.mt_no_movies);
            common.redirect("/movies", 2);
          },
          (final) => {}
        );
      }
    } catch (e) {
      this.setState({
        status: "error",
      });
    }
  };

  getSubscription = () => {
    try {
        let data = {
          url: "GET_SUBSCRIPTION",
          query: "?sort=ordering,asc",
          method: "GET",
        };
        apiServices.call(
          data,
          (response) => {
            this.setState({
              subscriptions: response.data.content,
              status: "success",
            });
          },
          (error) => {
            common.snack("E", message.somthing_wrong);
          },
          (final) => {}
        );
      
    } catch (e) {
      this.setState({
        status: "error",
      });
    }
  };

  bookTicket = (e) => {
    e.preventDefault();
    const { selectedTime, selectedDate, movieDetails } = this.state;
    if (selectedDate && selectedTime) {
      let detail = {
        movieId: movieDetails.movieId,
        date: tools.formatDate(selectedDate, 4),
        time: selectedTime,
        startTime: moment(),
      };
      localStorage.setItem("ticketInfo", JSON.stringify(detail));
      common.redirect("/movies/payment", 1);
    } else {
      common.snack("E", "Please select the show date and time");
    }
  };

  getCurrentWeek(date) {
    let firstDate = tools.now();
    let startDate = tools.utcToLocal(date);

    if (date && tools.dateStatus(startDate, firstDate, "after")) {
      firstDate = startDate;
    }

    let days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(firstDate).add(i, "days"));
    }

    this.setState({
      weekData: days,
      selectedDate: days[0],
    });
  }

  setShowDate = (date) => {
    this.setState({
      selectedTime: null,
      selectedDate: date,
    });
  };

  setShowTime = (time) => {
    this.setState({
      selectedTime: time,
    });
  };

  checkStreaming(time) {
    const { movieDetails, selectedDate } = this.state;
    let streamDate = tools.utcToLocal(movieDetails.streamingStartDate);
    let dateTime =
      tools.formatDate(selectedDate, 3) + " " + time.replace(".", ":");
    if (
      tools.dateStatus(dateTime, streamDate, "sAfter") &&
      tools.dateStatus(dateTime, moment(), "after")
    ) {
      return true;
    }
    return false;
  }

  streamingStatus() {
    const { movieDetails, selectedDate } = this.state;
    let streamDate = tools.utcToLocal(movieDetails.streamingStartDate);
    let status = tools.dateStatus(streamDate, tools.now(), "past");
    let isToday = tools.dateStatus(selectedDate, tools.now(), "same");
    if (isToday) {
      return status;
    }
    return false;
  }

  render() {
    const {
      weekData,
      timeData,
      selectedTime,
      selectedDate,
      movieDetails,
      status,
      subscriptions
    } = this.state;
    let movieBanner =
      "https://d13heh6lmag6t5.cloudfront.net/web/moviebanner/" +
      movieDetails.movieId +
      ".jpg";
    let ticketRate = parseFloat(movieDetails.ticketRate).toFixed(2);
    let taxValue = movieDetails.ticketRate
      ? (movieDetails.ticketRate * (18 / 100)).toFixed(2)
      : "0.00";
    let subTotal = movieDetails.ticketRate
      ? (parseFloat(movieDetails.ticketRate) + parseFloat(taxValue)).toFixed(2)
      : "0.00";

    return (
      <div className="cb-inner-page-wraper">
        <Header />

        <div className="container">
          <div className="breadcrum-wrap d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">Book Your Ticket</h1>
            {/* <ul className="breadcrum-nav">
              <li>Home</li>
              <li className="active">Book Your Ticket</li>
            </ul> */}
          </div>

          <div className="row cb-ticket-time-info-wrap">
            <div className="col-md-8 mb-5">
              <div className="movie-container">
                <div className="movie-details-container">
                  <img
                    src={common.loadCloudImg(movieDetails.image)
                      // movieDetails.image
                      //   ? movieDetails.image.includes("https://")
                      //     ? movieDetails.image
                      //     : (movieDetails.image.charAt(0) == "/") ? envConfig.IMAGE_URL + movieDetails.image.substring(1) : envConfig.IMAGE_URL + movieDetails.image
                      //   : ""
                    }
                    // src={"https://d28cifwpfxyimh.cloudfront.net/cholafiles/posters/nZnbkurpJQ.jpeg"}
                    alt="Movie"
                    width={100}
                    height={100}
                    className={"movie-image"}
                  />
                  <div className="movie-info-container">
                    <h1 className="movie-title">{movieDetails.movieName}</h1>
                    <h1 className="movie-info">
                      {movieDetails.primaryLanguage
                        ? movieDetails.primaryLanguage
                        : "-"}{" "}
                      <span className={"dot"}> {"\u2B24"} </span>
                      {movieDetails.movieGenres &&
                      movieDetails.movieGenres.length > 0 &&
                      movieDetails.movieGenres[0].genre &&
                      movieDetails.movieGenres[0].genre.genreName
                        ? movieDetails.movieGenres[0].genre.genreName
                        : "-"}
                      <span className={"dot"}> {"\u2B24"} </span>
                      <span className={"running-time"}>
                        {movieDetails.runningTime
                          ? moment
                              .utc(
                                moment
                                  .duration(movieDetails.runningTime, "minutes")
                                  .asMilliseconds()
                              )
                              .format("HH[ hr] mm[ min]")
                          : "-"}
                      </span>
                    </h1>
                  </div>
                </div>

                <div className="ticket_content index_content">
                  <div className="tc-inner-content">
                    <h3 className="content_header date_text">
                      {" "}
                      Film Information
                    </h3>
                    <div className="movie_date">
                      {weekData && weekData.length > 0 && (
                        <ul className="row">
                          {weekData.map((item, idx) => (
                            <li
                              className={
                                selectedDate == item
                                  ? "col text-center active"
                                  : "col text-center in-active-date"
                              }
                              onClick={() => this.setShowDate(item)}
                              key={idx}
                            >
                              <p>{tools.utcToLocalFormat(item, "DD")}</p>
                              <h6>{tools.utcToLocalFormat(item, "ddd")}</h6>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="select_time">
                      <h3 className="content_header time_text text-center">
                        {" "}
                        Select Your time
                      </h3>
                      <ul className="row show_time">
                        {this.streamingStatus() && (
                          <li
                            className="col-md-2"
                            onClick={() => {
                              this.setShowTime(moment().format("hh.mm A"));
                            }}
                            className={
                              selectedTime == moment().format("hh.mm A")
                                ? "col-md-2 show_time--select"
                                : "col-md-2 showzTimeList"
                            }
                          >
                            <p>Now</p>
                          </li>
                        )}
                        {timeData.map((data, idx) => (
                          <li
                            className="col-md-2"
                            onClick={() => {
                              if (this.checkStreaming(data)) {
                                this.setShowTime(data);
                              }
                            }}
                            className={
                              this.checkStreaming(data)
                                ? selectedTime == data
                                  ? "col-md-2 show_time--select"
                                  : "col-md-2 showTimeList"
                                : "col-md-2 disable"
                            }
                            key={idx}
                          >
                            <p>{data}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="button-container">
                    <button
                      className="continue-button"
                      onClick={(e) => this.bookTicket(e)}
                    >
                      {"Continue >"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="movie-container ticket-block">
                <div className="ticket-info">
                  {/* <h1 className="ticket-title">IDDPO1ABC</h1> */}
                  <h1 className="movie-title text-center">{movieDetails.movieName}</h1>
                  <h1 className="movie-info ticket-info-text text-center">
                    {movieDetails.primaryLanguage
                      ? movieDetails.primaryLanguage
                      : "-"}{" "}
                    <span className={"dot"}> {"\u2B24"} </span>
                    {movieDetails.movieGenres &&
                    movieDetails.movieGenres.length > 0 &&
                    movieDetails.movieGenres[0].genre &&
                    movieDetails.movieGenres[0].genre.genreName
                      ? movieDetails.movieGenres[0].genre.genreName
                      : "-"}
                    <span className={"dot"}> {"\u2B24"} </span>
                    <span className={"running-time"}>
                      {movieDetails.runningTime
                        ? moment
                            .utc(
                              moment
                                .duration(movieDetails.runningTime, "minutes")
                                .asMilliseconds()
                            )
                            .format("HH[ hr] mm[ min]")
                        : "-"}
                    </span>
                  </h1>
                  <div className="row ticket-time-info md-5">
                    <div className="col-4">
                      <h1 className="ticket-value-title">DATE</h1>
                      <h1 className="ticket-value">
                        {moment(selectedDate).format("ll")}
                      </h1>
                    </div>

                    <div className="col-4">
                      <h1 className="ticket-value-title">TIME</h1>
                      <h1 className="ticket-value">
                        {selectedTime ? selectedTime : "-"}
                      </h1>
                    </div>

                    <div className="col-4">
                      <h1 className="ticket-value-title">PRICE</h1>
                      <h1 className="ticket-value">
                        INR {parseFloat(movieDetails.ticketRate).toFixed(2)}
                      </h1>
                    </div>
                  </div>
                  <div className="content-text-container">
                    <h3 className="content-text">
                      * You can watch the movie booked for 24hrs from the
                      booking time
                    </h3>
                  </div>
                </div>
              </div>
              {/* <div>
                {subscriptions && subscriptions.length > 0 &&
                  <div className="subscription-container">
                    {subscriptions.map((item, index) => {
                      return (
                        <div className="subscription" key={index}>
                          <h1 className="subscription-title text-center">{item.name}</h1>
                          <h1 className="subscription-description text-center">{item.description}</h1>
                          <h1 className="subscription-subvalue text-center">₹{item.subValue}</h1>
                          <h1 className="subscription-uom text-center">PER {item.uom}</h1>
                        </div>
                      )
                    })}
                  </div>
                }
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { TicketBooking };
