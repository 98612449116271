import React, { Component } from "react";
import { common, history } from "../_helpers";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <footer className="footer">
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                {/* ----------------------- */}
                <div className="col-lg-12 mb-3">
                <p class="description">CINEBAZZAR (Your Own Theatre)– OTT Which Works On TVOD Platform focussed on bringing New Released Quality Movies to the Worldwide Audience. Our Motto is to Reach Cinema Undisturbed NO ADS, NO SUBSCRIPTION and just Pay only for what you want to see. Only Movie Ticket Charge, We aim to bring the Regional and International Movies with Great Quality to you directly. We are in 200+ Countries and Regions Karnataka`s First Widest Network. Powered By CHOLALABS... MADE WITH ❤️ INDIA.</p>
                </div>
                <div className="col-lg-5">
                  <ul className="footer-links list-inline">
                    <li className="list-inline-item">
                      <a href="/about-us">About</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/terms-and-conditions">Terms of Use</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/terms-and-conditions">FAQ</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/contact-us">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2">
                  <h6>Connect With Us</h6>
                  <ul className="social-links d-flex">
                    <li>
                      <a
                        href="https://fb.me/cinebazzarott"
                        target="_blank"
                      >
                        <img src={common.loadImg("fb.png")} alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://mobile.twitter.com/Cinebazzarott" target="_blank">
                        <img
                          src={common.loadImg("twitter.png")}
                          alt="Twitter"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/p/CRxXKxWJlrH/?utm_medium=share_sheet"
                        target="_blank"
                      >
                        <img
                          src={common.loadImg("insta.png")}
                          alt="Instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCbkVOWs3vn56Jy_sTCK0m_g"
                        target="_blank"
                      >
                        <img
                          src={common.loadImg("youtube.png")}
                          alt="Youtube"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h6>Download App</h6>
                  <div className="app-links row">
                    <div className="col-lg-6">
                    <a
                      href="https://apps.apple.com/in/app/cinebazzar/id1583183674"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("appstore.png")}
                        alt="App Store"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.cinebazzar"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("playstore.png")}
                        alt="Play Store"
                      />
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.cholalabs.cinebazzartvapp"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("cinebazzartv.png")}
                        alt="Cinebazzar TV"
                      />
                    </a>
                    </div>
                    {/* <div className="col-lg-4"></div> */}
                    <div className="col-lg-6">
                    <a
                      href="https://www.amazon.in/-/hi/dp/B09GRRJGPW?language=en_IN"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("firetv.png")}
                        alt="Fire TV"
                      />
                    </a>
                    <a
                      href="https://channelstore.roku.com/en-gb/details/0ef99759ca5d55d58b47568a4a5ec10c/cinebazzar"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("rokutv.png")}
                        alt="Roku TV"
                      />
                    </a>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <p className="copy-rights mb-0">
                    Copyrights ©️ 2020-2021 Cinebazzar Nextlevel Pvt Ltd. All Rights Reserved, Powered By <a href=" https://www.cholalabs.com/products/chola-ott" target="_blank">CholaOTT</a>
                  </p>
                </div>
                {/* ----------------------- */}

                {/* <div className="col-lg-8">
                  <div className="row justify-content-between">
                    <div className="col-lg-4">
                    <p className="description">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cinebazzar – is an 
                      Bangalore based OTT platform focussed on bringing 
                      quality Kannada movies to the worldwide audience.
                      Launched officially in August 2021, 
                      we aim to bring the Kannada movies with great quality to you directly.
                    </p>
                    </div>
                    <div className="col-lg-8 d-flex justify-content-center">
                      <ul className="footer-links list-inline">
                        <li className="list-inline-item">
                          <a href="/about-us">About</a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/terms-and-conditions">Terms of Use</a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/terms-and-conditions">FAQ</a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/contact-us">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <p className="copy-rights mb-0">
                  Copyrights ©️ 2020-2021 Cinebazzar Nextlevel Pvt Ltd. All Rights Reserved, Powered By <a href=" https://www.cholalabs.com/products/chola-ott" target="_blank">CholaOTT</a>
                  </p>
                </div>
                <div className="col-lg-2">
                  <h6>Connect With Us</h6>
                  <ul className="social-links d-flex">
                    <li>
                      <a
                        href="https://fb.me/cinebazzarott"
                        target="_blank"
                      >
                        <img src={common.loadImg("fb.png")} alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://mobile.twitter.com/Cinebazzarott" target="_blank">
                        <img
                          src={common.loadImg("twitter.png")}
                          alt="Twitter"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/p/CRxXKxWJlrH/?utm_medium=share_sheet"
                        target="_blank"
                      >
                        <img
                          src={common.loadImg("insta.png")}
                          alt="Instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCbkVOWs3vn56Jy_sTCK0m_g"
                        target="_blank"
                      >
                        <img
                          src={common.loadImg("youtube.png")}
                          alt="Youtube"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2">
                  <h6>Download App</h6>
                  <div className="app-links row">
                    <div className="col-lg-4">
                    <a
                      href="https://apps.apple.com/in/app/cinebazzar/id1583183674"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("appstore.png")}
                        alt="App Store"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.cinebazzar"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("playstore.png")}
                        alt="Play Store"
                      />
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.cholalabs.cinebazzartvapp"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("cinebazzartv.png")}
                        alt="Cinebazzar TV"
                      />
                    </a>
                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                    <a
                      href="https://www.amazon.in/-/en/dp/B09GRRHSKX"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("firetv.png")}
                        alt="Fire TV"
                      />
                    </a>
                    <a
                      href="https://channelstore.roku.com/en-gb/details/0ef99759ca5d55d58b47568a4a5ec10c/cinebazzar"
                      target="_blank"
                      className="mb-3"
                    >
                      <img
                        src={common.loadImg("rokutv.png")}
                        alt="Roku TV"
                      />
                    </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export { Footer };
