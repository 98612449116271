export const payment = {
    paypal: {
      sandbox:
        'EMjaIyyZ1D2kUReITu7u5hTiIuxsZHS0LpiWE5-wttYZYep1DKaKpO6erHXoScmQdt5BcfZlRDaHSYyg', //'ELiYZAhDYSL9N6D9oqlEZy-L_2i7XfIuSDw7veJFu17EQRnrLb_5myQebG9PKTHUYIpw7u5_xiUwvmWN',
      production:
        'AckGzzTgVNx7ZDJpC7F9MovpeSHLCsgqv38ZzzQB8wemiHpz72P3bHtXqyk1OP2QGHQe_T2NbJicQTZ-', //'AVafDt1R-1yx335aOIwaM2dIxxowF9BRh-prlPhvjMcYGDhDOkIoOMisIE1p_hIpbJgksaegNYb0l_iV',
    },
    razorPay:{
      url:'https://api.razorpay.com/v1/',
      sandbox: {
        key: 'rzp_test_5wPhhpof43jHCH', //'rzp_test_l9iFQytntMQUQx', //'rzp_test_feENwd0Up3uwft'
        secret: 'kI3288CAIPwNLNsB0U4en1RT', //'BMXfFtoQURXOqJIkCBfXDEuU' //'rv7SX5q0cQ9bXbHslZ5JFint'
      },
      production: {
        key: 'rzp_live_wt9lr9zdm4cSmU', //'rzp_live_3W5XroOqaBiK4f', //'rzp_live_BrZG6MdcXSoBuN'
        secret: 'J4d4L3ohk5YAXNzfN5dslO6S', //'NA6Sy56JJsA1aJebJSLsE7lX', //'zT3vTUUWrv0kuojOnSGDjKMV'
      }
    }
  };
  