import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Header } from "../../../_layouts/header";
import { common } from "../../../_helpers";
import { apiServices } from "../../../api";
import { StatusBar } from "../../../_includes";
import "../css/Producer.css";
import { Helmet } from "react-helmet";
import { envConfig } from "../../../api/config";

class ProducerChannels extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      channelList: [],
      status: "process",
      paging: {
        page: 1,
        size: 30,
        total: 1,
      },
    };
  }

  componentDidMount() {
    common.checkRole("Producer", true);
    this.getchannelsList("init");
  }

  getchannelsList(type) {
    const { channelList, paging } = this.state;
    let data = {
      url: "GET_MY_CHANNELS",
      method: "GET",
      query:
        "?page=" +
        (paging.page - 1) +
        "&size=" +
        paging.size +
        "&sort=createdOn,desc",
    };

    apiServices.call(
      data,
      (response) => {
        if(response.data.totalElements>0){
          response.data.content.map((item, key) => {
            channelList.push(item);
          });
          paging.total = Math.ceil(response.data.totalElements / paging.size);
        }
        this.setState({
          channelList,
          paging,
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {
        if (type == "load") {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        status: "process",
        paging,
      },
      () => {
        this.getchannelsList("load");
      }
    );
  }

  refreshData() {
    this.setState(
      {
        channelList: [],
        status: "process",
        paging: {
          page: 1,
          size: 30,
          total: 1,
        },
      },
      () => {
        this.getchannelsList("init");
      }
    );
  }

  gotoMovies(e, id) {
    e.preventDefault();
    common.redirect("/producer/movies/" + id);
  }

  goBack(){
    common.redirect("/");
  }

  render() {
    const { channelList, paging, status } = this.state;
    return (
      <div>
        <Header />
        <div className="profile_content">
          <div className="custom_width">
            <Container>
              <div className="producer-content-wrap">
                <div className="myProfile_header order-detail-wrap-header">
                  <Row>
                    <Col md={10}>
                      <h3>My Channels</h3>
                    </Col>
                    <Col md={2} className="rt-right">
                      <Button
                        className="btn-booknow btn-goback"
                        onClick={() => this.goBack()}
                      >
                        Go Back
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="producer-content">
                  <StatusBar page="movieOrder" loader="small" status={status} />
                  {status == "success" &&
                    (channelList == "" ? (
                      <StatusBar
                        page="movieOrder"
                        type="channelList"
                        status={"noData"}
                      />
                    ) : (
                      <div>
                        <ul className="row channel-list">
                          {channelList.map((item, key) => (
                            <li className="col-md-4 mb-4 text-center" key={key}>
                              <a
                                href="#"
                                onClick={(e) =>
                                  this.gotoMovies(e, item.channelId)
                                }
                              >
                                {item.channelImage ? (
                                  <img
                                    src={
                                      item.channelImage
                                      ? item.channelImage.includes("https://")
                                        ? item.channelImage
                                        : envConfig.IMAGE_URL +
                                        item.channelImage
                                      : ""}
                                    alt="Channel Image"
                                    className="pc-img mb-3"
                                  />
                                ) : (
                                  <img
                                    src={common.loadImg("no_movie.png")}
                                    alt="Channel Image"
                                    className="pc-img mb-3"
                                  />
                                )}
                                <h2 className="pc-title">{item.channelName}</h2>
                              </a>
                            </li>
                          ))}
                        </ul>
                        {paging.page != paging.total && (
                          <a
                            href="#"
                            onClick={(e) => this.pagination(e)}
                            className="loadMore"
                          >
                            <img src={common.loadImg("loadmore.png")} /> Load
                            More
                          </a>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </Container>
          </div>
          {/* custom width end here */}
        </div>
      </div>
    );
  }
}
export { ProducerChannels };
