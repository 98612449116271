import React from "react";
import { Row, Col, ListGroup, Media, Table } from "react-bootstrap";
import { tools } from "../../../_helpers";
import { common } from "../../../_helpers";
import { Helmet } from "react-helmet";
class PurchaseHistoryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: this.props.orderDetails,
    };
  }

  summaryInfo() {
    const { orderDetails } = this.state;
    let data = {
      ticketPrice: (0).toFixed(2),
      discountPrice: (0).toFixed(2),
      subTotal: (0).toFixed(2),
      taxPrice: (0).toFixed(2),
      grandTotal: (0).toFixed(2),
    };

    if (orderDetails.unitPrice) {
      data.ticketPrice = orderDetails.unitPrice.toFixed(2);
    }
    if (orderDetails.discountValue) {
      data.discountPrice = orderDetails.discountValue.toFixed(2);
    }
    if (orderDetails.totalTaxValue) {
      data.taxPrice = orderDetails.totalTaxValue.toFixed(2);
    }
    if (orderDetails.totalRoundedValue) {
      data.grandTotal = orderDetails.totalRoundedValue.toFixed(2);
    }
    // Subtotal
    data.subTotal = (data.ticketPrice - data.discountPrice).toFixed(2);
    return data;
  }

  render() {
    const { orderDetails } = this.state;
    const summaryInfo = this.summaryInfo();
    return (
      <div className="profileContent_detail">
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>RegalTalkies - Virtual Theater App</title>
          <meta
            name="description"
            content="Watch new movies releasing on Regal Talkies on your favorite devices from anywhere with internet"
          />
        </Helmet> */}
        <ListGroup className="purchasehistory_detail historyTicket_detail">
          <ListGroup.Item>
            <Row>
              <Col md={10}>
                <Media>
                  <img
                    src={orderDetails.orderMovie.moviePoster ? common.loadCloudImg(orderDetails.orderMovie.moviePoster) : null}
                    alt="image"
                    className="purchase_img"
                  />
                  <Media.Body>
                    <h5>{orderDetails.orderMovie.movieName || "-"}</h5>
                    <ul>
                      <li>
                        <p>{orderDetails.orderMovie.primaryLanguage || "-"} </p>
                      </li>
                      <li>
                        <p>
                          {tools.utcToLocal(
                            orderDetails.bookingStartTime,
                            "DD MMM, YYYY | hh:mm A"
                          )}
                        </p>
                      </li>
                    </ul>
                  </Media.Body>
                </Media>
              </Col>
              <Col md={2} className="text-md-right">
                <p>{tools.utcToLocal(orderDetails.orderDate, "DD MMM")}</p>
                <h3>₹{summaryInfo.ticketPrice}</h3>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <div className="history_summary">
          <Row>
            <Col md={7} xs={7}>
              <h3>Summary</h3>
            </Col>
            <Col md={5} xs={5}>
              <h4 className="text-right">
                <span>Paid Via {orderDetails.paymentMode || "-"}</span>
              </h4>
            </Col>
          </Row>
        </div>
        <div className="history_table">
          <Table>
            <thead>
              <tr>
                <th>Ticket Id</th>
                <th>Movie Name</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{orderDetails.orderNo || "-"}</td>
                <td>
                  <p>{orderDetails.orderMovie.movieName || "-"}</p>
                </td>
                <td>
                  {tools.utcToLocal(
                    orderDetails.bookingStartTime,
                    "DD-MM-YYYY hh:mm A"
                  )}
                </td>
                <td>
                  {tools.utcToLocal(
                    orderDetails.bookingEndTime,
                    "DD-MM-YYYY hh:mm A"
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>{" "}
        <div className="history_price">
          <Row>
            <Col md={{ offset: 6, span: 6 }}>
              <ul>
                <li>
                  <p>Ticket Price</p>
                  <p>₹{summaryInfo.ticketPrice}</p>
                </li>
                <li>
                  <p>Voucher</p>
                  <p>₹{summaryInfo.discountPrice}</p>
                </li>
                <li>
                  <p>Sub Total</p>
                  <p>₹{summaryInfo.subTotal}</p>
                </li>

                <li>
                  <p>
                    GST <span> @ 18%</span>
                  </p>
                  <p>₹{summaryInfo.taxPrice}</p>
                </li>
                <li>
                  <p>Total</p>
                  <p>₹{summaryInfo.grandTotal}</p>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="transaction-status text-right mt-3">
          <p>
            *Transaction was{" "}
            <span>
              {" "}
              {orderDetails.paymentStatus === "PAID"
                ? "Successful"
                : orderDetails.paymentStatus === "CANCELLED"
                ? "Cancelled"
                : "not successfull"}
            </span>
          </p>
        </div>
      </div>
    );
  }
}
export default PurchaseHistoryDetail;
